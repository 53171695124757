// DocumentDesigner.styles.ts

// Builder styles: used for the drag-and-drop editor
export const builderContainerStyle = {
    width: "850px",
    height: "1100px",
    border: "1px solid black",
    padding: "20px",
};

export const ELEMENT_TYPES = {
    field: {
        border: "1px solid #ddd",
        padding: "10px",
        background: "none",
    },
    textBox: {
        border: "2px solid black",
        padding: "10px",
        background: "rgba(0,0,0,0.1)",
    },
    line: {
        borderBottom: "2px solid black",
        height: "2px",
        width: "100%",
    },
};

// PDF styles: used for the PDF preview
export const pdfPageStyle = {
    position: "relative",
    backgroundColor: "white", // Force white background
    width: 612,  // Letter size: 8.5 * 72
    height: 792, // Letter size: 11 * 72
};

export const pdfElementBaseStyle = (el: any) => ({
    position: "absolute",
    left: (el.x || 0) * 10,
    top: (el.y || 0) * 10,
    width: (el.w || 3) * 10,
    height: (el.h || 2) * 10,
    fontWeight: el.options?.format === "bold" ? "bold" : "normal",
    fontStyle: el.options?.format === "italic" ? "italic" : "normal",
});
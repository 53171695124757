import axios from 'axios';

// Helper to validate module type
const validateModuleType = (type: string) => {
    const validTypes = ['reference', 'transaction', 'nested'];
    if (!validTypes.includes(type)) {
        throw new Error(`Invalid module type: ${type}`);
    }
};

// Helper to flatten records
const flattenRecords = (records: any[]) => {
    return records.map(record => {
        const flattenedRecord: any = {};
        Object.keys(record).forEach(key => {
            if (typeof record[key] === 'object' && record[key] !== null) {
                Object.keys(record[key]).forEach(subKey => {
                    flattenedRecord[`${key}.${subKey}`] = record[key][subKey];
                });
            } else {
                flattenedRecord[key] = record[key];
            }
        });
        return flattenedRecord;
    });
};

// Main Data Extract Utility
const dataExtractUtility = {
    async getAllModules (tenantId: number, companyId: number) {
        if (!tenantId || !companyId) {
            throw new Error('Tenant ID and Company ID are required');
        }
        try {
            const response = await axios.get(`${API_URL}/modules`, {
                params: { tenantId, companyId },
            });

            return response.data || []
        } catch (error) {
            console.error('Error fetching modules:', error);
            return []; // Fallback to an empty array in case of an error
        }
    },

    async getAllModulesByType (tenantId: number, companyId: number, pageType: string) {
        try {
            const allModules = await this.getAllModules(tenantId, companyId);
            return allModules.filter((module: any) => module.pageType.toLowerCase() === pageType.toLowerCase());
        } catch (error) {
            console.error('Error fetching modules:', error);
            return []; // Fallback to an empty array in case of an error
        }
    },

    async getPublishedModules (tenantId: number, companyId: number) {
        if (!tenantId || !companyId) {
            throw new Error('Tenant ID and Company ID are required');
        }
        try {
            const response = await axios.get(`${API_URL}/modules/published?`, {
                params: { tenantId, companyId },
            });

            // Ensure response data is an array
            const data = response.data || []
            return data;
        } catch (error) {
            console.error('Error fetching modules:', error);
            return []; // Fallback to an empty array in case of an error
        }
    },

    async getModuleData(moduleId: string) {
        if (!moduleId) {
            throw new Error('Module ID is required');
        }

        try {
            // Fetch module data
            const moduleResponse = await axios.get(`${API_URL}/modules/${moduleId}`);
            const moduleData = moduleResponse.data;

            return { moduleData };
        } catch (error) {
            console.error('Error fetching module data:', error);
            throw error;
        }
    },

    async getModuleDataFields(moduleId: string) {
        if (!moduleId) {
            throw new Error('Module ID is required');
        }

        try {
            // Fetch module data
            const moduleResponse = await axios.get(`${API_URL}/modules/${moduleId}/fields`);
            const moduleData = moduleResponse.data;

            return moduleData;
        } catch (error) {
            console.error('Error fetching module data:', error);
            throw error;
        }
    },

    async getModuleDataStages(moduleId: string) {
        if (!moduleId) {
            throw new Error('Module ID is required');
        }

        try {
            // Fetch module data
            const moduleResponse = await axios.get(`${API_URL}/modules/${moduleId}/stages`);
            const moduleData = moduleResponse.data;

            return moduleData;
        } catch (error) {
            console.error('Error fetching module data:', error);
            throw error;
        }
    },

    async getReferenceData(tenantId: number, companyId: number, moduleId: string) {
        if (!moduleId || !tenantId || !companyId) {
            throw new Error('Module ID, Tenant ID, and Company ID are required');
        }

        const tenantIdString = tenantId.toString();
        const companyIdString = companyId.toString();

        try {
            const response = await axios.get(
                `${API_URL}/referencedata?moduleId=${moduleId}&tenantId=${tenantIdString}&companyId=${companyIdString}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching reference data:', error);
            throw error;
        }
    },

    async getTransactionData(tenantId: number, companyId: number,moduleId: string,) {
        if (!moduleId || !tenantId || !companyId) {
            throw new Error('Module ID, Tenant ID, and Company ID are required');
        }

        try {
            const response = await axios.get(
                `${API_URL}/transactiondata?moduleId=${moduleId}&tenantId=${tenantId}&companyId=${companyId}`
            );
            // Filter out records with movedToNextStage = true
            const records = response.data.filter((record: any) => !record.movedToNextStage);
            return records;
        } catch (error) {
            console.error('Error fetching transaction data:', error);
            throw error;
        }
    },

    async getNestedData(moduleId: string, tenantId: number, companyId: number) {
        if (!moduleId || !tenantId || !companyId) {
            throw new Error('Module ID, Tenant ID, and Company ID are required');
        }

        try {
            const response = await axios.get(
                `${API_URL}/nesteddata?moduleId=${moduleId}&tenantId=${tenantId}&companyId=${companyId}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching nested data:', error);
            throw error;
        }
    },

    async getTenants() {
        // const isAdmin = user?.roles.includes('quickbuildadmin'); <-- should add this here eventually
        try {
            const response = await axios.get(`${API_URL}/tenants`);
            return response.data;
        } catch (error) {
            console.error('Error fetching tenants data:', error);
            throw error;
        }
    },

    async getTenantById(tenantId: number) {
        try {
            const response = await axios.get(`${API_URL}/tenants/${tenantId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching tenantById:', error);
        }
    },

    async getCompanyById(companyId: number) {
        try {
            const response = await axios.get(`${API_URL}/company/${companyId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching company:', error);
        }
    },

    async getCompanies(tenantId: number) {
        if (!tenantId) {
            throw new Error('Tenant ID is required');
        }
        try {
            const response = await axios.get(`${API_URL}/company/bytenant/${tenantId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching companies data:', error);
            throw error;
        }
    },

    async getNextAutoIncrement(tenantId: number, companyId: number, moduleId: string, fieldId: string) {
        if (!tenantId || !companyId || !moduleId || !fieldId) {
            throw new Error('All parameters (tenantId, companyId, moduleId, fieldId) are required');
        }
        try {
            const response = await axios.get(`${API_URL}/autoincrement/next`, {
                params: {
                    tenantId,
                    companyId,
                    moduleId,
                    fieldId,
                },
            });
            return response.data.nextValue;  // Assuming the response has a `nextValue` field
        } catch (error) {
            console.error('Error fetching next auto-increment value:', error);
            throw error;
        }
    },

    async getSubscription(tenantId: number) {
        if (!tenantId) {
            throw new Error('Tenant ID is required');
        }
        try {
            const response = await axios.get(`${API_URL}/subscription/${tenantId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching subscription:', error);
        }
    },
  
    async getAggregateValues(tenantId: number, companyId: number, moduleId: string) {
        if (!tenantId || !companyId || !moduleId) {
            throw new Error('Tenant, Company, and Module are required');
        }
        try {
            const response = await axios.get(`${API_URL}/aggregate/value`, {
                params: {
                    tenantId,
                    companyId,
                    moduleId,
                }
            })
            return response.data.data;
        } catch (error) {
            console.error('Error fetching aggregate values:', error);
        }
    },

    async getAllDocuments(tenantId: number, companyId: number) {
        if (!tenantId || !companyId) {
            throw new Error('Tenant ID and Company ID are required');
        }
        try {
            const response = await axios.get(`${API_URL}/documents/${tenantId}/${companyId}`);
            return response.data || [];
        } catch (error) {
            console.error('Error fetching documents:', error);
            return [];
        }
    },

    async getDocumentById(tenantId: number, companyId: number, documentId: string) {
        if (!tenantId || !companyId || !documentId) {
            throw new Error('Tenant ID, Company ID, and Document ID are required');
        }
        try {
            const response = await axios.get(`${API_URL}/documents/${tenantId}/${companyId}/${documentId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching document:', error);
            throw error;
        }
    }
};

export default dataExtractUtility;
// pdfStyles.ts
import { StyleSheet } from "@react-pdf/renderer";
import type { Style, FontWeight, FontStyle } from "@react-pdf/types";

// The keys here (page, textBox, line, field, text) can be anything,
// but it’s nice to match your element “type” names for convenience.
export const pdfStyles = StyleSheet.create({
    page: {
        position: "relative",
        backgroundColor: "#FFFFFF",
        // For LETTER size at 72 DPI:
        width: 612,
        height: 792,
    },
    textBox: {
        position: "absolute",
        borderWidth: 2,
        borderColor: "black",
        backgroundColor: "rgba(0,0,0,0.1)",
        padding: 4,
    },
    // pdfStyles.ts
    line: {
        position: "absolute",
        borderBottomWidth: 2,
        borderBottomColor: "black",
        borderBottomStyle: "solid",
    },
    field: {
        position: "absolute",
    },
    text: {
        fontSize: 12,
        color: "#000000",
        fontWeight: "normal",
        fontStyle: "normal",
    },
});

export function getDynamicStyle(el: any): Style {
    // Base position & size in points
    const baseStyle: Style = {
        left: (el.x || 0) * 10,
        top: (el.y || 0) * 10,
        width: (el.w || 3) * 10,
        height: (el.h || 2) * 10,
    };

    // If it’s a line, add the line styling
    if (el.type === "line") {
        return {
            ...baseStyle,
            borderBottomWidth: 2,
            borderBottomColor: "black",
            borderBottomStyle: (el.options?.style as "solid" | "dotted" | "dashed") || "solid",
        };
    }

    // If it’s a textBox, you might handle custom widths or background
    // but typically that's in the static style.

    return baseStyle;
}

export function getTextStyle(el: any): Style {
    // Convert the user’s "bold"/"italic" choice into valid React-PDF enums
    const fontWeight: FontWeight = el.options?.format === "bold" ? "bold" : "normal";
    const fontStyle: FontStyle = el.options?.format === "italic" ? "italic" : "normal";

    return {
        fontWeight,
        fontStyle,
    };
}
const formValidateUtility = {
    async validateReferencePage(formValues: any, moduleId: string, moduleData: any, otmData: any) {
        const missingRequiredFields: string[] = [];

        // Validate required fields
        moduleData.sections.forEach((section: any) => {
            section.fields.forEach((field: any) => {
                if (field.fieldOptions?.required) {
                    if (field.type === 'OneToManyField') {
                        const dataArray = otmData[field.id] || [];
                        if (dataArray.length === 0) missingRequiredFields.push(field.name);
                    } else if (field.type === 'PhoneNumber') {
                        const phoneObject = formValues[field.name];
                        const phoneNumber = phoneObject?.phone;
                        if (!phoneNumber) missingRequiredFields.push(field.name);
                    } else {
                        const value = formValues[field.name];
                        if (!value) missingRequiredFields.push(field.name);
                    }
                }
            });
        });

        if (missingRequiredFields.length > 0) {
            return {
                success: false,
                message: `Missing: ${missingRequiredFields.join(', ')}`,
            };
        }

        return { success: true };
    },

    async validateTransaction(formValues: any, moduleId: string, stage: string, tableData: any, moduleData: any) {
        const missingRequiredFields: string[] = [];

        // Validate required fields
        moduleData.sections.forEach((section: any) => {
            section.fields.forEach((field: any) => {
                if (field.fieldOptions?.required) {
                    if (field.type === 'PhoneNumber') {
                        const phoneObject = formValues[field.name];
                        const phoneNumber = phoneObject?.phone;
                        if (!phoneNumber) missingRequiredFields.push(field.name);
                    } else {
                        const value = formValues[field.name];
                        if (!value) missingRequiredFields.push(field.name);
                    }
                }
            });
        });

        // Validate required fields in transaction table data
        tableData.forEach((row: any, rowIndex: number) => {
            moduleData.tableFields.forEach((field: any) => {
                if (field.fieldOptions?.required) {
                    const value = row[field.name];
                    if (!value) missingRequiredFields.push(`${field.name} (Row ${rowIndex + 1})`);
                }
            });
        });

        if (missingRequiredFields.length > 0) {
            return {
                success: false,
                message: `Missing: ${missingRequiredFields.join(', ')}`,
            };
        }

        return { success: true };
    },
};

export default formValidateUtility;
import React, {useEffect, useState} from 'react';
import { Upload, Button, Progress, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import * as XLSX from "xlsx";
import moment, {now} from "moment";
import dataAll from "../../utils/dataAll";

interface OtmField {
    id: string;
    name: string;
    type: string;
    fieldOptions?: {
        otmSections?: {
            fields: {
                id: string;
                name: string;
                type: string;
                fieldOptions?: any;
            }[];
        }[];
    };
}

type ModuleDataType = {
    tenantId?: string;
    companyId?: string;
    pageType?: string;
    _id?: string;
    stages?: {
        id?: string;
        name?: string;
    }[];
    sections?: {
        id?: string;
        title?: string;
        fields: {
            id: string;
            name: string;
            type: string;
        }[];
    }[];
    tableData?: {
        id?: string;
        columns?: {
            id: string;
            name: string;
            type: string;
            fieldOptions?: {
                lineNumberFormat?: string;
            }
        }
    }[];
};

const ImportDataModal = ({ moduleId, tenantId, companyId, onClose, onImportSuccess }) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [moduleData, setModuleData] = useState({});

    useEffect(() => {
        fetchModuleData();
    }, []);

    const fetchModuleData = async () => {
        try {
            const moduleResponse = await axios.get(
                `${API_URL}/modules/${moduleId}`
            );
            setModuleData(moduleResponse.data);

        } catch (err: any) {
            if (err.response && err.response.status === 404) {
            } else {
                message.error(err.message);
            }
            }
    };


    const parseExcelFile = async (file: File): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const data = new Uint8Array(event.target?.result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const rows = XLSX.utils.sheet_to_json(worksheet);
                    resolve(rows);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
    });
  };

    const parseWorkbook = async (file: File): Promise<XLSX.WorkBook> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const data = new Uint8Array(event.target?.result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    resolve(workbook);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
        });
    };

    const parseExcelSheet = (workbook: XLSX.WorkBook, sheetName: string): any[] | null => {
        const worksheet = workbook.Sheets[sheetName];
        if (!worksheet) {
            console.warn(`Sheet "${sheetName}" not found in the workbook.`);
            return null;
        }
        return XLSX.utils.sheet_to_json(worksheet);
    };

    const processAutoIncrementFields = async (row: any, moduleData: ModuleDataType) => {
        const autoIncrementFields: { id: string; name: string; type: string }[] = [];

        if (Array.isArray(moduleData.sections)) {
            moduleData.sections.forEach((section) => {
                if (Array.isArray(section.fields)) {
                    section.fields.forEach((field) => {
                        if (field.type === 'AutoIncrementId') {
                            autoIncrementFields.push(field);
                        }
                    });
                }
            });
        }

        for (const field of autoIncrementFields) {
            try {
                const response = await axios.get(`${API_URL}/autoincrement/next`, {
                    params: {
                        tenantId: moduleData.tenantId,
                        companyId: moduleData.companyId,
                        moduleId: moduleData._id,
                        fieldId: field.id,
                    },
                });
                const nextValue = response.data.nextValue;
                row[field.name] = nextValue; // Add auto-increment value to the row
            } catch (error) {
                console.error('Error fetching auto-increment value:', error);
                throw new Error(`Failed to fetch auto-increment value for field: ${field.name}`);
            }
        }
    };

    const processOneToManyFields = async (
        row: any,
        moduleData: ModuleDataType,
        File: File,
        data: any
    ) => {
 // Utility to parse Excel into rows

        const mdSections = moduleData.sections || []
        const otmFlatMap = Array.isArray(mdSections)
            ? mdSections.flatMap((section: any) => section.fields || [])
            : [];
        const oneToManyFields: OtmField[] = otmFlatMap.filter((field): field is OtmField => field.type === 'OneToManyField');
        const workbook = await parseWorkbook(File);

    for (const otmField of oneToManyFields) {
        const sheetName = otmField.name; // Assume OTM field name matches the sheet name
        const otmRows = parseExcelSheet(workbook, sheetName);

        if (!otmRows) {
            console.warn(`No sheet found for OneToManyField: ${otmField.name}`);
            continue;
        }

        const firstRow = otmRows[0];
        const matchColumn = Object.keys(firstRow)[0]; // Gets the first key of the first row

        if (!matchColumn) {
            console.warn(`Sheet "${sheetName}" has no valid columns.`);
            continue;
        }

        const matchedRows = otmRows.filter((otmRow: any) => {
            const matchValue = row[matchColumn];
            if (!matchValue) {
                console.warn(`No matching value for field: ${otmField.name}`);
                return false;
            }
            return otmRow[matchColumn] === matchValue;
        });

        const otmValue = await Promise.all(matchedRows.map(async (matchedRow: any) => {
            const entry: any = {};

            if (
                !otmField.fieldOptions?.otmSections ||
                !Array.isArray(otmField.fieldOptions.otmSections) ||
                otmField.fieldOptions.otmSections.length === 0
            ) {
                console.warn(`Invalid fieldOptions for field: ${otmField.name}`);
                return null;
            }

            const subFields = otmField.fieldOptions.otmSections[0]?.fields || [];

            if (!subFields.length) {
                console.warn(`No subfields found for ${otmField.name}`);
            } else {
                for (const subField of subFields) {
                    console.log("Processing subField:", subField);
                    const fieldValue = matchedRow[subField.name] || null;

                    if (subField.type === 'ReferenceLinkField') {
                        await processReferenceLinkFields([matchedRow], moduleData, false, true);
                        entry[subField.id] = matchedRow[subField.name];
                    } else {
                        entry[subField.id] = {
                            label: subField.name,
                            value: fieldValue,
                        };
                    }
                }
            }

            return entry;
        }));

        data[otmField.id] = {
            label: otmField.name,
            value: otmValue,
        };
    }
    };

    async function fetchReferenceData(moduleId: string, referenceDataCache: { [key: string]: any[] }) {
        if (!referenceDataCache[moduleId]) {
            try {
                const response = await axios.get(`${API_URL}/referencedata?moduleId=${moduleId}`);
                referenceDataCache[moduleId] = response.data.map((record: any) => ({
                    _id: record._id,
                    ...record.data,
                }));
            } catch (error) {
                console.error(`Failed to fetch reference data for moduleId: ${moduleId}`, error);
                referenceDataCache[moduleId] = [];
            }
        }
        return referenceDataCache[moduleId];
    }

    async function processReferenceLinkFields(rows: any[], moduleData: ModuleDataType, isTable: boolean, isOTM: boolean): Promise<void> {
        const referenceDataCache: { [key: string]: any[] } = {};

        const referenceLinkFields = isTable
            ? (moduleData.tableData || []).reduce((acc, table) => acc.concat(table.columns || []), []).filter(field => field.type === 'ReferenceLinkField')
            : isOTM
                ? (moduleData.sections || [])
                    .flatMap(section => section.fields || [])
                    .filter(field => field.type === 'OneToManyField')
                    .flatMap((otmField: OtmField) => otmField.fieldOptions?.otmSections?.flatMap(sec => sec.fields) || [])
                    .filter(subField => subField.type === 'ReferenceLinkField')
                : (moduleData.sections || []).flatMap(section => section.fields || []).filter(field => field.type === 'ReferenceLinkField');

        if (!referenceLinkFields.length) return;

        for (const row of rows) {
            for (const field of referenceLinkFields) {
                const importedValue = row[field.name];
                if (!importedValue) continue;

                const selectedFields = field.fieldOptions?.modules?.[0]?.selectedFields || [];
                const moduleIdForRef = field.fieldOptions?.modules?.[0]?.moduleId;
                if (!moduleIdForRef) continue;

                const referenceData = await fetchReferenceData(moduleIdForRef, referenceDataCache);

                const match = referenceData.find(record => {
                    const label = selectedFields.map(fieldId => record[fieldId]?.value).join(' - ');
                    return label === importedValue;
                });

                row[field.name] = match ? { uuid: match._id, label: importedValue } : null;
            }
        }
    }

    // async function processReferenceLinkFields(row, moduleData, data) {
    //     // Cache reference data to avoid repeated API calls
    //     const referenceDataCache = {};
    //
    //     async function fetchReferenceData(moduleId) {
    //         if (!referenceDataCache[moduleId]) {
    //             try {
    //                 const response = await axios.get(
    //                     `${API_URL}/referencedata?moduleId=${moduleId}`
    //                 );
    //                 referenceDataCache[moduleId] = response.data.map((record: any) => ({
    //                     _id: record._id,
    //                     ...record.data,
    //                 }));
    //             } catch (error) {
    //                 console.error(`Failed to fetch reference data for moduleId: ${moduleId}`, error);
    //                 referenceDataCache[moduleId] = []; // Prevent further unnecessary calls
    //             }
    //         }
    //         return referenceDataCache[moduleId];
    //     }
    //
    //     const mdSections = moduleData.sections || []
    //     const fieldMap = mdSections.flatMap((section: any) => section.fields || [])
    //     const referenceLinkFields = fieldMap.filter(field => field.type === 'ReferenceLinkField')
    //
    //
    //     for (const field of referenceLinkFields) {
    //     if (field.type === "ReferenceLinkField") {
    //         const importedValue = row[field.name]; // Excel-imported Cell Value
    //         const selectedFields = field.fieldOptions?.modules?.[0]?.selectedFields || [];
    //         const returnFields = field.fieldOptions?.modules?.[0]?.returnFields || [];
    //         const referenceData = await fetchReferenceData(field.fieldOptions?.modules?.[0]._id); // Fetch from DB
    //
    //         // Attempt to find a match in referenceData based on the importedValue
    //         const match = referenceData.find((record) => {
    //             // Build the label from selectedFields
    //             const label = selectedFields.map((fieldId) => record[fieldId]?.value).join(" - ");
    //             return label === importedValue;
    //         });
    //         row[field.name] = match ? { uuid: match._id, label: importedValue } : null;
    //     }
    //     }
    // }
    //
    //     // New function to process ReferenceLink fields in tableData
    // async function processTableReferenceLinkFields(rows: any[], moduleData: ModuleDataType): Promise<void> {
    //     // Get ReferenceLinkField columns from tableData
    //     const tableRefLinkFields = (moduleData.tableData || [])
    //         .reduce((acc, table) => acc.concat(table.columns || []), [])
    //         .filter(field => field.type === 'ReferenceLinkField');
    //
    //     if (!tableRefLinkFields.length) return;
    //
    //     const referenceDataCache: { [key: string]: any[] } = {};
    //
    //     async function fetchReferenceData(moduleId: string) {
    //         if (!referenceDataCache[moduleId]) {
    //             try {
    //                 const response = await axios.get(`${API_URL}/referencedata?moduleId=${moduleId}`);
    //                 // const response = await dataAll.dataExtractUtility.getReferenceData(tenantId, companyId, moduleId);
    //                 referenceDataCache[moduleId] = response.data.map((record: any) => ({
    //                     _id: record._id,
    //                     ...record.data,
    //                 }));
    //             } catch (error) {
    //                 console.error(`Failed to fetch reference data for moduleId: ${moduleId}`, error);
    //                 referenceDataCache[moduleId] = [];
    //             }
    //         }
    //         return referenceDataCache[moduleId];
    //     }
    //
    //     for (const row of rows) {
    //         for (const field of tableRefLinkFields) {
    //             const importedValue = row[field.name];
    //             if (!importedValue) continue;
    //             const selectedFields = field.fieldOptions?.modules?.[0]?.selectedFields || [];
    //             const moduleIdForRef = field.fieldOptions?.modules?.[0]?.moduleId;
    //             if (!moduleIdForRef) continue;
    //             const referenceData = await fetchReferenceData(moduleIdForRef);
    //             const match = referenceData.find(record => {
    //                 const label = selectedFields.map(fieldId => record[fieldId]?.value).join(' - ');
    //                 return label === importedValue;
    //             });
    //             row[field.name] = match ? {uuid: match._id, label: importedValue} : null;
    //         }
    //     }
    // }

    const getLineNumber = (format: string, rowIndex: number): string => {
      switch (format) {
        case '1, 2, 3, ...':
          return `${rowIndex + 1}`;
        case '10, 20, 30, ...':
          return `${(rowIndex + 1) * 10}`;
        case '100, 200, 300, ...':
          return `${(rowIndex + 1) * 100}`;
        case '001, 002, 003, ...': {
          const nextValue = rowIndex + 1;
          return nextValue.toString().padStart(3, '0');
        }
        default:
          return `${rowIndex + 1}`;
      }
    };

    // New function to process Line Number fields
    async function processLineNumberFields (rows: any[], moduleData: ModuleDataType) {
      if (!moduleData.sections) return;

      // Extract all fields of type 'LineNumber' from all sections
      const lineNumberFields = (moduleData.tableData || [])
        .reduce((acc, table) => acc.concat(table.columns || []), [])
        .filter((field) => field.type === 'LineNumber');

      if (!lineNumberFields.length) return;

      // Process each row, assigning a line number based on the field's format
      rows.forEach((row, rowIndex) => {
      lineNumberFields.forEach((field: { id: string; name: string; type: string, fieldOptions?: { lineNumberFormat?: string } })   => {
          // Use the provided lineNumberFormat or default
          const format = field.fieldOptions?.lineNumberFormat || '1, 2, 3, ...';
          row[field.name] = getLineNumber(format, rowIndex);
        });
      });
    };

    const processTableData = async (row, moduleData, importFile) => {
        const processedTables = {};
        const workbook = await parseWorkbook(importFile);

        for (const table of moduleData.tableData) {
                // moduleData.tableData.forEach((table) => {
            const matchingSection = moduleData.sections.find((section) => section.id === table.id);
            const tableName = matchingSection ? matchingSection.title : table.id; // Use title if available

            const sheetData = parseExcelSheet(workbook, tableName);
            if (!sheetData) {
                console.warn(`No data found for table: ${tableName}`);
                return;
            }

            const commonId = row["Common ID"];
            if (!commonId) {
                console.warn(`Missing Common ID for row: ${JSON.stringify(row)}`);
                return;
            }

            // Filter rows that match the Common ID
            const matchedRows = sheetData.filter((tRow) => tRow["Common ID"] === commonId);

            // Process LineNumber fields on matchedRows
            processLineNumberFields(matchedRows, moduleData);
            await processReferenceLinkFields(matchedRows, moduleData, true, false);

            processedTables[table.id] = matchedRows.map((matchedRow) => {
                const entry = {};
                table.columns.forEach((field) => {
                    let value = matchedRow[field.name] || null;

                    switch (field.type) {
                        case "DateField":
                            if (typeof value === "number") {
                                value = moment("1899-12-30").add(value, "days").toISOString(); // Convert Excel serial date to JS Date
                            } else {
                                value = value.toISOString();
                            }
                            break;

                        case "Number":
                            value = value !== null ? parseFloat(value) : null; // Ensure it's a valid number
                            break;

                        case "Dropdown":
                            value = field.options?.includes(value) ? value : null; // Validate against predefined options
                            break;

                    default:
                        value = value ?? ""; // Default for text and other types
                        break;
                    }

                    if (field.type === "ReferenceLinkField") {
                        entry[field.id] = value;
                    } else {
                        entry[field.id] = {label: field.name, value};
                    }
                });

                return entry;

            });
        }

        return processedTables;
    };


    const mapRowToSchema = (row: any, moduleData: ModuleDataType, data: any) => {
        if (Array.isArray(moduleData.sections)) {
            moduleData.sections.forEach((section) => {
                if (Array.isArray(section.fields)) {
                    section.fields.forEach((field) => {
                        if (field.type !== 'OneToManyField') {
                            const fieldValue = row[field.name] || null;
                            data[field.id] = {
                                label: field.name,
                                value: fieldValue,
                            };
                        }
                    });
                }
            });
        }
    };

    const handleSave = async (row: any, moduleData: ModuleDataType, importFile) => {
        if (!moduleData?.tenantId || !moduleData?.companyId || !moduleData?._id) {
            console.error('Invalid module data:', moduleData);
            throw new Error('Module data is incomplete or missing required fields.');
        }

        const data: any = {};

        await processAutoIncrementFields(row, moduleData);
        await processOneToManyFields(row, moduleData, fileList[0], data);

        if (moduleData.pageType === "Transaction") {
            const tableData = await processTableData(row, moduleData, importFile);

            // Attach each table’s rows under data[tableId]
            Object.entries(tableData).forEach(([tableId, rows]) => {
                // Find a matching section for a nicer label (if it exists)
                const matchingSection = moduleData.sections.find((section) => section.id === tableId);
                const tableLabel = matchingSection?.title || tableId;

                data[tableId] = {
                    label: tableLabel,
                    value: rows, // rows is the array returned by processTableData
                };
            });

        }

        // Step 3: Map Headers to Schema and Build Payload for non-OTM fields
        mapRowToSchema(row, moduleData, data);

        await processReferenceLinkFields([row], moduleData, false, false);

        // Step 4: Create the Payload
        const payload: any = {
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            tenantId: moduleData.tenantId,
            companyId: moduleData.companyId,
            moduleId: moduleData._id,
            data,
        };

        if (moduleData.pageType === "Transaction" && moduleData.stages && moduleData.stages.length > 0) {
            payload.stageId = moduleData.stages[0].name;
            payload.movedToNextStage = false;
        }

        // Step 5: Post the Payload to the API
        try {
            // let response: { data: any; };
            if (moduleData.pageType === "Reference") {
                await dataAll.dataPostUtility.postData('reference', payload);
            } else if (moduleData.pageType === "Transaction") {
                await dataAll.dataPostUtility.postData('transaction', payload);
            }
            // message.success("Successfully posted data");
            // console.log('Record saved successfully:', response.data);
        } catch (error) {
            console.error('Save failed:', error);
            throw new Error('Failed to save the record.');
        }
    };

    const handleUpload = async () => {
        if (fileList.length === 0) {
            message.error('Please select a file to upload.');
            return;
        }

        setUploading(true);

        try {
            // Step 1: Parse the Excel file into an array of rows
            const file = fileList[0];
            const rows = await parseExcelFile(file); // Utility to parse Excel into rows

            // Step 2: Loop through rows and save each record
            let successCount = 0;
            let errorCount = 0;

            for (const row of rows) {
                try {
                    await handleSave(row, moduleData, file); // Save each row using your save logic
                    successCount++;
                } catch (error) {
                    console.error('Error saving row:', row, error);
                    errorCount++;
                }
            }

            // Step 3: Display results
            if (errorCount === 0) {
                message.success(`Successfully saved all ${successCount} records.`);
                onImportSuccess(successCount);
            } else {
                message.warning(
                    `Completed with errors. Successfully saved ${successCount} records. Failed to save ${errorCount} records.`
                );
            }
        } catch (error) {
            message.error('Failed to process the file.');
            console.error('Error processing file:', error);
        } finally {
            setUploading(false);
            setFileList([]);
        }
    };

    const props = {
        onRemove: (file) => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false; // Prevent automatic upload
        },
        fileList,
    };

    return (
        <div>
            <Upload {...props}>
                <Button icon={<UploadOutlined/>}>Select File</Button>
            </Upload>
            {progress > 0 && <Progress percent={progress}/>}
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                style={{marginTop: 16}}
            >
                {uploading ? 'Uploading' : 'Start Import'}
            </Button>
            <Button onClick={onClose} style={{marginLeft: 8}}>
                Cancel
            </Button>
        </div>
    );
    }
    ;

    export default ImportDataModal;

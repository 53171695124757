// src/App.tsx
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import * as reactRouterDom from "react-router-dom";
// import HomePage from './pages/HomePage';
// import LoginPage from './pages/LoginPage';
// import SignUpPage from './pages/SignUpPage';
import QBHome from './pages/QBHome';
import TenantLayout from './layouts/TenantLayout';
import ReferencePageBuilder from './pages/Builder/ReferencePage/ReferencePageBuilder';
import BuilderHomePage from './pages/Builder/BuilderHome';
import TransactionPageBuilder from './pages/Builder/TransactionPage/TransactionPageBuilder';
// import NestedPageBuilder from './pages/Builder/NestedPage/NestedPageBuilder';
import BasePageRender from './pages/Render/BasePageRender';
import SettingsPage from "./components/SettingsPage";
import { TourProvider } from './contexts/tour/tourContext'
import {SuperTokensWrapper} from "supertokens-auth-react";
import {EmailPasswordPreBuiltUI} from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import {getSuperTokensRoutesForReactRouterDom} from "supertokens-auth-react/ui";
import {initSuperTokens} from "./superTokensConfig";
import {SessionAuth} from "supertokens-auth-react/recipe/session";
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles';
import {SessionClaimValidator} from "supertokens-auth-react/lib/build/types";
import DocumentDesigner from "./pages/Builder/Documents/DocumentDesigner";

declare global {
    var API_URL: string;
}

global.API_URL = process.env.REACT_APP_API_URL;

initSuperTokens();

/**
 * Wrap the component with SuperTokens SessionAuth
 * @param Component
 */
function wrapRouteWithAuth(Component: React.ComponentType, roles: string[] = []) {
    const roleClaims: SessionClaimValidator[] = []
    for (const role of roles) {
        roleClaims.push(UserRoleClaim.validators.includes(role));
    }

    return (
        <SessionAuth
            // accessDeniedScreen={AccessDeniedScreen}
            // overrideGlobalClaimValidators={(globalValidators) => [
            //     ...globalValidators, ...roleClaims,
            // ]
            // }
        >
            <Component/>
        </SessionAuth>);
}

const builderRoles = ['quickbuildadmin', 'freetrial', 'builder'];
const otherRoles = ['quickbuildadmin', 'user', 'freetrial'];

const App = () => {
    return (
        <BrowserRouter>
            <SuperTokensWrapper>
                <TourProvider>
                <Routes>
                    {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI])}
                    {/*/!* Public Routes *!/*/}
                    {/*<Route path="/login" element={<LoginPage/>}/>*/}
                    {/*<Route path="/signup" element={<SignUpPage/>}/>*/}

                    {/*/!* Unauthorized Access Route *!/*/}
                    {/*<Route path="/unauthorized" element={<Unauthorized/>}/>*/}


                     Protected Routes wrapped in TenantLayout
                    <Route element={<TenantLayout/>}>
                        {/* QuickBuildAdmin-only Routes */}
                        <Route >
                            <Route path="/builder" element={wrapRouteWithAuth(BuilderHomePage, builderRoles)}/>
                            <Route path="/builder/document-builder" element={wrapRouteWithAuth(DocumentDesigner, builderRoles)}/>
                            <Route path="/builder/reference-page-builder" element={wrapRouteWithAuth(ReferencePageBuilder, builderRoles)}/>
                            <Route path="/builder/transaction-page-builder" element={wrapRouteWithAuth(TransactionPageBuilder, builderRoles)}/>
                            {/*<Route path="/builder/nested-page-builder" element={wrapRouteWithAuth(NestedPageBuilder, builderRoles)}/>*/}
                        </Route>

                        {/* User and QuickBuildAdmin Routes */}
                        <Route >
                            <Route path="/" element={<Navigate to="/home" />} />
                            <Route path="/home" element={wrapRouteWithAuth(QBHome)}/>
                            <Route path="/module/:moduleId/:moduleType" element={wrapRouteWithAuth(BasePageRender, otherRoles)}/>
                            <Route path="/settings" element={wrapRouteWithAuth(SettingsPage)}/>
                        </Route>

                    </Route>
                </Routes>
                </TourProvider>
            </SuperTokensWrapper>
        </BrowserRouter>
    );
};
export default App;

import React, { useState, useEffect } from 'react';
import { Modal, Table, Select, Button } from 'antd';
import { useTenant } from '../contexts/TenantContext';
import { ColumnsType } from 'antd/es/table';
import dataAll from "../utils/dataAll";


const { Option } = Select;

interface PermissionModalProps {
  visible: boolean;
  onClose: () => void;
  onSave: (updatedPermissions: any[]) => void;
  users: { id: string; name: string }[];
}

interface PageModule {
  _id: string;
  moduleName: string;
}

const PermissionModal: React.FC<PermissionModalProps> = ({ visible, onClose, onSave, users }) => {
  const [pageModules, setPageModules] = useState<PageModule[]>([]);
  const [userPermissions, setUserPermissions] = useState<any[]>([]);
  // Placeholder to check if user is a company admin
  const isCompanyAdmin = true; // Always true for now
  const { tenantId, companyId } = useTenant();  // Access tenantId and companyId here

  // Fetch page modules from backend
  useEffect(() => {
    if (visible && isCompanyAdmin) {
      const fetchModuleData = async () => {
        try {
          const allModules = await dataAll.dataExtractUtility.getAllModules(tenantId, companyId)
          setPageModules(allModules);
        } catch (error) {
        console.log(error)}
      }

      fetchModuleData()
    }
  }, [visible]);

  // Initialize user permissions
  useEffect(() => {
    if (users) {
      const initialPermissions = users.map((user) => ({
        userId: user.id,
        name: user.name,
        permissions: pageModules.map((module) => ({
          moduleId: module._id,
          moduleName: module.moduleName,
          access: 'none', // Default access level
        })),
      }));
      setUserPermissions(initialPermissions);
    }
  }, [users, pageModules]);

  // Handle permission change for a specific user and module
  const handlePermissionChange = (userId: string, moduleId: string, access: string) => {
    setUserPermissions((prev) =>
      prev.map((user) =>
        user.userId === userId
          ? {
              ...user,
              permissions: user.permissions.map((perm) =>
                perm.moduleId === moduleId ? { ...perm, access } : perm
              ),
            }
          : user
      )
    );
  };

  // Bulk update user permissions
  const handleBulkUpdate = (access: string) => {
    setUserPermissions((prev) =>
      prev.map((user) => ({
        ...user,
        permissions: user.permissions.map((perm) => ({ ...perm, access })),
      }))
    );
  };

  const handleSave = () => {
    onSave(userPermissions);
    onClose();
  };

  // Table columns
  const columns: ColumnsType<any> = [
    {
      title: 'User Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left', // Correctly typed as FixedType ("left" | "right")
      width: 200, // Required for fixed columns
    },
    ...pageModules.map((module) => ({
      title: module.moduleName,
      dataIndex: module._id,
      key: module._id,
      render: (_: any, record: any) => (
        <Select
          value={
            record.permissions.find((perm: any) => perm.moduleId === module._id)?.access || 'none'
          }
          onChange={(value) => handlePermissionChange(record.userId, module._id, value)}
          style={{ width: 120 }}
        >
          <Option value="none">None</Option>
          <Option value="view">View</Option>
          <Option value="edit">Edit</Option>
          <Option value="full">Full</Option>
        </Select>
      ),
    })),
  ];

  return (
    <Modal
      title="Manage Permissions"
      open={visible}
      onCancel={onClose}
      onOk={handleSave}
      width={1000}
    >
      <Button onClick={() => handleBulkUpdate('view')} style={{ marginBottom: 16 }}>
        Grant View to All
      </Button>
      <Button onClick={() => handleBulkUpdate('edit')} style={{ marginBottom: 16, marginLeft: 8 }}>
        Grant Edit to All
      </Button>
      <Button onClick={() => handleBulkUpdate('full')} style={{ marginBottom: 16, marginLeft: 8 }}>
        Grant Full to All
      </Button>
      <Table
        dataSource={userPermissions}
        columns={columns}
        rowKey="userId"
        pagination={false}
        scroll={{ x: 'max-content', y: '50vh' }} // Horizontal and vertical scrolling
      />
    </Modal>
  );
};

export default PermissionModal;

// TourManager.tsx
import React, { useState, useEffect } from 'react';
import Tooltip from './tourTooltip';
import { TourStep } from './tourSteps';
import '../../styles/tourStyles.css'
import { useTour } from './tourContext';

interface TourManagerProps {
  steps: TourStep[];
}

const TourManager: React.FC<TourManagerProps> = ({ steps }) => {
  const { currentStep, setCurrentStep, totalSteps } = useTour();
  const [targetRect, setTargetRect] = useState<DOMRect | null>(null);
  const [isTourActive, setIsTourActive] = useState<boolean>(false);

  // Initialize tour state based on localStorage
  useEffect(() => {
    const dismissed = localStorage.getItem('tutorialDismissed') === 'true';
    if (!dismissed && steps.length > 0) {
      setIsTourActive(true);
    }
  }, [steps]);

  // Manage highlighting and positioning
  useEffect(() => {
    if (isTourActive && steps[currentStep]) {
      const step = steps[currentStep];
      const targetElement = document.querySelector(step.selector);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        setTargetRect(rect);
        targetElement.classList.add('highlight');
    
        // Scroll the target element into view for added visibility
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    // Cleanup previous step's highlight
    return () => {
      if (steps[currentStep]) {
        const targetElement = document.querySelector(steps[currentStep].selector);
        if (targetElement) {
          targetElement.classList.remove('highlight');
        }
      }
    };
  }, [currentStep, isTourActive, steps]);


  // Handle Skip button
  const handleSkip = () => {
    endTour();
  };

  // Inside the endTour function
  const endTour = () => {
    setIsTourActive(false);
    localStorage.setItem('tutorialDismissed', 'true');
    localStorage.removeItem('currentTourStep'); // Clear step progress
  };

  // Handle Escape key to skip the tour
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isTourActive) {
      handleSkip();
    }
  };

  // Attach and detach keydown listener
  useEffect(() => {
    if (isTourActive) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTourActive]);

  return (
    <>
      {isTourActive && steps[currentStep] && (
        <Tooltip
          content={steps[currentStep].content}
          position={steps[currentStep].position}
          targetRect={targetRect}
          videoUrl={steps[currentStep].videoUrl} // Pass the video URL if it exists
          onSkip={handleSkip}
        />
      )}
    </>
  );
};

export default TourManager;
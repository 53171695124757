import React, {useEffect, useState} from "react";
import GridLayout from "react-grid-layout";
import { Page, Text, View, Document, PDFViewer } from "@react-pdf/renderer";
import {Button, Input, message, Modal, Select, Tooltip} from "antd";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import dataAll from "../../../utils/dataAll";
import {useTenant} from "../../../contexts/TenantContext"
import {useLocation} from "react-router-dom";
import {pdfStyles, getDynamicStyle, getTextStyle} from "./pdfStyles";
import { builderContainerStyle } from "./DocumentDesigner.styles"
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import Editor from "./Editor"

interface ElementPayload {
    id: string; // forced unique ID (used for layout and payload)
    type: string; // e.g. "field", "textBox", or "line"
    x: number;
    y: number;
    w: number;
    h: number;
    options: { [key: string]: any };
    field?: Field;
}

interface Field {
    id: string;
    name: string;
    type: string;
}

interface DocumentPayload {
    id?: string;
    name: string;
    tenantId: number;
    companyId: number;
    type: string; // typically selectedModule.moduleName
    moduleId: string;
    pageSize: string;
    margins: { top: number; right: number; bottom: number; left: number };
    elements: ElementPayload[];
}

const DocumentDesigner = () => {
    const [documentName, setDocumentName] = useState("New Document");
    const [availableModules, setAvailableModules] = useState<any[]>([]);
    const [selectedModule, setSelectedModule] = useState<any>(null);
    const [availableFields, setAvailableFields] = useState<any[]>([]);
    const [selectedField, setSelectedField] = useState<any>(null);
    const [selectedShape, setSelectedShape] = useState<any>(null);
    const [pageSize, setPageSize] = useState("8.5x11");
    const [elements, setElements] = useState<ElementPayload[]>([]);
    const [layout, setLayout] = useState<any[]>([]);
    const { tenantId, companyId } = useTenant();
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [existingDocument, setExistingDocument] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editingElement, setEditingElement] = useState(null);
    const { Option } = Select;
    const location = useLocation();
    const [isModuleLocked, setIsModuleLocked] = useState(false);
    const [elementOptions, setElementOptions] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        if (location.state?.existingDocument) {
            setExistingDocument(location.state.existingDocument);
        }
    }, [location.state]);

    useEffect(() => {
        if (existingDocument && availableModules.length > 0) {
            setDocumentName(existingDocument.name || "Untitled Document");
            setPageSize(existingDocument.pageSize || "8.5x11");
            const matchedModule = availableModules.find(
                (mod) => mod._id === existingDocument.moduleId
            );
            setSelectedModule(matchedModule || null);
            const rawElements = Array.isArray(existingDocument.elements)
                ? existingDocument.elements
                : [];
            const docElements = transformElements(rawElements);
            setElements(docElements);
            setLayout(docElements);
        }
    }, [existingDocument, availableModules]);

    useEffect(() => {
        const fetchDocumentDetails = async () => {
            if (!existingDocument) return;
            try {
                const documentData = await dataAll.dataExtractUtility.getDocumentById(
                    tenantId,
                    companyId,
                    existingDocument.id
                );
                if (documentData) {
                    setDocumentName(documentData.name || "Untitled Document");
                    setSelectedModule(
                        availableModules.find((mod) => mod._id === documentData.type) || null
                    );
                    setPageSize(documentData.pageSize || "8.5x11");
                    const transformed = transformElements(documentData.elements || []);
                    setElements(transformed);
                    setLayout(transformed);
                }
            } catch (error) {
                console.error("Failed to fetch document details:", error);
            }
        };

        if (existingDocument) {
            fetchDocumentDetails();
        }
    }, [existingDocument]);

    const findAvailableModules = async () => {
        try {
            const mods = await dataAll.dataExtractUtility.getAllModules(tenantId, companyId)
            setAvailableModules(mods);
        } catch (error) {
            console.log('Error setting modules: ', error)
        }
    };

    useEffect(() => {
        if (tenantId && companyId) {
            findAvailableModules();
        }
    }, [tenantId, companyId]);

    const findAvailableFields = async () => {
        try {
            const fields = await dataAll.dataExtractUtility.getModuleDataFields(selectedModule._id)
            setAvailableFields(fields);
        } catch (error) {
            console.log('Error setting fields: ', error)
        }
    };

    useEffect(() => {
        findAvailableFields()
    }, [selectedModule]);

    // Non-field elements (shapes, lines, etc.)
    const nonFieldElements = [
        { key: "textbox", label: "Text Box" },
        { key: "line", label: "Line" },
    ];

    const transformElements = (rawElements: any[]): ElementPayload[] => {
        return rawElements.map((item) => {
            let obj: any;
            if (Array.isArray(item)) {
                obj = {};
                item.forEach(({ Key, Value }: { Key: string; Value: any }) => {
                    obj[Key] = Value;
                });
            } else {
                obj = { ...item };
            }
            if (!obj.options || typeof obj.options !== "object") {
                obj.options = {};
            }
            // Standardize the field property if present
            if (!obj.field || (!obj.field.id && !obj.field.name && !obj.field.type)) {
                // Try to extract field info from the item if available
                const fieldData = Array.isArray(item)
                    ? item.find((i) => i.Key === "field")
                    : null;
                if (fieldData && Array.isArray(fieldData.Value)) {
                    const fArr = fieldData.Value;
                    const fId = fArr.find((f) => f.Key === "id")?.Value || "";
                    const fName = fArr.find((f) => f.Key === "name")?.Value || "";
                    const fType = fArr.find((f) => f.Key === "type")?.Value || "";
                    obj.field = { id: fId, name: fName, type: fType };
                } else {
                    obj.field = { id: "", name: "", type: "" };
                }
            } else {
                obj.field = {
                    id: obj.field.id || "",
                    name: obj.field.name || "",
                    type: obj.field.type || "",
                };
            }
            if (!obj.id && obj.i) {
                obj.id = obj.i;
            }
            return obj;
        });
    };

    const buildPayload = (): DocumentPayload => {
        return {
            name: documentName,
            tenantId: tenantId,
            companyId: companyId,
            type: selectedModule.moduleName, // e.g. "Invoice" etc.
            moduleId: selectedModule._id,      // use _id consistently
            pageSize,
            margins: { top: 20, right: 20, bottom: 20, left: 20 },
            elements: elements.map((el) => ({
                id: el.id,
                type: el.type,
                x: el.x,
                y: el.y,
                w: el.w,
                h: el.h,
                options: el.options, // Already an array of Option
                field: el.field,     // Already conforms to Field interface
            })),
        };
    };

    const handleSave = async () => {
        if (!documentName || !selectedModule || !pageSize) {
            console.warn("Missing required fields before saving.");
            message.error("Failed to save")
            return;
        }

        const payload = buildPayload();

        try {
            if (existingDocument) {
                await dataAll.dataPostUtility.updateDocument(
                    tenantId,
                    companyId,
                    existingDocument.id,
                    payload
                );
                // console.log("Document updated successfully.");
                message.success("Document updated successfully.");
            } else {
                await dataAll.dataPostUtility.createDocument(
                    tenantId,
                    companyId,
                    payload
                );
                // console.log("Document created successfully:", response);
                message.success("Document created successfully.");
            }
        } catch (error) {
            message.error("Failed to save document")
            console.error("Failed to save document:", error);
        }
    };

    const onChangeModule = (value) => {
        const module = availableModules.find((mod) => mod._id === value);
        if (module) {
            setSelectedModule(module);
        } else {
            console.warn("Module not found in availableModules:", value);
        }
    };

    const onChangeField = (value: string) => {
        const field = availableFields.find((field) => field.id === value);
        if (field) {
            setSelectedField(field);
        } else {
            console.warn("Field not found in availableFields:", value);
        }
    };


    const openElementEditor = (el: ElementPayload) => {
        setEditingElement(el);
        setElementOptions(el.options || {});
        setIsEditing(true);
    };

    const updateOption = (key: string, value: any) => {
        setElementOptions((prev) => ({ ...prev, [key]: value }));
    };

    const handleSaveElementOptions = () => {
        setElements((prevElements) =>
            prevElements.map((el) =>
                el.id === editingElement?.id ? { ...el, options: elementOptions } : el
            )
        );
        setIsEditing(false);
    };

    const addElement = (element: any, type: string) => {
        if (!type) return;
        const normalizedType =
            type === "textbox" ? "textBox" : type === "line" ? "line" : "field";
        const newElement: ElementPayload = {
            id: `${normalizedType}-${Date.now()}`,
            type: normalizedType,
            x: 0,
            y: layout.length * 2,
            w: 4,
            h: 3,
            options:
                normalizedType === "textBox"
                    ? { text: "Placeholder Text" }
                    : normalizedType === "line"
                        ? { style: "solid", color: "#000000" }
                        : {},
            field: normalizedType === "field" ? (element as Field) : undefined,
        };
        setLayout((prev) => [...prev, newElement]);
        setElements((prev) => [...prev, newElement]);
        if (normalizedType === "field") {
            setIsModuleLocked(true);
        }
    };

    const removeElement = (elementId: string) => {
        setElements(elements.filter((el) => el.id !== elementId));
        setLayout(layout.filter((el) => el.id !== elementId));
    };

    const ELEMENT_TYPES = {
        field: {
            border: "1px solid #ddd",
            padding: "10px",
            background: "none",
        },
        textBox: {
            border: "2px solid black",
            padding: "10px",
            background: "rgba(0,0,0,0.1)",
        },
        line: {
            borderBottom: "2px solid black",
            height: "2px",
            width: "100%",
            padding: "0",
        },
    };

    const handleLayoutChange = (newLayout) => {
        setLayout(newLayout);
        setElements((prevElements) =>
            prevElements.map((el) => {
                const updated = newLayout.find((item) => item.i === el.id);
                return updated
                    ? { ...el, x: updated.x, y: updated.y, w: updated.w, h: updated.h }
                    : el;
            })
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "20px" }}>
            {/* Header Row */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px", alignItems: "center" }}>
                <Input
                    value={documentName}
                    onChange={(e) => setDocumentName(e.target.value)}
                    placeholder="Document Name"
                    style={{ width: "250px" }}
                />
                <Select value={pageSize} onChange={setPageSize} style={{ width: "180px" }}>
                    <Option value="8.5x11">8.5 x 11 (Letter)</Option>
                </Select>
                <Select
                    value={selectedModule?._id || undefined}
                    onChange={onChangeModule}
                    placeholder="Select Module"
                    style={{ minWidth: "250px" }}
                    disabled={isModuleLocked}
                >
                    {availableModules.map((mod) => (
                        <Option key={mod._id} value={mod._id}>
                            <Tooltip title={mod.moduleName}>{mod.moduleName}</Tooltip>
                        </Option>
                    ))}
                </Select>
            </div>

            {/* Row 2: Field/Shape Selectors & Buttons */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px", alignItems: "center" }}>
                <Select
                    value={selectedField?._id || undefined}
                    onChange={onChangeField}
                    placeholder="Select Field"
                    style={{ width: "200px" }}
                >
                    {availableFields.map((field) => (
                        <Option key={field.id} value={field.id}>
                            {field.name}
                        </Option>
                    ))}
                </Select>
                <Button onClick={() => addElement(selectedField, "field")} disabled={!selectedField}>
                    Add Field
                </Button>
                <Select
                    value={selectedShape}
                    onChange={setSelectedShape}
                    placeholder="Select Shape"
                    style={{ width: "180px" }}
                >
                    {nonFieldElements.map((shape) => (
                        <Option key={shape.key} value={shape.key}>
                            {shape.label}
                        </Option>
                    ))}
                </Select>
                <Button onClick={() => addElement(selectedShape, selectedShape)} disabled={!selectedShape}>
                    Add Shape
                </Button>
                <Button type="primary" onClick={handleSave}>
                    Save
                </Button>
                <Button onClick={() => setIsPreviewOpen(true)}>Preview PDF</Button>
            </div>

            {/* Drag-and-Drop Canvas */}
            <div style={builderContainerStyle}>
                <GridLayout
                    className="layout"
                    cols={20}
                    rowHeight={30}
                    width={850}
                    maxRows={200}
                    compactType={null}
                    isBounded={false}
                    autoSize={false}
                    onLayoutChange={handleLayoutChange}
                >
                    {elements.map((el) => {
                        const elementType =
                            el.type === "textBox" ? "textBox" : el.type === "line" ? "line" : "field";
                        return (
                            <div
                                key={el.id}
                                data-grid={{
                                    i: el.id,
                                    x: el.x,
                                    y: el.y,
                                    w: el.w,
                                    h: el.h,
                                }}
                                style={{
                                    ...ELEMENT_TYPES[elementType],
                                    position: "relative",
                                }}
                            >
                                {elementType === "field"
                                    ? getFieldName(el.field)
                                    : el.options.text || ""}
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "2px",
                                        right: "2px",
                                        display: "flex",
                                        gap: "4px",
                                        zIndex: 10,
                                        pointerEvents: "auto",
                                    }}
                                >
                                    <Button
                                        danger
                                        type="default"
                                        icon={<DeleteOutlined />}
                                        size="small"
                                        style={{ pointerEvents: "auto", border: "1px solid black", width: "20px", height: "20px" }}
                                        onClick={() => removeElement(el.id)}
                                    />
                                    <Button
                                        type="default"
                                        icon={<EditOutlined />}
                                        size="small"
                                        style={{ pointerEvents: "auto", border: "1px solid black", width: "20px", height: "20px" }}
                                        onClick={() => openElementEditor(el)}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </GridLayout>
            </div>

            {/* PDF Preview Modal */}
            <Modal title="PDF Preview" open={isPreviewOpen} onCancel={() => setIsPreviewOpen(false)} footer={null} width={800}>
                <PDFViewer style={{ width: "100%", height: "600px" }}>
                    <Document>
                        <Page style={pdfStyles.page} size="LETTER">
                            {elements.map((el, i) => {
                                const elementType =
                                    el.type === "textBox" ? "textBox" : el.type === "line" ? "line" : "field";
                                const combinedStyle = [pdfStyles[elementType], getDynamicStyle(el)];
                                return (
                                    <View key={i} style={combinedStyle}>
                                        {(elementType === "field" || elementType === "textBox") && (
                                            <Text style={[pdfStyles.text, getTextStyle(el)]}>
                                                {el.field ? getFieldName(el.field) : el.options.text || "Field"}
                                            </Text>
                                        )}
                                    </View>
                                );
                            })}
                        </Page>
                    </Document>
                </PDFViewer>
            </Modal>

            {/* Element Options Modal */}
            <Modal title="Edit Element" open={isEditing} onCancel={() => setIsEditing(false)} onOk={handleSaveElementOptions}>
                {editingElement && editingElement.type === "textBox" && (
                    <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Text:</label>
                        <Editor
                            defaultValue={elementOptions.text || ""}
                            onChange={(newContent) => updateOption("text", newContent)}
                        />
                    </div>
                )}
                {editingElement && editingElement.type === "field" && (
                    <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Formatting:</label>
                        <Select
                            style={{ width: "150px", marginBottom: "10px" }}
                            defaultValue={elementOptions.format || "Default"}
                            onChange={(value) => updateOption("format", value)}
                        >
                            <Option value="default">Default</Option>
                            <Option value="bold">Bold</Option>
                            <Option value="italic">Italic</Option>
                        </Select>
                        <div>
                            <label style={{ display: "block", marginBottom: "4px" }}>Font Size:</label>
                            <Input
                                type="number"
                                style={{ width: "100px" }}
                                value={elementOptions.fontSize || 12}
                                onChange={(e) => updateOption("fontSize", parseInt(e.target.value))}
                            />
                        </div>
                    </div>
                )}
                {editingElement && editingElement.type === "line" && (
                    <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Line Style:</label>
                        <Select
                            style={{ width: "150px", marginBottom: "10px" }}
                            value={elementOptions.style || "solid"}
                            onChange={(value) => updateOption("style", value)}
                        >
                            <Option value="solid">Solid</Option>
                            <Option value="dashed">Dashed</Option>
                            <Option value="dotted">Dotted</Option>
                        </Select>
                        <div>
                            <label style={{ display: "block", marginBottom: "4px" }}>Line Color:</label>
                            <Input
                                type="color"
                                value={elementOptions.color || "#000000"}
                                onChange={(e) => updateOption("color", e.target.value)}
                            />
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default DocumentDesigner;

function getFieldName(field?: Field): string {
    return field ? field.name : "";
}

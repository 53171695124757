import React from 'react';

const TrainingModal = () => {
    const trainingVideos = [
        // { title: 'Getting Started with QuickBuild', url: 'https://www.youtube.com/embed/WrbVwRqqNoA' },
        // { title: 'How to Set Up Modules', url: 'https://www.youtube.com/embed/WrbVwRqqNoA' },
        { title: 'Setup and Configure Transaction Stages', url: 'https://www.youtube.com/embed/WrbVwRqqNoA' },
        { title: 'Setup a One to Many Field', url: 'https://www.youtube.com/embed/X6J3Sw_5AA8?si=MdoD3GWa1ulDAOfo' },
        // { title: 'Setup and Configure Transaction Stages', url: 'https://www.youtube.com/embed/WrbVwRqqNoA' },

    ];

    return (
        <div style={{ padding: '20px' }}>
            <h1>Training Videos</h1>
            <p>Watch these training videos to learn how to use QuickBuild more effectively.</p>
            <div style={{
                display: 'flex', flexWrap: 'wrap', gap: '20px',
                margin: '0 auto',
                maxWidth: '60vw',
            }}>
                {trainingVideos.map((video, index) => (
                    <div key={index} style={{ flex: '1 1 300px', maxWidth: '30vw' }}>
                        <h3>{video.title}</h3>
                        <iframe
                            width="100%"
                            height="250"
                            src={video.url}
                            title={video.title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TrainingModal;
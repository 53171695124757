import React from "react";
import { Tabs } from "antd";
import PageBuilder from "./PageBuilder";
import DocumentList from "./Documents/DocumentList"

const { TabPane } = Tabs;

const BuilderHomePage = () => {
  return (
      <div>
        <Tabs defaultActiveKey="pages">
          <TabPane tab="Pages" key="pages">
            <PageBuilder />
          </TabPane>
          <TabPane tab="Documents" key="documents">
            <DocumentList />
          </TabPane>
        </Tabs>
      </div>
  );
};

export default BuilderHomePage;



// import React, { useState, useEffect } from 'react';
// import { useTenant } from '../../contexts/TenantContext';
// import { useUser } from '../../contexts/UserContext';
// import { Button, Table, Modal, Input, notification } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import TourManager from '../../contexts/tour/tourManager';
// import { tourSteps } from '../../contexts/tour/tourSteps';
// import dataAll from "../../utils/dataAll";
//
// const BuilderHomePage = () => {
//   const { tenantId, companyId } = useTenant();
//   const [modules, setModules] = useState([]);  // Ensure modules is always an array
//   const navigate = useNavigate(); // Import and use useNavigate correctly
//   const [selectedModuleType, setSelectedModuleType] = useState(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
//   const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
//   const [newModuleName, setNewModuleName] = useState('');
//   const [templateName, setTemplateName] = useState('');
//   const [moduleToCopy, setModuleToCopy] = useState(null); // The module we're copying
//   const { user } = useUser();
//
//   useEffect(() => {
//     const fetchModules = async () => {
//       try {
//         const modules = await dataAll.dataExtractUtility.getAllModules(tenantId, companyId);
//         setModules(modules);
//       } catch (error) {
//         console.error('Error fetching modules: ', error);
//       }
//     }
//
//     if (tenantId && companyId) {
//       fetchModules();
//     }
//   }, [tenantId, companyId]);
//
//   const columns = [
//     {
//       title: 'Module Name',
//       dataIndex: 'moduleName',
//       key: 'moduleName',
//     },
//     {
//       title: 'Status',
//       dataIndex: 'status',
//       key: 'status',
//     },
//     // {
//     //   title: 'Version',
//     //   dataIndex: 'version',
//     //   key: 'version',
//     // },
//     {
//       title: 'Page Type',
//       dataIndex: 'pageType',
//       key: 'pageType',
//       render: (text, record) => {
//         // You can decide how to map the "pageType" based on your module structure.
//         return record.pageType || 'Unknown';  // Directly accessing the top-level field pageType
//       }
//     },
//     {
//       title: 'Actions',
//       key: 'actions',
//       render: (text, record) => (
//           <>
//           <button
//             className='edit-button'
//             onClick={() => handleEdit(record._id)}
//             >Edit</button>
//           <Button type="link" onClick={() => handleDelete(record._id)}>Delete</Button>
//           <Button type="link" onClick={() => handleCopyClick(record)}>Copy</Button> {/* Trigger the Copy modal */}
//           <Button type="link" onClick={() => handlePublish(record._id)}>Publish</Button>
//         </>
//       )
//     }
//   ];
//
//
// const handleEdit = async (moduleId: string) => {
//   try {
//     const singleModule = await dataAll.dataExtractUtility.getModuleData(moduleId);
//     const singleModData = singleModule.moduleData;
//     const pageType = singleModData.pageType.toLowerCase() || 'unknown';
//     const url = "/builder/" + pageType + "-page-builder"
//     navigate(url, {state: {module: singleModData}});
//   } catch (error) {
//     console.error("Error fetching single module: ", error);
//   }
// };
//
// const handleDelete = async (moduleId: string) => {
//   const confirmed = window.confirm("Are you sure you want to delete this module?");
//   if (!confirmed) return;
//
//   const deleteModule = async (moduleId: string ) => {
//     try {
//       const deleteThisModule = await dataAll.dataPostUtility.deleteData('module', moduleId);
//       setModules(modules.filter(module => module._id !== moduleId));
//       // console.log('deleted module: ', deleteThisModule);
//       notification.success({
//         message: 'Page Deleted',
//         placement: 'top',
//         duration: 3
//       })
//     } catch (error) {
//       console.error('Failed to Delete: ', error);
//     }
//   };
//
//   await deleteModule(moduleId);
// };
//
// const handleCopyClick = (module) => {
//   setModuleToCopy(module); // Set the module to copy
//   setNewModuleName(''); // Reset the module name input
//   setIsCopyModalVisible(true); // Open the modal
// };
//
// // Handle copy module logic when the user confirms the modal
// const handleCopyModule = async () => {
//   if (!newModuleName.trim()) {
//     alert("Please enter a new module name.");
//     return;
//   }
//
//
//   try {
//     const response = await fetch(`${API_URL}/modules/copy`, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({
//         moduleId: moduleToCopy._id, // Pass the full module to copy
//         newModuleName, // Pass the new module name
//       }),
//     });
//
//     if (response.ok) {
//       const newModule = await response.json();
//       // console.log('response ok')
//       setModules([...modules, newModule]); // Add the copied module to the module list
//       setIsCopyModalVisible(false); // Close the modal
//       notification.success({
//         message: 'Page Copied',
//         description: 'Refresh for alphabetical order, or see the page at the end of the list.',
//         placement: 'top',
//         duration: 3
//       })
//     } else {
//       console.error("Failed to copy module.");
//     }
//   } catch (error) {
//     console.error("Error copying module:", error);
//   }
// };
//
// const handlePublish = async (moduleId: string) => {
//
//   try {
//     const publishedModules = await dataAll.dataExtractUtility.getPublishedModules(tenantId, companyId);
//     const PUBLISHED_LIMIT = 50
//     if (publishedModules.length >= PUBLISHED_LIMIT && user.roles.includes('freetrial')) {
//       notification.warning({
//         message: 'Publish Limit Reached',
//         description: `Free Trial has a limit of ${PUBLISHED_LIMIT} published pages. Please upgrade to publish more.`,
//         placement: 'top',
//       });
//       return; // Exit the function if the limit is reached
//     }
//     const updatedModule = await dataAll.dataPostUtility.publishModule(moduleId);
//     if (updatedModule) {
//       setModules(modules.map(module =>
//           module._id === moduleId ? updatedModule : module
//       ));  // Update the module's status in the state
//
//       console.log("Module published successfully");
//       notification.success({
//         message: 'Page Published',
//         description: 'Refresh the screen to see your new page.',
//         placement: 'top',
//         duration: 3
//       })
//
//     } else {
//       console.error("Failed to publish module.");
//     }
//
//   } catch (error) {
//     console.error(error);
//   }
//
// };
//
//
// // Open the modal when the user clicks "New"
// const handleNewModuleClick = () => {
//   setIsModalVisible(true); // Show the modal
// };
//
//   const handleNewTemplateClick = () => {
//     setIsTemplateModalVisible(true); // Show the modal
//   };
//
// // Handle modal option selection
// const handleModalOk = () => {
//   if (selectedModuleType === 'Reference') {
//     navigate('/builder/reference-page-builder'); // Navigate if Reference is selected
//   }
//   if (selectedModuleType === 'Transaction') {
//     navigate('/builder/transaction-page-builder'); // Navigate if Reference is selected
//   }
//   setIsModalVisible(false); // Close the modal after selection
// };
//
//   const handleTemplateModalOk = () => {
//     dataAll.dataPostUtility.createTemplate(tenantId, companyId, templateName);
//
//     setIsTemplateModalVisible(false); // Close the modal after selection
//   };
//
// const handleModalCancel = () => {
//   setIsModalVisible(false); // Just close the modal on cancel
//   setIsCopyModalVisible(false);
//   setIsTemplateModalVisible(false);
// };
//
//   return (
//     <div>
//       {window.location.pathname === '/builder' && modules.length > 0 && (
//         <TourManager steps={tourSteps.builderHome} />
//       )}
//       <h1>Page Library</h1>
//       <Table dataSource={modules} columns={columns} rowKey="_id" />
//       <Button type="primary" onClick={handleNewModuleClick}>
//         + New
//       </Button>
//       <Button
//           type="primary"
//           onClick={handleNewTemplateClick}
//           style={{paddingLeft: '2vw'}}
//           hidden={true}
//       >
//         + Save as Template
//       </Button>
//
//       {/* Modal to ask for module type */}
//       <Modal
//         title="What kind of page experience do you want?"
//         open={isModalVisible}
//         onOk={handleModalOk}
//         onCancel={handleModalCancel}
//       >
//         <Button
//           type={selectedModuleType === 'Reference' ? 'primary' : 'default'}
//           onClick={() => setSelectedModuleType('Reference')}
//           style={{ marginBottom: '10px', width: '100%' }}
//         >
//           Reference
//           (Customers, Vendors, Item)
//         </Button>
//         <Button
//           type={selectedModuleType === 'Transaction' ? 'primary' : 'default'}
//           onClick={() => setSelectedModuleType('Transaction')}
//           style={{ marginBottom: '10px', width: '100%' }}
//         >
//           Transaction
//           (Sales, Purchasing, Production)
//         </Button>
//       </Modal>
//       <Modal
//           title="Enter Template Details"
//           open={isTemplateModalVisible}
//           onOk={handleTemplateModalOk}
//           onCancel={handleModalCancel}
//       >
//         <Input
//             value={templateName}
//             onChange={(e) => setTemplateName(e.target.value)}
//             placeholder="Enter template name"
//         />
//       </Modal>
//       {/* Copy Module Modal */}
//             <Modal
//         title="Copy Module"
//         open={isCopyModalVisible}
//         onOk={handleCopyModule}  // Handle copying logic
//         onCancel={() => setIsCopyModalVisible(false)}  // Close the modal
//       >
//         <Input
//           value={newModuleName}
//           onChange={(e) => setNewModuleName(e.target.value)}
//           placeholder="Enter new module name"
//         />
//       </Modal>
//
//     </div>
//   );
// };
//
// export default BuilderHomePage;

import React, {useState, useEffect, useContext} from "react";
import { Table, Button, Modal, notification } from "antd";
import { useNavigate } from "react-router-dom";
import dataAll from "../../../utils/dataAll";
import {useTenant} from "../../../contexts/TenantContext";

const DocumentList = () => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { tenantId, companyId } = useTenant()


    useEffect(() => {
        fetchDocuments();
    }, [tenantId, companyId]);

    const fetchDocuments = async () => {
        setLoading(true);
        try {
            const response = await dataAll.dataExtractUtility.getAllDocuments(tenantId, companyId);
            setDocuments(response);
        } catch (error) {
            console.error("Failed to fetch documents:", error);
        } finally {
            setLoading(false);
        }
    };


    const deleteDocument = async (documentId: string) => {
        Modal.confirm({
            title: "Are you sure?",
            content: "This will permanently delete the document.",
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk: async () => {
                try {
                    await dataAll.dataPostUtility.deleteDocument(tenantId, companyId, documentId);
                    notification.success({ message: "Document deleted successfully" });
                    fetchDocuments();
                } catch (error) {
                    console.error("Error deleting document:", error);
                }
            },
        });
    };

    const handleEditDocument = (record) => {
        navigate('/builder/document-builder', { state: { existingDocument: record } });
    };

    const columns = [
        { title: "Name", dataIndex: "name", key: "name" },
        { title: "Type", dataIndex: "type", key: "type" },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <>
                    <Button onClick={() => handleEditDocument(record)}>Edit</Button>
                    <Button danger onClick={() => deleteDocument(record.id)}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <>
            <h3>Documents coming soon!</h3>
            {/*<Table*/}
            {/*    columns={columns}*/}
            {/*    dataSource={documents}*/}
            {/*    loading={loading} rowKey="_id" />*/}
            {/*<Button*/}
            {/*    type="primary"*/}
            {/*    style={{ marginTop: "10px" }}*/}
            {/*    onClick={() => navigate("/builder/document-builder")}*/}
            {/*>*/}
            {/*    + New*/}
            {/*</Button>*/}
        </>
    )
};

export default DocumentList;
import React, { useState, useEffect, useMemo } from 'react';
import { create, all } from 'mathjs';
import { Parser } from 'expr-eval';

interface CalculationFieldRenderProps {
  field: any;
  rowData: any;
  columns: any[];
  handleFieldChange: Function;
  sectionId: string;
  rowId: string;
}

// Configure mathjs instance
// const math = create(all, {
//   number: 'number',
//   precision: 14,
// });

const evaluateExpression = (expression, rowData, columns) => {
  try {
    // Create a scope object for variable substitution
    const scope = {};
    Object.entries(rowData).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        if ("uuid" in value) {
          scope[key] = value.uuid || 0;
        } else if ("value" in value) {
          scope[key] = value.value || 0;
        }
      } else {
        scope[key] = value || 0;
      }
    });

    // Sanitize the expression to replace field IDs with their corresponding values
    const sanitizedExpressionValues = expression.replace(
        /\[(.+?)\]/g,
        (match, fieldId) => {
          const value = scope[fieldId];
          if (value === undefined || value === null) {
            console.warn(`Missing value for field ID: ${fieldId}`);
            return "0"; // Default to 0 if the value is missing
          }
          return value; // Replace with the actual value from scope
        }
    );

    // console.log("Sanitized Expression with Values:", sanitizedExpressionValues);

    // Parse and evaluate the sanitized expression
    const parser = new Parser();
    return parser.evaluate(sanitizedExpressionValues);
  } catch (error) {
    console.error("Error evaluating expression:", error);
    return ""; // Return an empty string on error
  }
};

// const evaluateExpression = (expression: string, rowData: any) => {
//   const scope: { [key: string]: any } = {};
//
//   Object.entries(rowData).forEach(([key, value]) => {
//     const sanitizedKey = key.replace(/-/g, '_'); // Replace hyphens with underscores
//     if (typeof value === 'object' && value !== null) {
//       if ('value' in value) {
//         scope[sanitizedKey] = value.value || 0;
//       } else {
//         scope[sanitizedKey] = value || 0;
//       }
//     } else {
//       scope[sanitizedKey] = value || 0;
//     }
//   });
//
//   scope.PI = Math.PI;
//   scope.E = Math.E;
//
//   const sanitizedExpression = expression.replace(/\[(field_\d+|[\w-]+)\]/g, (match) =>
//       match.replace(/-/g, '_').replace(/\[|\]/g, '')
//   );
//
//   try {
//     const parser = new Parser();
//       const calcresult = parser.evaluate(sanitizedExpression, scope);
//     return calcresult; //parser.evaluate(parsedExpression, scope);
//   } catch (error) {
//     console.error('Error evaluating expression:', error);
//     return ''; // Return empty string on error
//   }
// };

const CalculationFieldRender: React.FC<CalculationFieldRenderProps> = ({
                                                                         field,
                                                                         rowData,
                                                                         columns,
                                                                         handleFieldChange,
                                                                       }) => {
  const [calculatedValue, setCalculatedValue] = useState<string | number>('');

  // Extract dependent field values based on the formula
  const dependentValues = useMemo(() => {
    const fieldIds = field.fieldOptions.backendFormula.match(/\[(field_\d+)\]/g) || [];
    return fieldIds.map((id) => rowData[id.replace(/\[|\]/g, '')] || 0);
  }, [field.fieldOptions.backendFormula, rowData]);

  useEffect(() => {
    const result = evaluateExpression(field.fieldOptions.backendFormula, rowData, columns);
    setCalculatedValue(result);

    if (rowData && rowData[field.id] !== result) {
      handleFieldChange(result);
    }
  }, [field.fieldOptions.backendFormula, dependentValues]); // Track changes to dependent values

  return <div className="render-calculated-value">{calculatedValue}</div>;
};

export default CalculationFieldRender;

// import React, { useState, useEffect } from 'react';
// import { create, all, row } from 'mathjs';
// import { Parser } from 'expr-eval';
//
//
// interface CalculationFieldRenderProps {
//   field: any;
//   rowData: any;
//   columns: any[];
//   handleFieldChange: Function;
//   sectionId: string;
//   rowId: string;
// }
//
// // Configure mathjs instance
// const math = create(all, {
//   // Add custom configuration if needed
//   number: 'number', // Use "number" to prevent BigNumber by default
//   precision: 14, // Default precision
// });
//
// const evaluateExpression = (expression: string, rowData: any, columns: any[]) => {
//
//   // Create a scope object for variable substitution
//   const scope: { [key: string]: any } = {};
//
//   Object.entries(rowData).forEach(([key, value]) => {
//     if (typeof value === 'object' && value !== null) {
//       if ('uuid' in value) {
//         scope[key] = value.uuid || 0;
//       } else if ('value' in value) {
//         scope[key] = value.value || 0;
//       }
//     } else {
//       scope[key] = value || 0;
//     }
//   });
//
//   // Add global constants
//   scope.PI = Math.PI;
//   scope.E = Math.E;
//
//   // Replace placeholders with variable names
//   const parsedExpression = expression.replace(/\[(field_\d+)\]/g, '$1');
//
//   try {
//     const parser = new Parser();
//     const result = parser.evaluate(parsedExpression, scope);
//     return result;
//   } catch (error) {
//     console.error('Error evaluating expression:', error);
//     return ''; // Return an empty string on error
//   }
// };
//
// const CalculationFieldRender: React.FC<CalculationFieldRenderProps> = ({
//   field,
//   rowData,
//   columns,
//   handleFieldChange,
// }) => {
//   const [calculatedValue, setCalculatedValue] = useState<string | number>('');
//
//   useEffect(() => {
//     const result = evaluateExpression(field.fieldOptions.backendFormula, rowData, columns || []);
//     console.log('result: ', result)
//     setCalculatedValue(result);
//
//
//     // Only update if value has changed to prevent infinite loops
//     if (rowData && rowData[field.id] !== result) {
//       handleFieldChange(result);
//     }
//   }, [field.fieldOptions.backendFormula, rowData, columns]);
//
//   return <div className='render-calculated-value'>{calculatedValue}</div>;
// };
//
// export default CalculationFieldRender;
//

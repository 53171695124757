import React, { useState } from 'react';
import { Input, Form } from 'antd';

interface URLFieldRenderProps {
    field: any;
    handleFieldChange: Function;
    value?: string;
    renderType?: 'table' | 'section';
    required?: boolean;
}

const URLFieldRender: React.FC<URLFieldRenderProps> = ({
                                                           field,
                                                           handleFieldChange,
                                                           value = '',
                                                           renderType,
                                                           required
                                                       }) => {
    const [url, setUrl] = useState(value);
    const [isValid, setIsValid] = useState(true);

    const validateURL = (input: string) => {
        try {
            new URL(input); // Throws an error if invalid
            setIsValid(true);
            return true;
        } catch {
            setIsValid(false);
            return false;
        }
    };

    const handleBlur = () => {
        if (url) validateURL(url);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setUrl(newValue);
        handleFieldChange(newValue);
    };

    if (renderType === 'table') {
        return (
            <Input
                value={url}
                onChange={handleChange}
                onBlur={handleBlur}
                status={isValid ? undefined : 'error'}
                placeholder="Enter URL"
            />
        );
    } else {
        return (
            <Form.Item
                name={field.name}
                label={field.name}
                required={required}
                initialValue={value}
                validateStatus={isValid ? undefined : 'error'}
                help={isValid ? '' : 'Invalid URL format'}
            >
                <Input onChange={handleChange} onBlur={handleBlur} placeholder="Enter URL" />
            </Form.Item>
        );
    }
};

export default URLFieldRender;
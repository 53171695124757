import '../../styles/tourStyles.css'

// tourSteps.tsx

export interface TourStep {
  id: string;
  selector: string;
  content: string;
  position: 'left' | 'right' | 'top' | 'bottom' | 'extrabottom' | 'bottomright';
  videoUrl?: string;
}


export const tourSteps: { [key: string]: TourStep[] } = {
  profileSelector: [
    {
      id: 'builder-step',
      selector: '.side-menu-builder',
      content: 'Click "Builder" to start customizing your experience.',
      position: 'right',
    },
    // Add more steps for Profile Selector if needed
  ],
  builderHome: [
    {
      id: 'edit-buttons-step',
      selector: '.edit-button',
      content: 'Edit one of the Reference Pages - Customers is a great start.',
      position: 'bottom',
      videoUrl: 'https://www.youtube.com/embed/DR_JLqesgrk?si=nuX_SlWdDPychOTE'
    },
    // Add more steps for Builder Home if needed
  ],
  referencePageBuilder: [
    {
      id: 'add-textfield-step',
      selector: '.textfield-button',
      content: 'Click here to add a Text Field to your canvas.',
      position: 'bottom',
      videoUrl: 'https://www.youtube.com/embed/OoEFT_-104A?si=G60XS96I0lB4VS-5'
    },
    {
      id: 'textfield-added-step',
      selector: '.new-textfield', // Ensure your canvas has this class
      content: 'Great! You have added a Text Field. Now click the TextField_#### ID to edit it. This works for all fields you see',
      position: 'bottom',
      videoUrl: 'https://www.youtube.com/embed/JWxfnFcr6eU?si=Oq7puF_6FT-aRAms'
    },
    {
      id: 'edit-field-name-step',
      selector: '.field-name-input',
      content: 'Edit the field name to something useful for you and click OK. Not sure what to name it? Try "My field"',
      position: 'bottomright',
      videoUrl: 'https://www.youtube.com/embed/vYvUug9w-Fk?si=9J5fXbUk660gXRw5'
    },
    {
      id: 'save-schema-step',
      selector: '.save-schema-button',
      content: 'Congrats! You are well on your way. Now, click save. Then click the page on the side menu to see your new field in action.',
      position: 'right',
      videoUrl: 'https://www.youtube.com/embed/xdR0H8wnbEw?si=6z5SwlhIsvP5yY-H'
    },
  ],
  basePageRender: [
    // Define steps for Base Page Render
  ],
  referencePageRender: [
    // Define steps for Reference Page Render
  ],
};
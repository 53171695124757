// OneToManyBuilder.tsx

import React, { useState, useEffect, useRef } from 'react';
import { message } from 'antd';
import SectionOrganizer from '../Organizers/SectionOrganizer'; // Ensure correct import path
import SubFieldPalette from './Z_SubFieldPalette'; // Ensure correct import path
// import { renderField } from '../../Render/FieldRender/_RenderField'; // Ensure correct import path
import { isEqual } from 'lodash';

// const { Text } = Typography;

interface OneToManyBuilderProps {
  fieldOptions: any
  sectionId: string;
  fieldId: string;
  onBuilderUpdate?: (fieldId: string, updatedFieldOptions: any) => void;
}

const OneToManyBuilder: React.FC<OneToManyBuilderProps> = ({
  fieldOptions,
  sectionId,
  fieldId,
  onBuilderUpdate,
}) => {
  // **1. Initialize Sections from fieldOptions or default**
  const defaultOtmSections: any[] = [
    {
      id: `subsection-${Date.now()}`,
      title: 'SubFields',
      type: 'section',
      fields: [],
    },
  ];

  const [otmSections, setOtmSections] = useState<any[]>(() => {
    return Array.isArray(fieldOptions.otmSections && fieldOptions.otmSections.length > 0)
      ? fieldOptions.otmSections
      : defaultOtmSections;
  });

  // **Ref to Prevent Infinite Loop**
  const isSyncingRef = useRef(false);

  // **2. Synchronize internal sections with fieldOptions.sections from parent**
  useEffect(() => {
    if (isSyncingRef.current) {
      isSyncingRef.current = false;
      return;
    }

    if (Array.isArray(fieldOptions.otmSections) && !isEqual(fieldOptions.otmSections, otmSections)) {
      isSyncingRef.current = true;
      setOtmSections(fieldOptions.otmSections);
    }
  }, [fieldOptions.otmSections]);

  // **3. Notify parent of changes**
  useEffect(() => {
    // console.log('notifying parent')
    if (isSyncingRef.current) {
      isSyncingRef.current = false;
      return;
    }

    if (onBuilderUpdate) {
        // console.log('on builder update')
      if (!isEqual(fieldOptions.otmSections, otmSections)) {
        onBuilderUpdate(fieldId, { ...fieldOptions, otmSections });

      }
    } else {
    }
  }, [otmSections, fieldId]);

  // **4. Handler to add a new subfield**
  const onAddSubField = (fieldType: string) => {
    const newField = {
      id: `subfield-${Date.now()}`,
      type: fieldType,
      name: `${fieldType}_${Date.now()}`,
      fieldOptions: {},     //This is the subfield field options, not the OTM fieldoptions.
    };
    setOtmSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        fields: [...section.fields, newField],
      }))
    );
    message.success(`${fieldType} added successfully.`);
  };

  // **5. Handler to update a field**
  const onFieldUpdateHandler = (sectionId: string, fieldId: string, updatedField: any) => {
    setOtmSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              fields: section.fields.map((field) =>
                field.id === fieldId ? { ...field, ...updatedField } : field
              ),
            }
          : section
      )
    );
};

  // **6. Handler to delete a field**
  const onFieldDeleteHandler = (sectionId: string, fieldId: string) => {
    setOtmSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              fields: section.fields.filter((field) => field.id !== fieldId),
            }
          : section
      )
    );
    message.success('SubField deleted successfully.');
    // console.log(`OneToManyBuilder - Deleted subfield: ${fieldId}`);
  };

  return (
    <>
      <div>
        <h3>Configure One-to-Many Field</h3>
        <SubFieldPalette
          onAddSubField={onAddSubField}
          availableFields={['TextField', 'EmailField', 'PhoneNumber', 'Dropdown', 'ReferenceLinkField', 'DateField']}
        />
        {/* Render SectionOrganizer for nested sections */}
        {otmSections.map((section) => (
          <SectionOrganizer
            key={section.id}
            section={section}
            onFieldUpdate={onFieldUpdateHandler}
            onFieldDelete={onFieldDeleteHandler}
            onRenameSection={() => {}} // Implement if needed
            pageType="one-to-many"
            // Additional props if necessary
          />
        ))}
      </div>
    </>
  );
};

export default OneToManyBuilder;
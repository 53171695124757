import React from 'react';
import '../../styles/tourStyles.css'

interface TooltipProps {
  content: string;
  position: 'left' | 'right' | 'top' | 'bottom' | 'extrabottom' | 'bottomright';
  targetRect: DOMRect | null;
  videoUrl?: string; // Optional video URL
  onSkip: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ content, position, targetRect, videoUrl, onSkip }) => {
  if (!targetRect) return null;
  const isVideoTooltip = !!videoUrl;

  const calculateStyle = (): React.CSSProperties => {
    const style: React.CSSProperties = {
      position: 'absolute',
      padding: '10px',
      backgroundColor: '#333',
      color: '#fff',
      borderRadius: '4px',
      zIndex: 1050,
      pointerEvents: 'auto',
      maxWidth: isVideoTooltip ? '600px' : '300px', // Expand if there's a video
      width: 'auto',
      height: 'auto',
      maxHeight: isVideoTooltip ? '500px' : 'auto',
      left: targetRect.right + 10 + 'px',
      top: targetRect.top + window.scrollY + 'px',
    };

    switch (position) {
      case 'right':
        style.left = targetRect.right + 10 + 'px';
        style.top = targetRect.top + window.scrollY + 'px';
        break;
      case 'left':
        style.left = targetRect.left - 310 + 'px'; // 300px width + 10px padding
        style.top = targetRect.top + window.scrollY + 'px';
        break;
      case 'top':
        style.left = targetRect.left + 'px';
        style.top = targetRect.top - 60 + window.scrollY + 'px'; // 50px height + 10px padding
        break;
      case 'bottom':
        style.left = targetRect.left + 'px';
        style.top = targetRect.bottom + window.scrollY + 'px';
        style.marginLeft = '1vw'
        break;
      case 'extrabottom':
        style.left = targetRect.left + 'px';
        style.top = targetRect.bottom + window.scrollY + 'px';
        style.marginTop = '10vh';
        break;
      case 'bottomright':
        style.left = targetRect.left + 'px';
        style.top = targetRect.bottom + window.scrollY + 'px';
        style.marginLeft = '30vw';
        style.marginTop = '15vh';
        break;
      default:
        style.left = targetRect.right + 10 + 'px';
        style.top = targetRect.top + window.scrollY + 'px';
    }

    return style;
  };

  return (
      <div style={calculateStyle()}>
        <p style={{color: '#ddd', width: isVideoTooltip ? 400 : 250}}>{content}</p>
        {/* Render video if provided */}
        {videoUrl && (
            <div style={{ marginTop: '10px' }}>
              <iframe
                  width="400"
                  height="300"
                  src={videoUrl}
                  title="Tutorial Video"
                  allowFullScreen
              ></iframe>
            </div>
        )}
        <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
          <button
              onClick={onSkip}
              style={{
                backgroundColor: '#f00',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
          >
            Skip Tutorial
          </button>
        </div>
      </div>
  );
};

export default Tooltip;
import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Button, Table, message, Steps } from 'antd';
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { renderField } from './FieldRender/_RenderField';
import dataAll from "../../utils/dataAll";
import {useTenant} from "../../contexts/TenantContext";
import {initializePage} from "../../utils/initializeRender";

interface TransactionPageRenderProps {
  moduleData: any;
  existingRecord?: any;
  records: any[];
  setRecords: (updated: any[]) => void;
  closeModal: () => void;
}

interface Stage {
  id: string;
  name: string;
  fields: { fieldId: string; isCarryover: boolean }[];
  fieldConfigs?: Record<string, string>;
}

const TransactionPageRender: React.FC<TransactionPageRenderProps> = ({
  moduleData,
  existingRecord,
  records,
  setRecords,
  closeModal
}) => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<Record<string, any[]>>({});
  const [referenceData, setReferenceData] = useState<Record<string, any[]>>({});
  const [currentStage, setCurrentStage] = useState<Stage | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedAggregates, setSelectedAggregates] = useState<Record<string, string>>({});
  const [clickedDropdown, setClickedDropdown] = useState<string | null>(null);
  const { tenantId, companyId } = useTenant();
  const rowCounters = useRef<Record<string, number>>({});


  // ----------------------------
  // 1. useEffect: Initialize
  // ----------------------------
  useEffect(() => {
    // console.log('form vals to start: ', form.getFieldsValue());
    if (!moduleData || isInitialized) return;
    if (!moduleData.stages || moduleData.stages.length === 0) {
      message.warning('You must configure stages before transaction pages are visible.');
      return;
    }

    initializePage.initializeTransactionForm(
        moduleData,
        existingRecord,
        form,
        setTableData,
        rowCounters
    );
    const stage = moduleData.stages.find((s) => s.name === existingRecord?.stageId) || moduleData.stages[0];
    setCurrentStage(stage);
    setIsInitialized(true);
  }, [moduleData, existingRecord, isInitialized, form]);

  // ----------------------------
  // 2. Collect Reference Data
  // ----------------------------

  useEffect(() => {
    if (!moduleData) return;
    initializePage.fetchReferenceData(moduleData, referenceData, setReferenceData, tenantId, companyId);
  }, [moduleData, referenceData]);

  // ----------------------------
  // 3. Create + Submit Record
  // ----------------------------
  async function fillOneAutoIncrement(
    fieldId: string,
    fieldName: string,
    moduleData: any,
    API_URL: string
  ): Promise<string> {
    try {
      return await dataAll.dataExtractUtility.getNextAutoIncrement(tenantId, companyId, moduleData._id, fieldId);
      // return nextIncrement;
    } catch (err) {
      console.error(`Error fetching auto-increment for field "${fieldName}"`, err);
      return ''; // fallback empty

    }
  }

  async function buildStandardData(formVals: any) {
    const standardData: Record<string, any> = {};

    // Loop over sections in a standard for...of, which can be async
    for (const section of moduleData.sections.filter((s: any) => s.type === 'section')) {
      for (const fld of section.fields) {
        const rawVal = formVals[fld.name];
        let finalVal = rawVal;

        if (fld.type === 'AutoIncrementId') {
          // If new record + empty => fetch
          const isNew = !existingRecord;
          const userVal = rawVal?.value ?? rawVal;
          if (isNew && !userVal) {
            finalVal = await fillOneAutoIncrement(fld.id, fld.name, moduleData, API_URL);
          } else {
            finalVal = userVal;
          }
        } else if (fld.type === 'DateField' && rawVal) {
          finalVal = dayjs(rawVal).isValid() ? dayjs(rawVal).toISOString() : null;
        } else if (rawVal?.value !== undefined) {
          finalVal = rawVal.value;
        }

        standardData[fld.id] = {
          label: fld.name,
          value: finalVal ?? '',
        };
      }
    }

    return standardData;
  }

  const handleSubmit = async () => {
    const formVals = form.getFieldsValue();
    const finalRecord = await initializePage.buildTransactionData(moduleData, formVals, tableData, existingRecord);
    await doSubmit(finalRecord);
    closeModal();
  };

  async function doSubmit(finalData: any) {
    const { tenantId, companyId, _id: moduleId } = moduleData;
    const payload = {
      _id: "", // add a default value
      data: finalData,
      moduleId,
      tenantId,
      companyId,
      stageId: currentStage?.name,
      updatedAt: new Date().toISOString(),
      createdAt: existingRecord?.createdAt || new Date().toISOString(),
    };

    if (existingRecord?._id) {
      payload._id = existingRecord._id; // update id if existing record
      await dataAll.dataPostUtility.overwriteData('transaction',existingRecord._id, payload);
      // await axios.put(`${API_URL}/transactiondata/${existingRecord._id}`, payload);
    } else {
      await dataAll.dataPostUtility.postData('transaction',payload);
      // await axios.post(`${API_URL}/transactiondata`, payload);
    }

    // Refresh
    const newTrxData = await dataAll.dataExtractUtility.getTransactionData(tenantId, companyId, moduleId)
    setRecords(newTrxData);
  }

  // ----------------------------
  // 4. Table Helpers
  // ----------------------------
  const addTableRow = (sectionId: string) => {
    const tblDef = moduleData.tableData?.find((table: any) => table.id === sectionId);
    if (!tblDef) return console.error('No table definition for', sectionId);

    if (!rowCounters.current[sectionId]) {
      rowCounters.current[sectionId] = 0;
    }
    const newId = `row_${sectionId}_${rowCounters.current[sectionId]++}`;
    const newRow: any = { id: newId };
    tblDef.columns.forEach((col: any) => {
      newRow[col.id] = col.type === 'Dropdown' ? '' : null;
    });
    setTableData((prev) => {
      const newSectionData = [...(prev[sectionId] || [])]; // Ensure new array
      newSectionData.push({ ...newRow }); // Deep copy new row
      return { ...prev, [sectionId]: newSectionData };
    });
  };

  const removeTableRow = (sectionId: string, rowId: string) => {
    setTableData((prev) => ({
      ...prev,
      [sectionId]: prev[sectionId].filter((r) => r.id !== rowId),
    }));
  };

  const findField = (fieldId: string) => {
    return moduleData.sections
      .flatMap((s: any) => s.fields)
      .find((f: any) => f.id === fieldId);
  };

  const handleFormFieldChange = (fieldId: string, val: any) => {
    const fld = findField(fieldId);
    if (!fld) return;
    if (fld.type === 'Dropdown') {
      form.setFieldsValue({ [fieldId]: { label: val, value: val } });
    } else {
      form.setFieldsValue({ [fieldId]: { label: fld.name, value: val } });
    }
  };

  // Called by column fields
  const handleTableCellChange = (
    sectionId: string,
    rowId: string,
    columnId: string,
    value: any
  ) => {
    setTableData((prev) => {
      const updatedSectionData = prev[sectionId]?.map((row) => {
        if (row.id === rowId) {
          return { ...row, [columnId]: value };
        }
        return row;
      });
      return { ...prev, [sectionId]: updatedSectionData };
    });
  };

  const calculateAggregate = (rows: any[], columnId: string, type: string) => {
    const values = rows.map((row) => row[columnId]).filter((val) => typeof val === 'number');
    switch (type) {
      case 'sum':
        return values.reduce((acc, curr) => acc + curr, 0);
      case 'average':
        return values.length ? values.reduce((acc, curr) => acc + curr, 0) / values.length : 0;
      case 'count':
      default:
        return values.length;
    }
  };

  const renderSummary = (tableColumns: any[], sectionId: string) => {
    const rows = tableData[sectionId] || [];

    return (
      <Table.Summary.Row>
        {tableColumns.map((col, index) => {
          if (typeof rows[0]?.[col.columnId] === 'number') {
            const selectedType = selectedAggregates[col.columnId] || 'count';
            const result = calculateAggregate(rows, col.columnId, selectedType);

            return (
              <Table.Summary.Cell key={col.columnId} index={index}>
                <div
                  className="aggregate-cell"
                  style={{
                    height: clickedDropdown === col.columnId ? '80px' : '15px', // Dynamically adjust height
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const dropdownId = `dropdown-${col.columnId}`;
                    if (clickedDropdown === col.columnId) {
                      setClickedDropdown(null); // Close the dropdown if already open
                    } else {
                      setClickedDropdown(col.columnId); // Open the dropdown
                    }
                  }}
                >
                  <span className="aggregate-result">
                    {`${selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}: ${result}`}
                  </span>
                  {clickedDropdown === col.columnId && (
                    <div className="aggregate-dropdown">
                      {['count', 'sum', 'average'].map((type) => (
                        <div
                          key={type}
                          className="aggregate-dropdown-item"
                          style={{
                            background: selectedType === type ? '#e6f7ff' : 'transparent',
                          }}
                          onClick={() => {
                            setSelectedAggregates((prev) => ({
                              ...prev,
                              [col.columnId]: type,
                            }));
                            setClickedDropdown(null);
                          }}
                        >
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Table.Summary.Cell>
            );
          }
          return <Table.Summary.Cell key={col.columnId} index={index} />;
        })}
        <Table.Summary.Cell index={tableColumns.length} />
      </Table.Summary.Row>
    );
  };

  // ----------------------------
  // 5. Move to Next Stage (Simplified)
  // ----------------------------
  //

  const buildMovedStageData = async (formValues, nextStage, moduleData) => {
    const newData = {};

    for (const section of moduleData.sections.filter((s) => s.type === 'section')) {
      for (const field of section.fields) {
        const rawValue = formValues[field.name];
        let finalValue = rawValue;

        if (field.type === 'DateField' && rawValue) {
          finalValue = dayjs(rawValue).isValid() ? dayjs(rawValue).toISOString() : null;
        } else if (rawValue?.value !== undefined) {
          finalValue = rawValue.value;
        }

        newData[field.id] = {
          label: field.name,
          value: finalValue ?? '',
        };
      }
    }

    return {
      ...newData,
      stageId: { label: 'Stage', value: nextStage.name },
    };
  };

  const buildTableData = (tableData, moduleData) => {
    return Object.keys(tableData).reduce((acc, sectionId) => {
      const rows = tableData[sectionId] || [];
      const tableDef = moduleData.tableData?.find((table) => table.id === sectionId);
      const colDefs = tableDef?.columns || [];

      const tableRows = rows.map((row) => {
        const rowObj = {};
        colDefs.forEach((col) => {
          let val = row[col.id];
          if (col.type === 'DateField' && val) {
            val = dayjs(val).isValid() ? dayjs(val).toISOString() : null;
          }
          rowObj[col.id] = {
            label: col.name,
            value: val ?? '',
          };
        });
        return rowObj;
      });

      acc[sectionId] = {
        label: moduleData.sections.find((s) => s.id === sectionId)?.title || sectionId,
        value: tableRows,
      };

      return acc;
    }, {});
  };

  const moveToNextStage = async () => {
    if (!currentStage) return;
    const currentStageIndex = moduleData.stages.findIndex((s) => s.id === currentStage.id);
    const nextStage = moduleData.stages[currentStageIndex + 1];

    if (!nextStage) {
      message.info('You are already at the final stage.');
      return;
    }

    try {
      const requiredFieldIds = Object.keys(currentStage.fieldConfigs || {})
          .filter(fieldId => currentStage.fieldConfigs[fieldId] === "Require");

      const requiredFieldsFromSections = requiredFieldIds
          .map(fieldId =>
              moduleData.sections?.flatMap(s => s.fields || []).find(f => f.id === fieldId)
          )
          .filter(Boolean);

      const requiredFieldsFromTables = requiredFieldIds
          .map(fieldId =>
              moduleData.tableData?.flatMap(table => table.columns || []).find(col => col.id === fieldId)
          )
          .filter(Boolean);

      const allRequiredFields = [...requiredFieldsFromSections, ...requiredFieldsFromTables];

      const emptyFields = allRequiredFields.filter(field => {
        // Try getting the value from the form first
        let value = form.getFieldValue(field.name);

        // If it's undefined, check if it's inside a table row
        if (value === undefined) {
          Object.keys(tableData).forEach(sectionId => {
            tableData[sectionId].forEach(row => {
              if (row[field.id] !== undefined && row[field.id] !== "") {
                value = row[field.id]; // Get value from table row
              }
            });
          });
        }

        if (value && typeof value === "object") {
          if (
              !(value instanceof Date) &&  // Not a Date object
              !("$d" in value && value.$y && value.$M >= 0 && value.$D) && // Ensure it isn't a date-like object
              !(value && value.uuid != "")
          ) {
            return !value.uuid; // Invalid if UUID is null or undefined
          }

          return false; // If it's a valid date-like object, it's not empty
        }

        return !value; // Handle other field types
      });

      if (emptyFields.length > 0) {
        const missingFieldNames = emptyFields.map(field => field.name).join(", ");
        message.error(`The following fields must be filled: ${missingFieldNames}`);
        return;
      } else {
        await form.validateFields(allRequiredFields.map(f => f.name));
      }

      const formValues = form.getFieldsValue();
      const processedFormData = await buildMovedStageData(formValues, nextStage, moduleData);
      const processedTableData = buildTableData(tableData, moduleData);
      const transactionId = existingRecord?.transactionId || uuidv4();

      // Updating existing record
      const updatedExistingRecord = {
        ...processedFormData,
        ...processedTableData,
        transactionId: {
          label: 'Transaction ID',
          value: transactionId,
        },
        movedToNextStage: true,
      };

      await dataAll.dataPostUtility.overwriteData('transaction', existingRecord._id, {
        _id: existingRecord._id,
        data: updatedExistingRecord,
        moduleId: moduleData._id,
        tenantId: moduleData.tenantId,
        companyId: moduleData.companyId,
        movedToNextStage: true,
        stageId: currentStage.name,
        createdAt: existingRecord.createdAt,
        updatedAt: new Date().toISOString(),
      });

      // Creating new record
      const newRecord = {
        data: { ...processedFormData, ...processedTableData },
        moduleId: moduleData._id,
        tenantId: moduleData.tenantId,
        companyId: moduleData.companyId,
        stageId: nextStage.name,
        movedToNextStage: false,
        createdAt: new Date().toISOString(),
      };

      await dataAll.dataPostUtility.postData('transaction', newRecord);

      setRecords([...records, newRecord]);
      setCurrentStage(nextStage);

      message.success('Moved to the next stage successfully.');
      closeModal();
    } catch (err) {
      console.error('Error moving to the next stage:', err);
      message.error('Failed to move to the next stage.');
    }

    // Refresh records
    try {
      const updatedRecords = await dataAll.dataExtractUtility.getTransactionData(moduleData.tenantId, moduleData.companyId, moduleData._id);
      setRecords(updatedRecords);
    } catch (err) {
      console.error('Error fetching updated records:', err);
    }
  };

  // ----------------------------
  // 6. Render
  // ----------------------------
  const stageIdx = moduleData?.stages?.findIndex((s: Stage) => s.id === currentStage?.id);

  return (
    <Modal
      title={`${existingRecord ? 'Edit' : 'New'} ${moduleData.moduleName}`}
      open={true}
      onOk={handleSubmit}
      onCancel={closeModal}
      width={1000}
    >
      {/* Stage + Next Stage */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <h2>Current Stage: {currentStage?.name}</h2>
        <Button
          onClick={moveToNextStage}
          type='primary'>
            Move to Next Stage
        </Button>
      </div>

      {/* Steps */}
      <Steps current={stageIdx}>
        {moduleData.stages.map((st: Stage) => (
          <Steps.Step key={st.id} title={st.name} />
        ))}
      </Steps>

      <Form form={form} layout="vertical" style={{ marginTop: 24 }}>
        {moduleData.sections.map((section: any) => {
          if (section.type === 'section') {
            // Renders standard fields
            const fieldsToRender = getVisibleFields(section, currentStage);
            const firstStage = moduleData.stages?.[0] || null;
            const currentStageNew = currentStage?.id
                ? moduleData.stages.find((s) => s.id === currentStage?.id) || firstStage
                : firstStage;

            const requiredFields = currentStageNew?.fieldConfigs
                ? Object.entries(currentStageNew.fieldConfigs)
                    .filter(([_, config]) => config === 'Require')
                    .map(([fieldId]) => fieldId)
                : [];

            return (
              <div key={section.id} style={{ marginBottom: 24 }}>
                <h3>{section.title}</h3>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
                  {fieldsToRender.map((fld: any) => (
                    <React.Fragment key={fld.id}>
                      {renderField(
                        fld,
                        section.id,
                        form,
                        referenceData,
                        (val: any) => handleFormFieldChange(fld.id, val),
                        moduleData,
                        tableData,
                        null, //rowData
                        null, //columns
                        null, //isEditing
                        null, //tenantId
                        null, //companyId
                        null, //moduleId
                        null, //itemId
                        requiredFields.includes(fld.id), // required
                        // 'section',
                        // null, //existingrecord,
                        // null, //new schema,
                        existingRecord ? form.getFieldValue(fld.name) : '', //existingRecord[fld.id], //value
                        null //rowIndex
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            );
          } else if (section.type === 'table') {
            // Renders table
            const tblDef = moduleData.tableData?.find((table: any) => table.id === section.id);
            if (!tblDef) return null;
            const columnsToRender = getVisibleColumns(tblDef.columns, currentStage);
            const columnsForTable = columnsToRender.map((col: any) => ({
              title: col.name,
              columnId: col.id,
              key: col.id,
              render: (text: any, row: any, rowIndex: number) =>
                renderField(
                  { ...col, renderType: 'table' },
                  section.id,
                  form,
                  referenceData,
                  (val: any) => handleTableCellChange(section.id, row.id, col.id, val),
                  moduleData,
                  tableData,
                  row,
                  columnsToRender, //columns
                  true, //isEditing
                  undefined, //tenantId
                  undefined, //companyId
                  moduleData._id, //moduleId
                  row.id, //itemId
                  currentStage?.fieldConfigs?.[col.id] === 'Require', //required
                  'table', //render type
                  existingRecord,
                  true, //new schema
                  row[col.id], //fieldValue
                  rowIndex
               ),
            }));

            return (
              <div key={section.id} style={{ marginBottom: 24 }}>
                <h3>{section.title}</h3>
                <Button
                  type="primary"
                  onClick={() => addTableRow(section.id)}
                  style={{ marginBottom: 16 }}
                >
                  Add Row
                </Button>
                <Table
                  columns={[
                    ...columnsForTable,
                    {
                      title: 'Actions',
                      key: 'actions',
                      fixed: 'right',
                      render: (_: any, row: any) => (
                        <Button
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() => removeTableRow(section.id, row.id)}
                        />
                      ),
                    },
                  ]}
                  dataSource={tableData[section.id] || []}
                  pagination={false}
                  rowKey="id"
                  scroll={{ x: 'max-content' }}
                  summary={() => renderSummary(columnsForTable, section.id)} // Add the summary row
                />
              </div>
            );
          }
          return null;
        })}
      </Form>
    </Modal>
  );
};

export default TransactionPageRender;

// Helpers
function getVisibleFields(section: any, currentStage: Stage | null) {
  if (!currentStage?.fieldConfigs) return section.fields;
  return section.fields.filter((fld: any) => {
    const config = currentStage.fieldConfigs[fld.id];
    return config === 'Edit' || config === 'View' || config === 'Require';
  });
}

function getVisibleColumns(allCols: any[], currentStage: Stage | null) {
  if (!currentStage?.fieldConfigs) return allCols;
  return allCols.filter((col: any) => {
    const config = currentStage.fieldConfigs[col.id];
    return config === 'Edit' || config === 'View' || config === 'Require';
  });
}

// BasePageRender.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {Button, Dropdown, MenuProps, Table, Modal, message, Input, Radio, Select, Checkbox} from 'antd';
import {
  DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined,
  ExportOutlined, FilterOutlined, UploadOutlined,
} from '@ant-design/icons';
import ReferencePageRender from './ReferencePageRender';
import TransactionPageRender from './TransactionPageRender';
import ImportDataModal from './ImportDataModal';
import * as XLSX from "xlsx";
import {initializePage} from "../../utils/initializeRender";
import {applyFilters} from "../../utils/applyFilters";
import {renderFieldValue} from "../../utils/renderFieldValues";
import { handleDownloadTemplate, exportTemplate } from "../../utils/downloadTemplateUtils";

const BasePageRender: React.FC = () => {
  const { moduleId } = useParams();
  const [moduleData, setModuleData] = useState<any>(null);
  const [records, setRecords] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Added loading state
  const [isDetailView, setIsDetailView] = useState(false);
  const [stageFilter, setStageFilter] = useState<string | null>(null);
  const [columnFilters, setColumnFilters] = useState<Record<string, any>>({});
  const [isScrollEnabled, setIsScrollEnabled] = useState(true); // State to control scrolling
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [commonIdentifier, setCommonIdentifier] = useState(null);
  const [isAdvancedExportVisible, setIsAdvancedExportVisible] = useState(false);
  const [exportOptions, setExportOptions] = useState({
    exportOneToManyDetails: false,
  });

  useEffect(() => {
    initializePage.initializePage(moduleId, setModuleData, setRecords);
  }, [moduleId]);

  useEffect(() => {
    if (moduleData?.pageType !== 'Transaction') {
      setStageFilter(null);
      setIsDetailView(false);
    }
  }, [moduleData?.pageType]);

  const toggleView = () => {
    if (moduleData.pageType === 'Transaction') {
      setIsDetailView(prev => !prev);
    } else {
      setIsDetailView(false);
    }
  };

  const filterRecordsByStage = (stageId: string | null) => {
    setStageFilter(stageId);
  };

  const resetAllFilters = () => {
    setColumnFilters({}); // Resets all column filters
    setStageFilter(null); // Resets the stage filter if used
    // Add any other state resets related to filtering here
  };

  const extractValue = (record, dataIndex, pageType) => {
    let fieldType = ''
    moduleData.sections.forEach(section => {
      if (section.type === 'table') {
        return;
      }
      section.fields.forEach(field => {
        if (field.id === dataIndex) {
          fieldType = field.type;
        }
      })
    })

    if (record.hasOwnProperty('lineItemId')) {
      // For flattened line items, data is directly on the record
      let value = record[dataIndex] || '';
      if (fieldType === 'OneToManyField') {
        return value?.length ? value.length : 0
      }
      if (value && typeof value === 'object') {
        return value?.value || value?.label || value?.value || '';
      }
      return value || '';
    } else {
      let value = record?.data[dataIndex]?.value || '';
      let extractedValue
      if (fieldType === 'OneToManyField') {
        extractedValue = String(value?.value?.length || value?.length || 0);
      }
      else if (value && typeof value === 'object') {
        extractedValue = value?.value || value?.label || '';
      }
      else {
        extractedValue = value || ''
      }
      return extractedValue
    }
  };

  const getColumnUniqueValues = (dataIndex: string, data: any[], pageType: string) => {
    if (!Array.isArray(data)) {
      console.warn(`getColumnUniqueValues: Expected an array but received`, data);
      return [];  // Return an empty array if data is undefined or not an array
    }

    const values = data.map(record => extractValue(record, dataIndex, pageType));
    return Array.from(new Set(values.filter(value => value !== undefined && value !== null)));
  };

  const getColumnFilterProps = (dataIndex, fieldType, fieldName) => {
    const uniqueValues = moduleData?.pageType && Array.isArray(records)
        ? getColumnUniqueValues(dataIndex, records, moduleData.pageType)
        : [];

    const onFilter = (value, record) => {
      if (!record?.data) return false;

      const checkMatch = (fieldValue, filterValue) => {
        if (typeof fieldValue === 'object' && fieldValue !== null) {
          if ('label' in fieldValue) {
            return fieldValue.label === filterValue;
          } else if ('value' in fieldValue) {
            return typeof fieldValue.value === 'string'
                ? fieldValue.value === filterValue
                : fieldValue.value.label === filterValue;
          }
        }
        return fieldValue === filterValue;
      };

      const recordValue = record.data[dataIndex];
      if (recordValue && checkMatch(recordValue, value)) {
        return true;
      }

      if (moduleData?.pageType === 'Transaction' && record.data.tableData) {
        for (const tableKey in record.data.tableData) {
          const tableRecords = record.data.tableData[tableKey];
          for (const lineItem of tableRecords) {
            for (const key in lineItem) {
              const field = lineItem[key];
              if (field && field.label === dataIndex && checkMatch(field, value)) {
                return true;
              }
            }
          }
        }
      }
      return false;
    };

    return {
      filterDropdown: ({ confirm }) => {
        const searchText = columnFilters[dataIndex]?.searchText || '';
        const filteredValues = uniqueValues.filter(value => {
          if (fieldType === 'OneToManyField') {
            // Convert array to length for filtering
            const valueLength = Array.isArray(value) ? value.length : 0;
            return String(valueLength).toLowerCase().includes(searchText.toLowerCase());
          }
          else {
            const valueStr = String(value).toLowerCase();
            return valueStr.includes(searchText.toLowerCase());
          }
        });
        // console.log(filteredValues);

        return (
            <div style={{ padding: 8 }}>
              <Input
                  placeholder={`Search ${fieldName}`}
                  value={searchText}
                  onChange={e => {
                    // const currValue = e.target.value;
                    setColumnFilters(prev => ({
                      ...prev,
                      [dataIndex]: {
                        ...prev[dataIndex],
                        searchText: e.target.value,
                      },
                    }));
                  }}
                  style={{ marginBottom: 8, display: 'block' }}
              />
              <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                <Radio.Group
                    onChange={e => {
                      setColumnFilters(prev => ({
                        ...prev,
                        [dataIndex]: {
                          ...prev[dataIndex],
                          selectedValue: e.target.value,
                        },
                      }));
                    }}
                    value={columnFilters[dataIndex]?.selectedValue}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                  {filteredValues.map((value, index) => (
                      <Radio key={index} value={value}>
                        {value}
                      </Radio>
                  ))}
                </Radio.Group>
              </div>
              <Button
                  type="primary"
                  onClick={() => confirm()}
                  size="small"
                  style={{ width: 90, marginTop: 8, marginRight: 8 }}
              >
                Apply
              </Button>
              <Button
                  onClick={() => {
                    setColumnFilters(prev => ({
                      ...prev,
                      [dataIndex]: {
                        searchText: '',
                        selectedValue: null,
                      },
                    }));
                    confirm();
                  }}
                  size="small"
                  style={{ width: 90, marginTop: 8 }}
              >
                Reset
              </Button>
            </div>
        )
      },
      filterIcon: () => {
        const isActive =
            columnFilters[dataIndex]?.searchText ||
            columnFilters[dataIndex]?.selectedValue;
        return (
            <span
                style={{
                  color: isActive ? '#1890ff' : undefined,
                  fontSize: isActive ? '18px' : '14px',
                  fill: isActive ? '#1890ff' : undefined,
                  borderRadius: '40%',
                  padding: isActive ? '3px' : '0',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
            >
                    <FilterOutlined />
                </span>
        );
      },
      onFilter: (value, record) => onFilter(String(value), record),
    };
  };

  // Flatten parent data function
  const flattenParentData = (record: any) => {
    const flattenedData: any = {};
    // Determine the schema version
    const isNewSchema = true //isUsingNewSchema(record);

    if (record.data && moduleData.pageType === 'Reference') {
      for (const [key, field] of Object.entries(record.data)) {
        if (key !== 'tableData') {
          if (isNewSchema) {
            if (field && typeof field === 'object'
                  && 'label' in field
                  && 'value' in field
                  && typeof field.label === 'string')
            {
              flattenedData[key] = field.value; // key is field.id
            }
          } else {
            flattenedData[key] = field;
          }
        }
      }
      // Add `_id` and `originalRecord` for both schemas
      flattenedData._id = record._id;
      flattenedData.originalRecord = record;
      return flattenedData;
    }

    if (record.data && moduleData.pageType === 'Transaction' && Array.isArray(record.data)) {
      record.data.forEach((field: any) => {
        const fieldId = field.Key;

        if (fieldId === "transactionId" || fieldId === "stageId" || fieldId.startsWith("table")) {
          flattenedData[fieldId] = field.Value;
          return
        }

        // Find the corresponding field type from moduleData
        const fieldType = moduleData.sections
            .flatMap((section: any) => section.fields)
            .find((f: any) => f.id === fieldId)?.type || null;

        if (!fieldType) {
          return;
        }

        // Extract the value object
        const valueObj = field.Value.find((val: any) => val.Key === 'value');

        // Handling different field types
        let fieldValue;
        switch (fieldType) {
          case 'ReferenceLinkField':
            if (Array.isArray(valueObj?.Value)) {
              const labelEntry = valueObj.Value.find((entry: any) => entry.Key === 'label');
              fieldValue = labelEntry?.Value || '';
            } else {
              fieldValue = '';
            }
            break;

          case 'PhoneNumber':
            if (Array.isArray(valueObj?.Value)) {
              const flattenedValues = valueObj.Value.flat(); // Flatten the nested arrays
              const phoneEntry = flattenedValues.find(
                  (entry: any) => entry.Key === 'Key' && entry.Value === 'phone'
              );
              fieldValue = phoneEntry ? flattenedValues.find((entry: any) => entry.Key === 'Value')?.Value || '' : '';
            } else {
              fieldValue = '';
            }
            break;

          default:
            fieldValue = valueObj?.Value || ''; // Default processing
            break;
        }

        flattenedData[fieldId] = fieldValue; // Add the processed value to the flattened object
      });

      // Add stage name to the flattened data
      if (record.stageId) {
        flattenedData.stageId = record.stageId;
      }
    }

    // Add `_id` and `originalRecord` for both schemas
    flattenedData._id = record._id;
    return flattenedData;
  };

  const displayRecords: any[] = applyFilters(
      records,
      stageFilter,
      columnFilters,
      extractValue,
      moduleData,
      isDetailView,
      (record, flattenedData) => initializePage.flattenLineItems(record, flattenedData, moduleData, renderFieldValue),
      flattenParentData
  );

  const getColumns = (isDetail: boolean) => {
    if (!moduleData) return [];

    const parentFields = moduleData.sections
        .filter((section: any) => section.type === 'section')
        .flatMap((section: any) => section.fields);

    // Define the stage column
    const stageColumn = {
      title: 'Stage',
      dataIndex: 'stageId',
      key: 'stageId',
      responsive: ['lg'],
      render: (stageId: any, record: any) => {
        return record.stageId ? stageId : 'N/A';
      },
    };

    // Generate parent columns using centralized field renderer
    const parentColumns = parentFields.map((field: any) => {
      let column: any = {
        title: field.name,
        dataIndex: field.id,
        key: field.id,
        responsive: field.responsive || ['lg'],
        render: (text: any, record: any) => {
          let fieldData;

          // Check for array-based schema (transaction pages)
          if (Array.isArray(record?.data)) {
            fieldData = record.data.find((item: any) => item.Key === field.id);
            if (fieldData) {
              const fieldValue = fieldData.Value.find((val: any) => val.Key === 'value')?.Value;
              return renderFieldValue(field.type, fieldValue ?? text);
            }
          }
          // Check for object-based schema (reference pages)
          if (record?.data && record.data[field.id]) {
            fieldData = record.data[field.id];
            return renderFieldValue(field.type, fieldData.value ?? text);
          }
          return text ?? '';
        },
        sorter: (a: any, b: any) => {
          const valueA = extractValue(a, field.id, moduleData.pageType);
          const valueB = extractValue(b, field.id, moduleData.pageType);
          if (field.type === 'NumberField' || field.type === 'CalculatedField') {
            return (parseFloat(valueA) || 0) - (parseFloat(valueB) || 0);
          } else {
            return String(valueA).localeCompare(String(valueB));
          }
        },
      };

      if (
          ['AutoIncrementId', 'Dropdown', 'ReferenceLinkField', 'OneToManyField'].includes(field.type)
      ) {
        column = {
          ...column,
          ...getColumnFilterProps(field.id, field.type, field.name),
        };
      }

      return column;
    });

    let columns;

    if (isDetail) {
      const tableSections = moduleData.sections.filter(
          (section: any) => section.type === 'table'
      );

      const tableColumns = tableSections.flatMap((tableSection: any) => {
        const tableDefinition = moduleData.tableData?.find((table: any) => table.id === tableSection.id);
        if (!tableDefinition || !Array.isArray(tableDefinition.columns)) {
          console.warn(`Table definition or columns not found for section: ${tableSection.id}`);
          return []; // Return an empty array to avoid undefined entries
        }
        return tableDefinition.columns.map((col: any) => {
          let column: any = {
            title: col.name,
            dataIndex: col.id,
            key: col.id,
            responsive: col.responsive || ['lg'],
            render: (text: any) => renderFieldValue(col.type, text),
            sorter: (a: any, b: any) => {
              const valueA = extractValue(a, col.id, moduleData.pageType);
              const valueB = extractValue(b, col.id, moduleData.pageType);
              if (col.type === 'NumberField' || col.type === 'CalculatedField') {
                return (parseFloat(valueA) || 0) - (parseFloat(valueB) || 0);
              } else {
                return String(valueA).localeCompare(String(valueB));
              }
            },
          };
          return column;
        });
      });

      columns = moduleData.pageType === 'Transaction'
          ? [stageColumn, ...parentColumns, ...tableColumns, getActionColumn()]
          : [...parentColumns, ...tableColumns, getActionColumn()];
    } else {
      columns = moduleData.pageType === 'Transaction'
          ? [stageColumn, ...parentColumns, getActionColumn()]
          : [...parentColumns, getActionColumn()];
    }

    // If "Fit" mode is active (isScrollEnabled is false), adjust the column widths
    if (!isScrollEnabled) {
      const totalWidth = window.innerWidth - 50; // Account for some padding
      const columnWidth = Math.floor(totalWidth / columns.length);

      // Set the width for each column
      columns = columns.map(col => ({
        ...col,
        width: columnWidth,
      }));
    }

    return columns;
  };

  const getActionColumn = () => ({
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    responsive: ['lg'],
    render: (_: any, record: any) => (
      <>
        <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(record)}
          danger
          style={{ marginLeft: 8 }}
        />
      </>
    ),
  });

  const handleDelete = (record: any) => {
    initializePage.deleteTransactionData(record._id, moduleData.pageType, setRecords);
  };

  const handleEdit = (record: any) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  if (!moduleData) return <p>Loading moduleData...</p>;
  if (error) {
    const err = error;
    setError(null) // clear the error so it doesn't propagate to the next tab
    return <p>{err}</p>
  }

  // CSV Export Functions
  // function convertToCSV(objArray, columns) {
  //   let str = columns.map(column => `"${column.title}"`).join(',') + '\r\n';
  //
  //   objArray.forEach(obj => {
  //     let line = columns.map(column => {
  //       let value = obj[column.title];
  //       if (Array.isArray(value)) {
  //         return value.length
  //       }
  //       else if (value && typeof value === 'object') {
  //         return `"${value.value ?? value.label ?? ''}"`;
  //       }
  //       return `"${value !== undefined ? value : ''}"`;
  //     }).join(',');
  //     str += line + '\r\n';
  //   });
  //
  //   return str;
  // }

  // function downloadCSV(records: any[], columns: any[], filename = 'output.csv') {
  //   const processedRecords = records.map((record) => {
  //     const flattenedData = {};
  //     // Extracting each field from the data object
  //     for (const key in record.data) {
  //       if (record.data.hasOwnProperty(key)) {
  //         flattenedData[record.data[key].label] = record.data[key].value ?? '';
  //       }
  //     }
  //     return flattenedData;
  //   });
  //   const csvData = convertToCSV(processedRecords, columns);
  //   const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement('a');
  //   const url = URL.createObjectURL(blob);
  //   link.setAttribute('href', url);
  //   link.setAttribute('download', filename);
  //   link.style.visibility = 'hidden';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }
  // const exportMenu: MenuProps = {
  //   items: [
  //     {
  //       key: "standard",
  //       label: "Standard Export",
  //       onClick: () =>
  //         downloadCSV(displayRecords, getColumns(isDetailView), 'exported_data.csv'),
  //     },
  //     {
  //       key: "advanced",
  //       label: "Advanced Export",
  //       onClick: () => setIsAdvancedExportVisible(true),
  //     },
  //   ],
  // };


  //THIS (renderDownloadModal) should now be obsolete. It's never called in the handleDownloadModal function.
  // const renderDownloadModal = () => {
  //   const textFields = moduleData.sections
  //       .flatMap((section) => section.fields)
  //       .filter((field) => field.type === "TextField");
  //
  //   return (
  //       <Modal
  //           title="Select Common Identifier"
  //           open={isDownloadModalVisible}
  //           onCancel={() => setIsDownloadModalVisible(false)}
  //           onOk={() => {
  //             if (!commonIdentifier) {
  //               message.error("Please select a common identifier.");
  //               return;
  //             }
  //             exportTemplate(moduleData, null, commonIdentifier); // Call export function after selecting identifier and before moving modal
  //             setIsDownloadModalVisible(false);
  //
  //           }}
  //           okText="Download"
  //       >
  //         <p>Select a common identifier to link related data:</p>
  //         <Select
  //             placeholder="Select Parent Identifier"
  //             style={{ width: "100%" }}
  //             onChange={(value) => setCommonIdentifier(value)}
  //         >
  //           {textFields.map((field) => (
  //               <Select.Option key={field.id} value={field.name}>
  //                 {field.name}
  //               </Select.Option>
  //           ))}
  //         </Select>
  //       </Modal>
  //   );
  // };


  const showImportModal = () => {
    setIsImportModalVisible(true);
  };

  const handleImportCancel = () => {
    setIsImportModalVisible(false);
  };

  const handleImportSuccess = (importResult) => {
    message.success(`Successfully imported ${importResult.insertedCount} records.`);
    setIsImportModalVisible(false);
    // Re-initialize page
    initializePage.initializePage(moduleId, setModuleData, setRecords);
  };

  const handleNewButton = () => {
    if (moduleData.pageType === 'Transaction' && (!moduleData.stages || moduleData.stages.length === 0)) {
      message.warning('You must configure stages before transaction pages are visible.');
      return;
    }
    setSelectedRecord(null);
    setIsModalVisible(true);
  }

  const exportData = (isAdvancedExport: boolean) => {
    message.success(`${isAdvancedExport ? "Advanced" : "Standard"} Export started!`);

    const workbook = XLSX.utils.book_new();
    const processedRecords = displayRecords.map((record) => {
      const flattenedData: Record<string, any> = {};
      for (const key in record.data) {
        if (record.data.hasOwnProperty(key)) {
          flattenedData[record.data[key].label] = record.data[key].value ?? '';
        }
      }
      return flattenedData;
    });

    const mainHeaders = Object.keys(processedRecords[0] || {});
    const mainData = processedRecords.map((record) =>
        mainHeaders.map((header) => record[header] || "")
    );

    const mainSheet = XLSX.utils.aoa_to_sheet([mainHeaders, ...mainData]);
    XLSX.utils.book_append_sheet(workbook, mainSheet, "Main Data");

    if (isAdvancedExport && exportOptions.exportOneToManyDetails) {
      processOneToManyDetails(workbook, moduleData, processedRecords);
    }

    const now = new Date();
    const formattedDate = now.toISOString().replace(/T/, '_').replace(/:/g, '-').split('.')[0];
    const fileName = `${moduleData.moduleName}_${formattedDate}.xlsx`;
    XLSX.writeFile(workbook, fileName);
    if (isAdvancedExport) {
      setIsAdvancedExportVisible(false);
    }
  };

  const handleAdvancedExport = () => {
    message.success("Advanced Export started!");

    const workbook = XLSX.utils.book_new();
    const processedRecords = displayRecords.map((record, index) => {
      const flattenedData: Record<string, any> = {}

      for (const key in record.data) {
        if (record.data.hasOwnProperty(key)) {
          flattenedData[record.data[key].label] = record.data[key].value ?? '';
        }
      }
      return flattenedData;
    });

    const mainHeaders = Object.keys(processedRecords[0] || {});
    const mainData = processedRecords.map((record) =>
        mainHeaders.map((header) => record[header] || "")
    );

    const mainSheet = XLSX.utils.aoa_to_sheet([mainHeaders, ...mainData]);
    XLSX.utils.book_append_sheet(workbook, mainSheet, "exported_data");

    // Process One-To-Many fields
    if (exportOptions.exportOneToManyDetails) {
      addCommonIdToMainSheet(workbook);
      processOneToManyDetails(workbook, moduleData, displayRecords);
    }

    // Generate file name with module name and timestamp
    const now = new Date();
    const formattedDate = now.toISOString().replace(/T/, '_').replace(/:/g, '-').split('.')[0];
    const fileName = `${moduleData.moduleName}_${formattedDate}.xlsx`;

    XLSX.writeFile(workbook, fileName);
    setIsAdvancedExportVisible(false);
  };

  const addCommonIdToMainSheet = (workbook) => {
    const parentSheet = workbook.Sheets["exported_data"];
    if (!parentSheet) return;

    let parentRecords = XLSX.utils.sheet_to_json(parentSheet, { header: 1 }) as any[][];
    let parentHeaders: string[] = parentRecords[0] as string[] || [];

    if (!parentHeaders.includes("Common ID")) {
      parentHeaders.unshift("Common ID");
    }

    // Preserve existing records while adding "Common ID"
    const updatedParentRecords = parentRecords.slice(1).map((row: any[], index: number) => {
      return [index + 1, ...row];
    });

    const updatedSheet = XLSX.utils.aoa_to_sheet([parentHeaders, ...updatedParentRecords]);
    workbook.Sheets["exported_data"] = updatedSheet;
  };

  const processOneToManyDetails = (workbook, moduleData, records) => {
    if (!moduleData || !records) return;

    const oneToManyFields = moduleData.sections
        .flatMap((section) => section.fields)
        .filter((field) => field.type === "OneToManyField");

    if (oneToManyFields.length === 0) return;

    oneToManyFields.forEach((otmField) => {
      const sheetName = otmField.name;
      const subFields = otmField.fieldOptions?.otmSections?.[0]?.fields || [];
      const headers = ["Common ID", ...subFields.map((sub) => sub.name)];
      const dataRows = [];

    records.forEach((record, index) => {
      const commonId = index + 1;
      if (!record.data || !record.data[otmField.id]) return;
      const otmData = record.data[otmField.id]?.value || [];
      if (!Array.isArray(otmData)) return;

      otmData.forEach((subRecord) => {
        const row = [commonId];
        subFields.forEach((sub) => {
          let val = subRecord?.[sub.id]?.value || subRecord?.[sub.id] ;
          if (sub.type === "ReferenceLinkField") {
            // If it's an object, output the label; if array, join them
            if (Array.isArray(val)) {
              val = val.map((v) => v?.label ?? v?.value ?? "").join(", ");
            } else if (val && typeof val === "object") {
              val = val?.label ?? val?.value ?? "";
            }
          } else if (typeof val === "object" && val !== null) {
            // For other object-based fields, try label or value
            val = val?.label ?? val?.value ?? "";
          }
          row.push(val ?? "");
        });
        dataRows.push(row);
      });

      // Add Common ID to the parent record
      record["Common ID"] = commonId;
    });

      // Create new sheet for OneToMany data
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...dataRows]);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });
  };

  const AdvancedExportModal = ({
                                 isVisible,
                                 onClose,
                                 onExport,
                                 exportOptions,
                                 setExportOptions
                               }) => {
    const handleCheckboxChange = (e) => {
      setExportOptions(prev => ({ ...prev, exportOneToManyDetails: e.target.checked }));
    };

    return (
        <Modal
            title="Advanced Export Options"
            open={isAdvancedExportVisible}
            onCancel={onClose}
            footer={[
              <Button key="cancel" onClick={onClose}>
                Cancel
              </Button>,
              <Button key="export" type="primary" onClick={onExport}>
                Export
              </Button>,
            ]}
        >
          <Checkbox checked={exportOptions.exportOneToManyDetails} onChange={handleCheckboxChange}>
            Export One to Many Details
          </Checkbox>
        </Modal>
    );
  };

  return (
    <div>
      <h1>{moduleData?.moduleName || 'Loading...'}</h1>
      <div style={{ marginBottom: 16 }}>
        <Button onClick={() => setIsScrollEnabled(!isScrollEnabled)}>
          {isScrollEnabled ? '  Fit  ' : 'Expand'}
        </Button>
        <Button onClick={toggleView}>
          View: {isDetailView ? 'Detail' : 'Header'}
        </Button>
        <Button
          type="primary"
          onClick={handleNewButton}
          style={{ marginLeft: 8 }}
        >
          + New
        </Button>
        <Button.Group style={{ marginLeft: 8 }}>
          <Button
              type="primary"
              icon={<ExportOutlined />}
              onClick={() => exportData(false)}
          >
            Export
          </Button>

          <Dropdown menu={{
            items: [
              { key: "standard", label: "Standard Export", onClick: () => exportData(false) },
              ...(moduleData?.pageType === "Reference"
                ? [{ key: "advanced", label: "Advanced Export", onClick: () => setIsAdvancedExportVisible(true) }]
                : [])
            ]
          }} trigger={['click']}>
            <Button type="primary" icon={<DownOutlined />} />
          </Dropdown>

        </Button.Group>

        <Button
          type="primary"
          onClick={resetAllFilters} // Using the reset function
          style={{ marginLeft: 8 }}
        >
          Reset All Filters
        </Button>
        {/*{moduleData.pageType=== 'Reference' && (*/}
          <>
            <Button
                onClick={() => handleDownloadTemplate(
                    moduleData,
                    // setIsDownloadModalVisible,
                    (data) => {
                      const workbook = XLSX.utils.book_new();
                      exportTemplate(data, workbook, commonIdentifier);
                    }
                )}
                style={{ marginLeft: 8 }}
                icon={<DownloadOutlined />}
            >
              Download Template
            </Button>
          <Button
            onClick={showImportModal}
            style={{ marginLeft: 8 }}
            icon={<UploadOutlined />}
          >
            Import
          </Button>
          </>
        {/*)}*/}
      </div>

      {moduleData.pageType === 'Transaction' && (
        <div style={{ marginBottom: 16 }}>
          <Button
            onClick={() => filterRecordsByStage(null)}
            type={!stageFilter ? 'primary' : 'default'}
          >
            All Stages
          </Button>
          {moduleData.stages?.map((stage: any) => (
            <Button
              key={stage.id}
              onClick={() => filterRecordsByStage(stage.name)}
              type={stageFilter === stage.name ? 'primary' : 'default'}
            >
              {stage.name}
            </Button>
          ))}
        </div>
      )}
      <Table
        columns={getColumns(isDetailView)}
        dataSource={displayRecords}
        rowKey={isDetailView ? 'lineItemId' : '_id'}
        scroll={isScrollEnabled ? { x: 'max-content' } : {}}
        loading={loading} // Ant Design's loading prop
        pagination={{
          // pageSize: 10, // Default number of records per page
          showSizeChanger: true, // Always show the page size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for number of records per page
          showTotal: (total) => `${total} records`,
        }}
      />

      {isModalVisible && (
        <>
          {moduleData.pageType === 'Reference' && (
            <ReferencePageRender
              moduleData={moduleData}
              existingRecord={selectedRecord}
              setRecords={setRecords}
              closeModal={() => setIsModalVisible(false)}
            />
          )}
          {moduleData.pageType === 'Transaction' && (
            <TransactionPageRender
              moduleData={moduleData}
              existingRecord={selectedRecord}
              records={records}
              setRecords={setRecords}
              closeModal={() => setIsModalVisible(false)}
            />
          )}
        </>
    )}
    {isImportModalVisible && (
      <Modal
        title="Import Data"
        open={isImportModalVisible}
        onCancel={handleImportCancel}
        footer={null}
      >
        <ImportDataModal
          moduleId={moduleId}
          tenantId={moduleData.tenantId}
          companyId={moduleData.companyId}
          // moduleData={moduleData} // Pass moduleData to ImportDataModal
          onClose={handleImportCancel}
          onImportSuccess={handleImportSuccess}
        />
      </Modal>
    )}
    <AdvancedExportModal
          isVisible={isAdvancedExportVisible}
          onClose={() => setIsAdvancedExportVisible(false)}
          onExport={handleAdvancedExport}
          exportOptions={exportOptions}
          setExportOptions={setExportOptions}
    />
    </div>
  );
};

export default BasePageRender;

import * as XLSX from "xlsx";

interface Field {
  id: string;
  name: string;
  type: string;
  fieldOptions?: {
    otmSections?: any;
    sections?: { fields: Field[] }[];
  };
}

interface Section {
  id: string;
  title: string;
  type: string;
  fields: Field[];
}

interface TableData {
  id: string;
  columns: { name: string; type: string }[]; // Added type property
}

interface ModuleData {
  moduleName: string;
  pageType: string;
  sections: Section[];
  tableData: TableData[];
}

// Exclude these field types from both main form and table data
const EXCLUDED_TYPES = [
  "OneToManyField",
  "AggregateField",
  "CalculationField",
  "AutoIncrementId",
  "LineNumber"
];

export const handleDownloadTemplate = (
    moduleData: ModuleData,
    // setIsDownloadModalVisible: (visible: boolean) => void,
    exportTemplateCallback: (moduleData: ModuleData) => void
): void => {
    if (!moduleData) return;
    exportTemplateCallback(moduleData)
};

export const extractHeaders = (moduleData: ModuleData): string[] => {
  return moduleData.sections
    .filter((section) => section.type === "section")
    .flatMap((section) => section.fields)
    .filter((field) => !EXCLUDED_TYPES.includes(field.type))
    .map((field) => field.name);
};

export const handleOneToManyFields = (moduleData: ModuleData, workbook: XLSX.WorkBook, commonIdentifier: string): void => {
  const oneToManyFields = moduleData.sections
    .flatMap((section) => section.fields)
    .filter((field) => field.type === "OneToManyField");

  oneToManyFields.forEach((field) => {
    const subFieldHeaders = field.fieldOptions?.otmSections
      ?.flatMap((section) => section.fields.map((subField) => subField.name)) || [];

    subFieldHeaders.unshift("Common ID");

    const oneToManyWorksheetData = [subFieldHeaders];
    const oneToManyWorksheet = XLSX.utils.aoa_to_sheet(oneToManyWorksheetData);
    XLSX.utils.book_append_sheet(workbook, oneToManyWorksheet, field.name);
  });
};

export const handleTransactionTables = (moduleData: ModuleData, workbook: XLSX.WorkBook): void => {
  const commonIdentifier = "Common ID";

  moduleData.tableData.forEach((table, index) => {
    // Filter out unwanted column types, then map to column names
    const filteredColumns = table.columns
      .filter((col) => !EXCLUDED_TYPES.includes(col.type))
      .map((col) => col.name);

    const tableHeaders = [commonIdentifier, ...filteredColumns];
    const tableWorksheetData = [tableHeaders];
    const tableWorksheet = XLSX.utils.aoa_to_sheet(tableWorksheetData);

    const matchingSection = moduleData.sections.find((section) => section.id === table.id);
    const tableName = matchingSection ? matchingSection.title : `Table ${index + 1}`;

    XLSX.utils.book_append_sheet(workbook, tableWorksheet, tableName);
  });
};

export const exportTemplate = (moduleData: ModuleData, workbook: XLSX.WorkBook, commonIdentifier: string): void => {
  try {
    let headers = extractHeaders(moduleData);
    const oneToManyFields = moduleData.sections
        .filter((section) => section.type === "section")
        .flatMap((section) => section.fields)
        .filter((field) => field.type === "OneToManyField");
    const otmLength = oneToManyFields.length

    if (headers.length > 0) {
      if (moduleData.pageType === "Transaction" || (moduleData.pageType === "Reference" && otmLength > 0)) {
        // For Transaction pages, ensure 'Common ID' is the first header and add a tooltip via data validation
        headers.unshift("Common ID");
      } else {
        // For non-Transaction pages, prepend the provided commonIdentifier
        headers = [commonIdentifier, ...headers];
      }

      const worksheet = XLSX.utils.aoa_to_sheet([headers]);

      if (moduleData.pageType === "Transaction") {
        worksheet["A1"].c = [{
          a: "Quickbuild",
          t: "The Common ID should be 1, 2, 3, etc. for each record you want to create. This isn't imported, but is used to map parent data to table data",
          T: true
        }]
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
    }

    handleOneToManyFields(moduleData, workbook, commonIdentifier);

    if (moduleData.pageType === "Transaction") {
      handleTransactionTables(moduleData, workbook);
    }

    const dateTime = new Date().toLocaleString();
    const fileName = `${moduleData.moduleName}_Import_${dateTime}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  } catch (error) {
    console.error("Error exporting template:", error);
  }
};
import React, {useEffect, useRef, useState} from 'react';
import {Form, message, Modal} from 'antd'; // Ant Design components
import {renderField} from '../Render/FieldRender/_RenderField';
import dataAll from "../../utils/dataAll";
import {useTenant} from "../../contexts/TenantContext";
import {initializePage} from "../../utils/initializeRender";
import formValidateUtility from "../../utils/formValidation";

interface ReferencePageRenderProps {
  moduleData: any;
  setRecords: Function;
  closeModal: () => void;
  existingRecord?: any;  // Accept `existingRecord` as an optional prop
}

const ReferencePageRender: React.FC<ReferencePageRenderProps> = ({
  moduleData,
  setRecords,
  closeModal,
  existingRecord,
}) => {
  const [form] = Form.useForm();  // Form instance for the modal
  const isEditing = Boolean(existingRecord);
  const [referenceData, setReferenceData] = useState({});
  const [modalKey, setModalKey] = useState(0); // For resetting if needed
  const [otmData, setOtmData] = useState<{ [fieldId: string]: any[] }>({});
  const recordIdRef = useRef<string | null>(null); // Track the currently loaded record ID to avoid infinite loop
  const { tenantId, companyId } = useTenant()

  // Helper to determine schema type
  const isUsingNewSchema = (record: any): boolean => {
    if (!record?.data) return false;

    return Object.values(record.data).some((field) => {
      if (typeof field === 'object' && field !== null) {
        // Ensure the field has `label` and `value` properties
        const fieldWithLabelAndValue = field as { label?: string; value?: any };
        return typeof fieldWithLabelAndValue.label === 'string' &&
            fieldWithLabelAndValue.value !== undefined
      }
      return false;
    });
  };

  useEffect(() => {
    if (!moduleData) return;
    const recordId = existingRecord?._id || null;

    if (recordIdRef.current !== recordId) {
      recordIdRef.current = recordId; // Update ref to avoid re-running unnecessarily

      const initializeData = async () => {
        if (existingRecord) {
          initializePage.setFormValues(form, existingRecord, moduleData, null);
          const newOtmData = await initializePage.initializeOTM(existingRecord, moduleData);
          setOtmData(newOtmData);
        }
      };
      initializeData();
    }
  }, [moduleData, existingRecord]);

  useEffect(() => {
    if (!moduleData) return;
    const moduleIds: string[] = [];

    moduleData.sections.forEach((section: any) => {

      if (section.type === 'section') {
        section.fields.forEach((field: any) => {
          if (field.type === 'ReferenceLinkField' && field.fieldOptions?.modules) {
            field.fieldOptions.modules.forEach((mod: any) => {
              if (mod.moduleId && !moduleIds.includes(mod.moduleId)) {
                moduleIds.push(mod.moduleId);
              }
            });
          }
        });
      }
    });

    const fetchReferenceData = async () => {
      const data: { [key: string]: any[] } = {};
      for (const mId of moduleIds) {
        try {
          moduleData = await dataAll.dataExtractUtility.getReferenceData(tenantId, companyId, mId)
          data[mId] = moduleData.map((record: any) => ({
            _id: record._id,
            ...record.data,
          }));
        } catch (error) {
          console.error(error);
        }
      }
      setReferenceData(data);
    };
    fetchReferenceData();
  }, [moduleData, API_URL]);

  const handleSave = async () => {
    const values = form.getFieldsValue();
    // const missingRequiredFields: string[] = [];
    await formValidateUtility.validateReferencePage(values,moduleData._id, moduleData, otmData)

    // // Validate required fields
    // moduleData.sections.forEach((section: any) => {
    //   section.fields.forEach((field: any) => {
    //     if (field.fieldOptions?.required) {
    //       if (field.type === 'OneToManyField') {
    //         const dataArray = otmData[field.id] || [];
    //         if (dataArray.length === 0) missingRequiredFields.push(field.name);
    //       }
    //       if (field.type === 'PhoneNumber') {
    //         const phoneObject = values[field.name];
    //         const phoneNumber = phoneObject.phone;
    //         if (!phoneNumber) missingRequiredFields.push(field.name);
    //       }
    //       else {
    //         const value = values[field.name];
    //         if (!value) missingRequiredFields.push(field.name);
    //       }
    //     }
    //   });
    // });
    //
    // if (missingRequiredFields.length > 0) {
    //   Modal.error({
    //     title: 'Missing Required Fields',
    //     content: `Missing: ${missingRequiredFields.join(', ')}`,
    //   });
    //   return;
    // }

        // If not editing, fetch the next auto-increment value(s)
    if (!isEditing) {
      // Collect all AutoIncrementId fields
      const autoIncrementFields = [];
      moduleData.sections.forEach((section: any) => {
        section.fields.forEach((field: any) => {
          if (field.type === 'AutoIncrementId') {
            autoIncrementFields.push(field);
          }
        });
      });

      // Fetch and populate next auto-increment values
      for (const field of autoIncrementFields) {
        try {
          const nextValue = await dataAll.dataExtractUtility.getNextAutoIncrement(tenantId, companyId, moduleData._id, field.id)
          values[field.name] = nextValue; // Set the value into the form values
        } catch (error) {
          console.error('Error fetching auto-increment value:', error);
          return;
        }
      }
    }

    // Prepare the data object using only the new schema format
    const data: any = {};

    moduleData.sections.forEach((section: any) => {
      section.fields.forEach((field: any) => {
        const fieldValue = values[field.name];
        if (field.type === 'OneToManyField') {
          // Always include OTM data, even if the modal was not opened
          data[field.id] = {
            label: field.name,
            value: otmData[field.id] || existingRecord?.data?.[field.id]?.value || [],
          };
        }
        else if (field.type === 'TrueFalseField') {
          // Always save as true or false
          data[field.id] = {
            label: field.name,
            value: fieldValue === true || fieldValue === 'true' ? true : false // Explicit conversion
          }
        } else {
          // Default case for standard fields
          data[field.id] = {
            label: field.name, // Use field name as label
            value: fieldValue || null, // Store the value, default to null if missing
          };
        }
      });
    });


    const newRecord = {
      tenantId: moduleData.tenantId,
      companyId: moduleData.companyId,
      moduleId: moduleData._id,
      data,
    };

    try {
      const savedRecord = existingRecord?._id
        ? await dataAll.dataPostUtility.postData('reference', { ...newRecord, _id: existingRecord._id })
        : await dataAll.dataPostUtility.postData('reference', newRecord);
      setRecords((prev: any[]) => {
        const recordExists = prev.some((r) => r._id === (existingRecord?._id || savedRecord._id));

        if (recordExists) {
          return prev.map((r) => (r._id === (existingRecord?._id || savedRecord._id) ? savedRecord : r));
        } else {
          return [...prev, { ...newRecord, _id: savedRecord._id, data: { ...savedRecord.data } }];
        }
      });

      closeModal();
      message.success('Record saved successfully.');

    } catch (error) {
      console.error('Save failed', error);
      Modal.error({
        title: 'Save Error',
        content: 'Failed to save the record. Please try again.',
      });
    }
  };

  // Handle changes for One-to-Many Fields
  const handleOTMFieldChange = (fieldId: string, data: any[]) => {
    // Update only the OTM data for that specific field
    setOtmData(prev => ({ ...prev, [fieldId]: data }));
  };

  // Handle changes for Non-One-to-Many Fields
  const handleFieldValueChange = (fieldId: string, value: any) => {
    form.setFieldsValue({ [fieldId]: value });
  };

  const isNewSchema = isUsingNewSchema(existingRecord); // Determine if the record uses the new schema

  return (
    <Modal
      title={`${existingRecord ? 'Edit' : 'New'} ${moduleData?.moduleName}`}
      open={true}
      onCancel={closeModal}
      onOk={handleSave}
      width={800}
      key={modalKey}
    >
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={() => {
          // console.log('Updated form values:', form.getFieldsValue());
        }}
      >
        {moduleData.sections?.map((section: any) => {
          if (section?.type === 'section') {
            return (
              <div key={section.id}>
                <h3>{section.title}</h3>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                  {section.fields?.map((field: any) => {
                      const fieldValue = isNewSchema
                      ? existingRecord?.data?.[field.id]?.value ?? null // New schema
                      : existingRecord?.data?.[field.name] ?? null; // Old schema
                    if (field.type === 'OneToManyField') {
                      return renderField(
                        field,
                        section.id,
                        form,
                        referenceData,
                        handleOTMFieldChange,
                        moduleData,
                        null, // table data
                        undefined,
                        undefined,
                        isEditing,
                        moduleData.tenantId,
                        moduleData.companyId,
                        moduleData._id,
                        existingRecord?._id, // itemId or existingRecord?._id to identify the record
                        field.fieldOptions?.required || false,
                        'section',
                        otmData[field.id], // Pass the OTM array for this field as existingRecord
                        isNewSchema, // Pass schema type to the field render
                        fieldValue
                      );
                    } else {
                      return renderField(
                        field,
                        section.id,
                        form,
                        referenceData,
                        handleFieldValueChange,
                        moduleData,
                        null, // table data
                        undefined,
                        undefined,
                        isEditing,
                        moduleData.tenantId,
                        moduleData.companyId,
                        moduleData._id,
                        existingRecord?._id,
                        field.fieldOptions?.required || false,
                        'section',
                        existingRecord,
                        isNewSchema,
                        fieldValue, // Pass the extracted field value,
                      );
                    }
                  })}
                </div>
              </div>
            );
          } else if (section?.type === 'table') {
            console.log('no tables on reference pages')
            // return renderTableSection(section);
          }
          return null;
        })}
      </Form>
    </Modal>
  );
};

export default ReferencePageRender;

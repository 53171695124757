// Editor.tsx
import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Props for the Editor: pass in defaultValue and a callback for changes
interface EditorProps {
    defaultValue?: string;
    onChange: (content: string) => void;
}

// A simple, controlled wrapper around ReactQuill
function Editor({ defaultValue = "", onChange }: EditorProps) {
    const quillRef = useRef<ReactQuill>(null);

    const handleChange = (content: string) => {
        // 'content' is the HTML representation of the Quill editor
        onChange(content);
    };

    return (
        <ReactQuill
            ref={quillRef}
            theme="snow"
            defaultValue={defaultValue}
            onChange={handleChange}
            // You can customize Quill modules/toolbars here, if desired
        />
    );
}

export default Editor;
import React, { useEffect, useState } from 'react';
import { Input, Button, Row, Col, Checkbox } from 'antd';
// import { Droppable, Draggable } from 'react-beautiful-dnd';
import FieldModals from '../Fields/_FieldModals';
// import { useTenant } from '../../../contexts/TenantContext';
import '../../../styles/sectionOrganizer.css'
import {reorder} from '../../../utils/reorder'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useTour } from '../../../contexts/tour/tourContext';

interface SectionOrganizerProps {
  section: any;
  onFieldUpdate: Function;
  onFieldDelete: Function;
  onRenameSection: Function;
  pageType: string;
  newlyAddedFieldId?: string | null; // Receive the ID
  setNewlyAddedFieldId?: (id: string | null) => void; // Receive the setter
  currentModuleId?: string | null;
}

const SectionOrganizer: React.FC<SectionOrganizerProps> = ({
  section, 
  onFieldUpdate, 
  onFieldDelete, 
  onRenameSection, 
  pageType,
  newlyAddedFieldId,
  setNewlyAddedFieldId,
  currentModuleId
 }) => {
  // const { tenantId, companyId } = useTenant();
  const [selectedField, setSelectedField] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [sectionTitle, setSectionTitle] = useState(section.title);
  const { currentStep, setCurrentStep } = useTour();

  const handleTitleChange = (e: { target: { value: any; }; }) => {
    setSectionTitle(e.target.value);
  };

  const saveTitleChange = () => {
    onRenameSection(section.id, sectionTitle);
    setIsEditingTitle(false);
  };

  const handleFieldClick = (field: any) => {
    setSelectedField(field);
    setIsModalVisible(true);

    // Advance to the field editing step in the tour
    setCurrentStep(currentStep + 1); // Advance the tour step
  };

  const handleFieldOptionChange = (field: { fieldOptions: any; id: any; }, fieldOptionKey: string, value: any) => {
    const updatedField = {
      ...field,
      fieldOptions: { ...(field.fieldOptions || {}), [fieldOptionKey]: value },
    };
  
    // Update the field in the sections
    onFieldUpdate(section.id, updatedField.id, updatedField);
  
    // Update selectedField if necessary
    if (selectedField && selectedField.id === field.id) {
      setSelectedField(updatedField);
    }
  };

  const handleRequiredChange = (field: { fieldOptions: any; id: any; }, isChecked: boolean) => {
    handleFieldOptionChange(field, 'required', isChecked);
  };

  const requiredFieldTypes = ['TextField', 'Dropdown', 'NumberField', 'ReferenceLinkField', 'PhoneNumber', 'EmailField']

  // Reordering functions
  const moveFieldUp = (index: number) => {
    if (index === 0) return; // Already at the top
    const newFields = reorder(section.fields, index, index - 1);
    onFieldUpdate(section.id, 'all', newFields); // 'all' indicates updating the entire fields array
  };

  const moveFieldDown = (index: number) => {
    if (index === section.fields.length - 1) return; // Already at the bottom
    const newFields = reorder(section.fields, index, index + 1);
    onFieldUpdate(section.id, 'all', newFields); // 'all' indicates updating the entire fields array
  };

  useEffect(() => {
    if (newlyAddedFieldId) {
      const element = document.getElementById(newlyAddedFieldId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element.classList.add('highlight'); // Add highlight class
        
        // Trigger the tour step for the new field
        setTimeout(() => {
          element.classList.remove('highlight'); // Remove highlight after delay
          setNewlyAddedFieldId(null); // Reset the state
        }, 3000);
      }
    }
  }, [newlyAddedFieldId, setNewlyAddedFieldId]);

  return (
    <div className='section-container'>
          <div
            style={{
              padding: '10px',
              marginBottom: '10px',
              minHeight: '100px',
            }}
          >
            {/* Section Title: Editable */}
            {isEditingTitle ? (
              <Input
                value={sectionTitle}
                onChange={handleTitleChange}
                onBlur={saveTitleChange}
                onPressEnter={saveTitleChange}
                autoFocus
                className='section-title'
              />
            ) : (
              <h3
                onClick={() => setIsEditingTitle(true)}
                style={{ cursor: 'pointer' }}
              >
                {sectionTitle}
              </h3>
            )}
            {/* Render Fields */}
            <Row gutter={[16, 16]} >
              {section.fields.map((field, index) => (
                    <Col
                    key={field.id}
                    id={field.id} // Ensure each field has a unique id

                      span={10}
                      style={{
                        border: '1px solid gray',
                        borderRadius: '8px',
                        padding: '10px',
                        marginRight: '50px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleFieldClick(field)} // Make field clickable
                    >
                    <div className={`required-checkbox ${newlyAddedFieldId === field.id ? 'highlight' : ''}`}>
                       {field.name || field.type}
                    </div>
                      {pageType === 'Reference' && requiredFieldTypes.includes(field.type) && (
                        <Checkbox
                          checked={field.fieldOptions?.required || false}
                          onClick={(e) => e.stopPropagation()} // Prevent the modal from opening
                          onChange={(e) => handleRequiredChange(field, e.target.checked)}
                        >
                          Required
                        </Checkbox>
                      )}
                      <Button
                        type="text"
                        className='field-delete-btn'
                        onClick={(e) => {
                          e.stopPropagation();
                          onFieldDelete(section.id, field.id);
                        }}
                      >
                        Delete
                      </Button>
                      <div className="move-buttons" style={{ position: 'absolute', top: '5px', right: '5px' }}>
                      {/* Move Buttons */}
                      <Button
                        type="text"
                        icon={<ArrowUpOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          moveFieldUp(index);
                        }}
                        disabled={index === 0}
                        style={{ marginRight: '5px' }}
                      />
                      <Button
                        type="text"
                        icon={<ArrowDownOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          moveFieldDown(index);
                        }}
                        disabled={index === section.fields.length - 1}
                      />
                    </div>
                    </Col>
              ))}
            </Row>
          </div>

      <FieldModals
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={(updatedField) => {
          setIsModalVisible(false);
          setSelectedField(null);
          onFieldUpdate(section.id, updatedField.id, updatedField);
        }}
        currentField={selectedField}
        handleFieldOptionChange={(fieldOptionKey: string, value: any) =>
          handleFieldOptionChange(selectedField, fieldOptionKey, value)
        }
        currentModuleId={currentModuleId}
        />
    </div>
  );
};

export default SectionOrganizer;
import React, {useEffect, useState} from 'react';
import {Checkbox, Select, Switch} from 'antd';

const {Option} = Select;

interface TrueFalseFieldProps {
    id?: string;
    name?: string;
    value?: boolean;
    renderType?: 'Checkbox' | 'Toggle' | 'Dropdown';
    onChange?: (fieldOptionKey: string, value: any) => void;
}

const TrueFalseField: React.FC<TrueFalseFieldProps> = ({id, name, value, renderType = 'Checkbox', onChange}) => {
    const [selectedValue, setSelectedValue] = useState<boolean>(value || false);

    useEffect(() => {
        setSelectedValue(value || false); // Sync with parent state
    }, [value]);

    const handleChange = (newValue: boolean) => {
        setSelectedValue(newValue);
        onChange && onChange('value', newValue);
    };

    return (
        <div>
            {renderType === 'Checkbox' && (
                <Checkbox checked={selectedValue} onChange={(e) => handleChange(e.target.checked)}>
                    {name || 'Checkbox'}
                </Checkbox>
            )}

            {renderType === 'Toggle' && (
                <Switch checked={selectedValue} onChange={handleChange}/>
            )}

            {renderType === 'Dropdown' && (
                <Select value={selectedValue ? 'true' : 'false'} onChange={(val) => handleChange(val === 'true')}>
                    <Option value="true">True</Option>
                    <Option value="false">False</Option>
                </Select>
            )}
        </div>
    );
};

export const TrueFalseFieldConfig: React.FC<{
    fieldOptions: any;
    setFieldOptions: (options: any) => void;
}> = ({fieldOptions, setFieldOptions}) => {
    return (
        <div>
            <div style={{marginBottom: '10px'}}>
                <label>Render Type:</label>
                <Select
                    style={{ width: '100%' }}
                    value={fieldOptions.renderType || 'Checkbox'}
                    onChange={(val) => setFieldOptions({...fieldOptions, renderType: val})}
                >
                    <Option value="Checkbox">Checkbox</Option>
                    <Option value="Toggle">Toggle</Option>
                </Select>
            </div>
        </div>
    );
};

export default TrueFalseField;
import React, { useEffect, useState } from 'react';
import { Input, Switch } from 'antd';

interface URLFieldProps {
    id?: string;
    name?: string;
    value?: string;
    onChange?: (fieldOptionKey: string, value: any) => void;
}

const URLField: React.FC<URLFieldProps> = ({ id, name, value, onChange }) => {
    const [url, setUrl] = useState(value || '');

    useEffect(() => {
        setUrl(value || ''); // Sync with parent state
    }, [value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
        onChange && onChange('value', e.target.value);
    };

    return (
        <Input
            id={id}
            name={name}
            value={url}
            onChange={handleInputChange}
            placeholder="Enter URL"
            type="url"
        />
    );
};

export const URLFieldConfig: React.FC<{
    fieldOptions: any;
    setFieldOptions: (options: any) => void;
}> = ({ fieldOptions, setFieldOptions }) => {
    return (
        <div>
            <div style={{ marginBottom: '10px' }}>
                <label>Open in New Tab: </label>
                <Switch
                    checked={fieldOptions.openInNewTab || false}
                    onChange={(val) => setFieldOptions({ ...fieldOptions, openInNewTab: val })}
                />
            </div>
        </div>
    );
};

export default URLField;
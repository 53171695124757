import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace';
import { Select } from 'antd';
import ace from 'ace-builds/src-noconflict/ace'; // Import Ace for custom markers
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import '../../../styles/fieldBuilder.css'

// const { Option } = Select;
const Range = ace.require('ace/range').Range;

interface CalculationFieldProps {
  id?: string;
  formula?: string;
  currentFieldNames?: { id: string; name: string }[]; // Accept available fields (section + table)
  value?: string;
  onFormulaChange?: (formula: string, backendFormula: string) => void;
}

const CalculationFieldConfig: React.FC<CalculationFieldProps> = ({
  id,
  formula = '',
  currentFieldNames = [],
  value = '',
  onFormulaChange,
}) => {
  const [userFriendlyFormula, setUserFriendlyFormula] = useState(formula || '');
  const [backendFormula, setBackendFormula] = useState('');

  useEffect(() => {
    setUserFriendlyFormula(formula || '');
    setBackendFormula(transformFormulaToBackend(formula));
  }, [formula]);

  const fieldMap = currentFieldNames.reduce((map, field) => {
    map[field.name] = field.id;
    return map;
  }, {} as Record<string, string>);

  const idMap = currentFieldNames.reduce((map, field) => {
    map[field.id] = field.name;
    return map;
  }, {} as Record<string, string>);

  const transformFormulaToBackend = (formula: string): string => {
    if (!formula) return '';
  
    let backendFormula = formula;
  
    Object.entries(fieldMap).forEach(([name, id]) => {
      // Escape any special regex characters in the field name
      const escapedName = name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  
      // Create a regex to match [Field Name]
      const regex = new RegExp(`\\[${escapedName}\\]`, 'g');
      // let backendTemp
      // backendTemp = backendFormula.replace(regex, `[${id}]`);
  
      // Replace [Field Name] with [Field ID]
      backendFormula = backendFormula.replace(regex, `[${id}]`);
    });
    return backendFormula;
  };


  const transformFormulaToUserFriendly = (formula: string): string => {
    if (!formula) return '';
    let userFriendly = formula;
    Object.entries(idMap).forEach(([id, name]) => {
      const regex = new RegExp(`\$begin:math:display$${id}\\$end:math:display$`, 'g'); // Match [FieldID]
      userFriendly = userFriendly.replace(regex, `[${name}]`);
    });
    return userFriendly;
  };

  const validateFields = (formula: string) => {
    const validFields = currentFieldNames.map((field) => field.name);
    const regex = /\[([^\]]+)]/g; // Matches [FieldName] in the formula
    const matches = [...formula.matchAll(regex)];

    const markers = matches.map((match) => {
      const fieldName = match[1];
      const start = match.index || 0;
      const end = start + match[0].length;

      const isValid = validFields.includes(fieldName);

      return {
        start,
        end,
        isValid,
      };
    });

    return markers;
  };

  const handleFormulaChange = (newFormula: string) => {
    const backendCompatibleFormula = transformFormulaToBackend(newFormula);
    // console.log('user friendly: ', newFormula)
    // console.log('backend friendly: ', backendCompatibleFormula)
    setUserFriendlyFormula(newFormula);
    setBackendFormula(backendCompatibleFormula);
    if (onFormulaChange) onFormulaChange(newFormula, backendCompatibleFormula);
  };

  const handleInsert = (syntax: string) => {
    const newFormula = userFriendlyFormula + syntax;
    handleFormulaChange(newFormula);
  };

  useEffect(() => {
    const editor = ace.edit('calculation-editor'); // Get Ace Editor instance
    const session = editor.getSession();
    // session.clearMarkers(); // Clear previous markers

    const markers = validateFields(userFriendlyFormula);

    markers.forEach((marker) => {
      const className = marker.isValid ? 'valid-field' : 'invalid-field';
      session.addMarker(
        new Range(0, marker.start, 0, marker.end),
        className,
        'text'
      );
    });
  }, [userFriendlyFormula]);

  const functions = [
    { name: 'IF', syntax: 'if([Condition], [ValueIfTrue], [ValueIfFalse])' },
    { name: 'CONCAT', syntax: 'concat([Field1], [Field2])' },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        <Select
          showSearch
          placeholder="Search fields"
          style={{ width: '100%' }}
          onSelect={(value) => handleInsert(`[${value}]`)}
          filterOption={(input, option) =>
            (option?.label || '').toLowerCase().includes(input.toLowerCase())
          }
          options={currentFieldNames.map((field) => ({
            value: field.name,
            label: field.name,
          }))}
        />

        <Select
          showSearch
          placeholder="Search functions"
          style={{ width: '100%' }}
          onSelect={(value) => handleInsert(value)}
          filterOption={(input, option) =>
            (option?.label || '').toLowerCase().includes(input.toLowerCase())
          }
          options={functions.map((func) => ({
            value: func.syntax,
            label: func.name,
          }))}
        />
      </div>

      <div>
        <label htmlFor={`${id}-formula`} style={{ display: 'block', marginBottom: '8px' }}>
          Enter your formula:
        </label>
        <AceEditor
          mode="javascript"
          theme="github"
          value={userFriendlyFormula}
          onChange={handleFormulaChange}
          name="calculation-editor"
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false }}
          placeholder="Enter your formula here (e.g., [Qty] * [Price])"
          style={{
            width: '100%',
            height: '200px',
            border: '1px solid #d9d9d9',
            borderRadius: '4px',
          }}
        />
      </div>
    </div>
  );
};

export default CalculationFieldConfig;
import React, {useEffect, useState} from 'react';
import {Modal, Menu, Button, Table} from 'antd';
import CheckoutPage from './PaddleCheckout'; // Existing Paddle modal
import PermissionModal from './PermissionsModal'
import TrainingModal from "./TrainingModal";
import dataAll from "../utils/dataAll";
import {useTenant} from "../contexts/TenantContext"; // Permission modal

// interface SettingsModalProps {
//   isVisible: boolean;
//   onClose: () => void;
// }

const SettingsPage = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('personalSettings');
  const [isPaddleModalOpen, setIsPaddleModalOpen] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [isTrainingModalVisible, setIsTrainingModalVisible] = useState(false);
  const {tenantId} = useTenant()

    const handleOpenPaddleModal = () => {
    setIsPaddleModalOpen(true);
  };

  const handleClosePaddleModal = () => {
    setIsPaddleModalOpen(false);
  };

  const handleOpenPermissionModal = () => {
    setIsPermissionModalOpen(true);
  };

  const handleClosePermissionModal = () => {
    setIsPermissionModalOpen(false);
  };

  const handleSavePermissions = (selectedPermissions: string[]) => {
    console.log('Saved Permissions:', selectedPermissions);
  };

  const restartTour = () => {
      localStorage.removeItem('tutorialDismissed')
      window.location.href = '/home';
  }

  // Variables for menu content
  const personalSettingsContent = (
    <div>
        <p>Information coming soon</p>
        <Button
            type="primary"
            onClick={restartTour}
            >
            Restart Tour
        </Button>
    </div>

    // <div>
    //   <h3>Personal Information</h3>
    //   <div style={{ marginBottom: '10px' }}>
    //     <label>Name:</label>
    //     <Input placeholder="Enter your name" />
    //   </div>
    //   <div style={{ marginBottom: '10px' }}>
    //     <label>Email:</label>
    //     <Input placeholder="Enter your email" />
    //   </div>
    //   <div style={{ marginBottom: '10px' }}>
    //     <Button type="link">Forgot Password?</Button>
    //   </div>
    // </div>
  );

  const companyInfoContent = (
    <div><p>Information coming soon</p></div>

    // <div>
    //   <h3>Company Information</h3>
    //   <div style={{ marginBottom: '10px' }}>
    //     <label>Company Name:</label>
    //     <Input placeholder="Enter your company name" />
    //   </div>
    //   <div style={{ marginBottom: '10px' }}>
    //     <label>Company Logo:</label>
    //     <Input type="file" />
    //   </div>
    //   <div style={{ marginBottom: '10px' }}>
    //     <label>Company Address:</label>
    //     <Input placeholder="Enter your company address" />
    //   </div>
    // </div>
  );

  const permissionsContent = (
    <div>
      <p>Information coming soon</p>
      {/*<Button type="primary" onClick={handleOpenPermissionModal}>*/}
      {/*  Manage Permissions*/}
      {/*</Button>*/}
    </div>
  );

    const Subscriptions = () => {
        const [subscriptions, setSubscriptions] = useState([]);

        useEffect(() => {
            const fetchSubscriptions = async () => {
                try {
                    const subs: object = await dataAll.dataExtractUtility.getSubscription(tenantId)
                    const subArray = [subs]
                    setSubscriptions(subArray); // Convert object to string for display
                } catch (error) {
                    console.error('Error fetching subscriptions:', error);
                }
            };
            fetchSubscriptions();
        }, []);

        const columns = [
            {
                title: 'Created Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (date: string) => new Date(date).toLocaleDateString(), // Format date
            },
            {
                title: 'Subscription Name',
                dataIndex: 'licenseName',
                key: 'licenseName',
            },
            {
                title: 'Expiration Date',
                dataIndex: 'expiresAt',
                key: 'expiresAt',
                render: (date: string) => new Date(date).toLocaleDateString(), // Format date
            },
        ];

        return (
            <div>
                <h2>Subscriptions</h2>
                <Table columns={columns} dataSource={subscriptions} rowKey="_id" />
            </div>
        );
    };

  const billingInfoContent = (
    // <div><p>Information coming soon</p></div>

      <div>
          <h3>Billing Information</h3>
          <p>Manage your subscription via our payment processor.</p>
          <p>All payments are processed by Paddle</p>
          <p>After successful checkout, you'll receive an email with details. Exit the modals afterwards.</p>
          <Button type="primary" onClick={handleOpenPaddleModal}>
              Buy Licenses
          </Button>
          <p></p>
          <p>By continuing to use QuickBuild, you agree to our
              <a href={"https://www.quickbuildnow.com/terms-of-service"} target="_blank" rel="noopener noreferrer"> Terms of Service </a>
               and
              <a href={"https://www.quickbuildnow.com/privacy-policy"} target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.</p>
          <p>Need to cancel? Sorry to see you go. Just email us and we'll take care of that for you</p>
      </div>
  );

    const trainingContent = <TrainingModal/>;

    // Render content based on selected menu item
    const renderContent = () => {
    switch (selectedMenuItem) {
      case 'personalSettings':
        return personalSettingsContent;
      case 'companyInfo':
        return companyInfoContent;
      case 'permissions':
        return permissionsContent;
      case 'billingInfo':
          return (
          <>
              {billingInfoContent}
              <Subscriptions />
          </>);
      case 'training':
        return trainingContent;
      default:
        return <div>Select an option from the menu.</div>;
    }
  };

return (
    <div style={{ display: 'flex', height: '100vh', padding: '20px' }}>
        {/* Left-hand side menu */}
        <Menu
            mode="inline"
            style={{ width: 200 }}
            selectedKeys={[selectedMenuItem]}
            onClick={(e) => setSelectedMenuItem(e.key)}
        >
            <Menu.Item key="personalSettings">Personal Settings</Menu.Item>
            <Menu.Item key="companyInfo">Company Information</Menu.Item>
            <Menu.Item key="billingInfo">Billing Information</Menu.Item>
            <Menu.Item key="permissions">Permissions</Menu.Item>
            <Menu.Item key="training">Training</Menu.Item>
        </Menu>

        {/* Right-hand side content */}
        <div style={{ flex: 1, padding: '20px' }}>
            {renderContent()}
        </div>

        {/* Paddle Modal */}
        <Modal
            title="Billing Information"
            open={isPaddleModalOpen}
            onCancel={handleClosePaddleModal}
            footer={null}
            width={800}
        >
            <CheckoutPage />
        </Modal>

        {/* Permission Modal */}
        <PermissionModal
            visible={isPermissionModalOpen}
            onClose={handleClosePermissionModal}
            onSave={handleSavePermissions}
            users={[
                { id: '1', name: 'Alice' },
                { id: '2', name: 'Bob' },
                { id: '3', name: 'Bob2' },
                { id: '4', name: 'Bob3' },
                { id: '5', name: 'Bob4' },
                { id: '6', name: 'Bob5' },
                { id: '7', name: 'Bob6' },
                { id: '8', name: 'Bob7' },
                { id: '9', name: 'Bob8' },
            ]}
        />
        <Modal
            title="Training Videos"
            open={isTrainingModalVisible}
            onCancel={() => setIsTrainingModalVisible(false)}
            footer={null}
            width={800}
        >
            <TrainingModal />
        </Modal>
    </div>
);
};
export default SettingsPage;

import React, { useState, useEffect } from "react";
import {Table, Button, Modal, notification, Input} from "antd";
import { useNavigate } from "react-router-dom";
import dataAll from "../../utils/dataAll";
import {useTenant} from "../../contexts/TenantContext"
import {useUser} from "../../contexts/UserContext";
import {CopyOutlined, DeleteOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import "../../styles/pageBuilder.css"

const PageBuilder = () => {
    const [modules, setModules] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedModuleType, setSelectedModuleType] = useState(null);
    const navigate = useNavigate();
    const { tenantId, companyId } = useTenant()
    const { user } = useUser();
    const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
    const [newModuleName, setNewModuleName] = useState('');
    const [moduleToCopy, setModuleToCopy] = useState(null); // The module we're copying


    useEffect(() => {
        if (tenantId && companyId) {
            fetchModules();
        }
    }, [tenantId, companyId]);

    const fetchModules = async () => {
        try {
            const response = await dataAll.dataExtractUtility.getAllModules(tenantId, companyId);
            setModules(response);
        } catch (error) {
            console.error("Error fetching modules: ", error);
        }
    };

    const handleEdit = async (moduleId: string) => {
        try {
            const singleModule = await dataAll.dataExtractUtility.getModuleData(moduleId);
            const singleModData = singleModule.moduleData;
            const pageType = singleModData.pageType.toLowerCase() || "unknown";
            navigate(`/builder/${pageType}-page-builder`, { state: { module: singleModData } });
        } catch (error) {
            console.error("Error fetching single module: ", error);
        }
    };

    const handleCopyClick = (module) => {
        setModuleToCopy(module); // Set the module to copy
        setNewModuleName(''); // Reset the module name input
        setIsCopyModalVisible(true); // Open the modal
    };

    // Handle copy module logic when the user confirms the modal
    const handleCopyModule = async () => {
        if (!newModuleName.trim()) {
            alert("Please enter a new module name.");
            return;
        }


        try {
            const response = await fetch(`${API_URL}/modules/copy`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    moduleId: moduleToCopy._id, // Pass the full module to copy
                    newModuleName, // Pass the new module name
                }),
            });

            if (response.ok) {
                const newModule = await response.json();
                // console.log('response ok')
                setModules([...modules, newModule]); // Add the copied module to the module list
                setIsCopyModalVisible(false); // Close the modal
                notification.success({
                    message: 'Page Copied',
                    description: 'Refresh for alphabetical order, or see the page at the end of the list.',
                    placement: 'top',
                    duration: 3
                })
            } else {
                console.error("Failed to copy module.");
            }
        } catch (error) {
            console.error("Error copying module:", error);
        }
    };

    const handlePublish = async (moduleId: string) => {

        try {
            const publishedModules = await dataAll.dataExtractUtility.getPublishedModules(tenantId, companyId);
            const PUBLISHED_LIMIT = 20
            if (publishedModules.length >= PUBLISHED_LIMIT && user.roles.includes('freetrial')) {
                notification.warning({
                    message: 'Publish Limit Reached',
                    description: `Free Trial has a limit of ${PUBLISHED_LIMIT} published pages. Please upgrade to publish more.`,
                    placement: 'top',
                });
                return; // Exit the function if the limit is reached
            }
            const updatedModule = await dataAll.dataPostUtility.publishModule(moduleId);
            if (updatedModule) {
                setModules(modules.map(module =>
                    module._id === moduleId ? updatedModule : module
                ));  // Update the module's status in the state

                console.log("Module published successfully");
                notification.success({
                    message: 'Page Published',
                    description: 'Refresh the screen to see your new page.',
                    placement: 'top',
                    duration: 3
                })

            } else {
                console.error("Failed to publish module.");
            }

        } catch (error) {
            console.error(error);
        }

    };

    const handleDelete = async (moduleId: string) => {
        if (!window.confirm("Are you sure you want to delete this module?")) return;
        try {
            await dataAll.dataPostUtility.deleteData("module", moduleId);
            setModules(modules.filter((module) => module._id !== moduleId));
            notification.success({ message: "Page Deleted", placement: "top", duration: 3 });
        } catch (error) {
            console.error("Failed to Delete: ", error);
        }
    };

    const columns = [
        { title: "Module Name", dataIndex: "moduleName", key: "moduleName" },
        { title: "Status", dataIndex: "status", key: "status" },
        { title: "Page Type", dataIndex: "pageType", key: "pageType" },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <>
                    <Button
                        type="default"
                        icon={<EditOutlined/>}
                        onClick={() => handleEdit(record._id)}
                        className="action-button"
                    >Edit</Button>
                    <Button
                        type="default"
                        icon={<CopyOutlined/>}
                        onClick={() => handleCopyClick(record)}
                        className="action-button"
                    >Copy</Button>
                    <Button
                        type="default"
                        icon={<SaveOutlined/>}
                        onClick={() => handlePublish(record._id)}
                        className="action-button"
                    >Publish</Button>
                    <Button
                        type="default"
                        danger
                        icon={<DeleteOutlined/>}
                        onClick={() => handleDelete(record._id)}
                        className="delete-button"
                    >Delete</Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <h1>Page Library</h1>
            <Table dataSource={modules} columns={columns} rowKey="_id" />
            <Button type="primary" onClick={() => setIsModalVisible(true)}>+ New</Button>

            {/* Modal for Selecting Page Type */}
            <Modal
                title="What kind of page do you want to create?"
                open={isModalVisible}
                onOk={() => {
                    if (selectedModuleType) {
                        navigate(`/builder/${selectedModuleType.toLowerCase()}-page-builder`);
                        setIsModalVisible(false);
                    }
                }}
                onCancel={() => setIsModalVisible(false)}
            >
                <Button onClick={() => setSelectedModuleType("Reference")} block>Reference Page</Button>
                <Button onClick={() => setSelectedModuleType("Transaction")} block>Transaction Page</Button>
            </Modal>
            {/* Copy Module Modal */}
            <Modal
                title="Copy Module"
                open={isCopyModalVisible}
                onOk={handleCopyModule}  // Handle copying logic
                onCancel={() => setIsCopyModalVisible(false)}  // Close the modal
            >
                <Input
                    value={newModuleName}
                    onChange={(e) => setNewModuleName(e.target.value)}
                    placeholder="Enter new module name"
                />
            </Modal>
        </div>
    );
};

export default PageBuilder;
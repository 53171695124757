import React, { useEffect, useState } from 'react';
import {Checkbox, Switch, Select, Form, Input} from 'antd';

const { Option } = Select;

interface TrueFalseFieldRenderProps {
    field: any;
    handleFieldChange: (value: boolean) => void;
    value?: boolean | string;
    renderType?: 'table' | 'section';
    required?: boolean;
}

const TrueFalseFieldRender: React.FC<TrueFalseFieldRenderProps> = ({
       field,
       handleFieldChange,
       value,
       renderType,
       required
   }) => {
    const renderOption = field?.fieldOptions?.renderType || 'Checkbox'; // Default to Checkbox
    const [localValue, setLocalValue] = useState<boolean>(
        value === true || value === 'true'
    );

    useEffect(() => {
        setLocalValue(value === true || value === 'true');
    }, [value]);

    const handleBooleanChange = (newValue: boolean) => {
        setLocalValue(newValue);
        handleFieldChange(newValue);
    };

    const renderComponent = () => {
        if (renderOption === 'Checkbox') {
            return (
                <Checkbox
                    checked={localValue}
                    onChange={(e) => handleBooleanChange(e.target.checked)}
                />
            );
        } else if (renderOption === 'Toggle') {
            return (
                <Switch
                    checked={localValue} // ✅ Use updated local state
                    onChange={(checked) => handleBooleanChange(checked)}
                />
            );
        } else {
            return (
                <Select
                    value={localValue}
                    onChange={(val) => handleBooleanChange(val === true)}
                >
                    <Option value="true">True</Option>
                    <Option value="false">False</Option>
                </Select>
            );
        }
    };

    return (
        <Form.Item
            name={field.name}
            required={required}
            initialValue={localValue}
            valuePropName="checked"
            {...(renderType !== 'table' && { label: field.name })}
        >
            {renderComponent()}
        </Form.Item>
    );
};

export default TrueFalseFieldRender;
// TransactionPageBuilder.tsx

import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Modal, notification, Select } from 'antd';
import FieldPalette from '../Fields/_FieldPalette';
import FieldTable from '../Organizers/TableOrganizer';
import PageOrganizer from '../Organizers/PageOrganizer';
import { useTenant } from '../../../contexts/TenantContext';
import { useLocation } from 'react-router-dom';
import StageBuilder from './StageBuilder';
import { v4 as uuidv4 } from 'uuid';
import { useUser } from '../../../contexts/UserContext';
import {DeleteOutlined, PlusSquareOutlined, SaveOutlined, ToolOutlined} from '@ant-design/icons';
import dataAll from "../../../utils/dataAll";

const { Option } = Select;

interface TransactionPageBuilderProps {
  module?: any;  // Optional module prop
}

interface Field {
    id: string;
    name: string;
    fieldType: string;
  }

type Section = FormSection | TableSection;

interface FormSection {
  id: string;
  title: string;
  type: 'section';
  fields: any[];
}

interface TableSection {
  id: string;
  title: string;
  type: 'table';
  columns: any[];
}





const TransactionPageBuilder: React.FC<TransactionPageBuilderProps> = () => {
  const location = useLocation();
  const [module, setModule] = useState(location.state?.module || null);  // Store module in local state
  const [pageTitle, setPageTitle] = useState('New Transaction Page');
  const [moduleName, setModuleName] = useState(pageTitle);
  const [version, setVersion] = useState('1.0.0');
  const [status, setStatus] = useState('draft');
  const [functions, setFunctions] = useState([]);
  const { tenantId, companyId } = useTenant();
  const [sections, setSections] = useState<Section[]>([
    { id: `section-${Date.now()}`, title: 'Section 1', type: 'section', fields: [] },
    { id: `table-${Date.now()}`, title: 'Table 1', type: 'table', columns: [] },
  ]);

  const [selectedSection, setSelectedSection] = useState<string>(
    `section-${Date.now()}` // Default to the first section
  );

  const [isStageModalVisible, setIsStageModalVisible] = useState(false);
  const [stages, setStages] = useState([]);
  const [stageFieldAdded, setStageFieldAdded] = useState(false);
  const { user } = useUser();
  const [currentFields, setCurrentFields] = useState<Field[]>([]); // State to store current fields
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [canViewFunctions, setCanViewFunctions] = useState(false);
  // Reference to store table data from multiple tables
  const tableRefs = useRef<any>({});

  useEffect(() => {
    if (user) {
      setCanViewFunctions(user.roles.some(role => ['quickbuildadmin', 'partner'].includes(role)));
    } else {
      setCanViewFunctions(false);
    }
  }, [user]);

  const initialSectionsRef = useRef([]);

  useEffect(() => {
    if (module) {
      setPageTitle(module.moduleName || 'Editable Page Title');
      setModuleName(module.moduleName || 'Module Name');
      setFunctions(module.functions || []);
      setVersion(module.version || '1.0.0');
      setStatus(module.status || 'draft');
      setStages(module.stages || []);
      setStageFieldAdded(module.stageFieldAdded || false);

      // Map over sections to load fields and table columns correctly
      const updatedSections = (module.sections || []).map((section: { type: string; id: any; fields: any[]; }) => {
        if (section.type === 'table') {
          const selectedTable = module.tableData?.find((entry: { id: any; }) => entry.id === section.id);
          const tableColumns = selectedTable?.columns || [];
          return { ...section, columns: tableColumns };
        } else if (section.type === 'section') {
          const fields = section.fields || [];
          return { ...section, fields };
        }
        return section;
      });

      setSections(updatedSections);
      setSelectedSection(updatedSections[0]?.id || '');
      initialSectionsRef.current = updatedSections;
      setHasUnsavedChanges(false)
    }
  }, [module]);

  const hasChanges = () => {
    // Compare sections
    if (sections.length !== initialSectionsRef.current.length) return true;

    for (let i = 0; i < sections.length; i++) {
      const currentSection = sections[i];
      const initialSection = initialSectionsRef.current[i];

      // Check section-level properties
      if (
        currentSection.id !== initialSection.id ||
        currentSection.title !== initialSection.title ||
        currentSection.type !== initialSection.type
      ) {
        return true;
      }

      // Compare fields for 'section' type
      if (currentSection.type === 'section') {
        if (currentSection.fields.length !== initialSection.fields.length) return true;

        for (let j = 0; j < currentSection.fields.length; j++) {
          if (
            JSON.stringify(currentSection.fields[j]) !==
            JSON.stringify(initialSection.fields[j])
          ) {
            return true;
          }
        }
      }

      // Compare columns for 'table' type
      if (currentSection.type === 'table') {
        if (currentSection.columns.length !== initialSection.columns.length) return true;

        for (let j = 0; j < currentSection.columns.length; j++) {
          if (
            JSON.stringify(currentSection.columns[j]) !==
            JSON.stringify(initialSection.columns[j])
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };



  const onAddField = (fieldType: any, sectionId: string) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId && section.type === 'section') {
        const newField = {
          id: `field-${Date.now()}`,
          type: fieldType,
          name: `${fieldType}_${Date.now()}`,
          fieldOptions: {}
        };
        return { ...section, fields: [...section.fields, newField] };
    }
      return section;
    });
    setSections(updatedSections);
  };

  const onFieldUpdate = (sectionId: string, fieldId: string, updatedField: any) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId && section.type === 'section') {
        let updatedFields: any[];
        if (fieldId === 'all') {
          // Replace the entire fields array
          updatedFields = updatedField;
        } else {
          // Update a single field
          updatedFields = section.fields.map((field) =>
            field.id === fieldId ? { ...field, ...updatedField } : field
          );
        }
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const onFieldDelete = (sectionId: string, fieldId: any) => {
    const updatedSections = sections.map(section => {
      if (section.id === sectionId && section.type === 'section') {
        const updatedFields = section.fields.filter(field => field.id !== fieldId);
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const addSection = () => {
    const newSection: FormSection = {
      id: `section-${Date.now()}`,
      title: `Section ${sections.length + 1}`,
      type: 'section',
      fields: [],
    };
    setSections([...sections, newSection]);
  };


  const addTable = () => {
    const newTable: TableSection = {
      id: uuidv4(), // Generate a unique UUID
      title: `Table ${sections.length + 1}`,
      type: 'table',
      columns: [],
    };
    setSections([...sections, newTable]);
  };

  const handleStageSave = (newStages: React.SetStateAction<any[]>) => {
    setStages(newStages);
    setHasUnsavedChanges(true)
    setIsStageModalVisible(false);

    // Add stage field to the schema if not already added
    if (!stageFieldAdded) {
      setStageFieldAdded(true);
    }
  };


  const saveSchema = async () => {

    // Collect table data from all tables
    const tableData = []; // Initialize as an array
    for (let section of sections) {
      if (section.type === 'table') {
        const tableRef = tableRefs.current[section.id];
        if (tableRef) {
          const tableContent = tableRef.getTableData(); // Get table data for the section
          tableData.push({
            id: section.id, // Use the section's ID
            ...tableContent, // Add the columns and data from the table
          });
        }
      }
    }

    // console.log(addTypeToSections)
    const schema = {
      pageTitle: moduleName,
      moduleName,
      version,
      status,
      sections,
      tableData,
      tenantId,
      companyId,
      pageType: 'Transaction',
      functions:  undefined,
      // functions.map((func) => ({
      //   functionId: func.functionId,
      //   name: func.name,
      //   steps: func.steps.map((step) => ({
      //     stepId: step.stepId,
      //     type: step.type,
      //     typeDetails: step.typeDetails,
      //   })),
      // })),
      stages,
    };

    // console.log('saving schema: ', schema)

    const autoIncrementFields = [];
    sections.forEach((section) => {
      if (section.type === 'section') {  // Only FormSection has 'fields'
        section.fields.forEach((field) => {
          if (field.type === 'AutoIncrementId') {
            autoIncrementFields.push(field);
          }
        });
      }
    });

    try {
      let savedModule
      if (module && module._id) {
        savedModule = await dataAll.dataPostUtility.overwriteData('module', module._id, schema)
      } else {
        savedModule = await dataAll.dataPostUtility.postData('module', schema);
      }

      // const updatedModule = await dataAll.dataPostUtility.overwriteData('module', module._id, schema)
      setModule(savedModule)
      notification.success({
        message: 'Schema saved',
        description: 'Click "Builder" to view in library',
        placement: 'top',
        duration: 3
      })
      setHasUnsavedChanges(false)

      // if (savedModule) {
      //   const updatedModule = dataAll.dataExtractUtility.getModuleData(savedModule._id)
      //   setModule(updatedModule)
      // } <-- don't think this is needed. It's just to refresh the savedModule, which should have just been defined earlier.

    } catch (error) {
      console.error(error);
    }


    // try {
    //   const url = module?._id
    //     ? `${API_URL}/modules/${module._id}`
    //     : `${API_URL}/modules/save`;
    //
    //     // console.log('url: ', url)
    //   const method = module?._id ? 'PUT' : 'POST';
    //
    //   const response = await fetch(url, {
    //     method,
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify(schema),
    //   });
    //
    //   const result = await response.json();
    //   if (response.ok) {
    //     const fetchUrl = `${API_URL}/modules/${result._id || module?._id}`;
    //     const fetchResponse = await fetch(fetchUrl);
    //     const updatedModule = await fetchResponse.json();
    //     setModule(updatedModule)
    //
    //     notification.success({
    //       message: 'Schema saved',
    //       description: 'Click "Builder" to view in library',
    //       placement: 'top',
    //       duration: 3
    //     })
    //     setHasUnsavedChanges(false);
    //
    //   } else {
    //     console.error('Failed to save schema: ', result);
    //   }
    // } catch (error) {
    //   console.error('Error saving schema:', error);
    // }
  };

  let availableFields = [
    'TextField',
    // 'ParagraphTextField',
    'AutoIncrementId',
    'PhoneNumber',
    'EmailField',
    'Dropdown',
    'ReferenceLinkField',
    'NumberField',
    'DateField',
    'TrueFalseField',
    //'URLField'
  ];



  let availableFieldsForTable = [
    'TextField',
    // 'ParagraphTextField',
    'PhoneNumber',
    'EmailField',
    'Dropdown',
    'ReferenceLinkField',
    'NumberField',
    'LineNumber',
    'DateField',
    'TrueFalseField',
    // 'URLField'
  ];


  if (canViewFunctions) {
    availableFields.push('CalculationField')
    availableFieldsForTable.push('CalculationField')
  }

  // Function to update columns in the sections state
  const onColumnsChange = (sectionId: string, newColumns: any[]) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId && section.type === 'table') {
          return { ...section, columns: newColumns };
        }
        return section;
      })
    );
  };

  const getAllFields = (): Field[] => {
    const allFields: Field[] = [];

    sections.forEach((section) => {
      if (section.type === 'section' && section.fields && Array.isArray(section.fields)) {
        // Collect fields from form sections
        const validFields = section.fields
            .filter((field: any) => field.id && field.name) // Ensure valid fields
            .map((field: any) => ({
              id: field.id,
              name: field.name,
              fieldType: field.type,
            }));
        allFields.push(...validFields);
      }
    });

    // Collect fields from tables using moduleData.tableData
    if (module) {
      module.tableData?.forEach((table: any) => {                   //ERROR HERE
        if (table.columns && Array.isArray(table.columns)) {
          const validTableColumns = table.columns
              .filter((col: any) => col.id && col.name) // Ensure valid columns
              .map((col: any) => ({
                id: col.id, // Use the column ID
                name: col.name,
                fieldType: col.type,
              }));
          allFields.push(...validTableColumns);
        } else {
          console.warn(`No valid columns found for table ID: ${table.id}`);
        }
      });

    }

    if (allFields.length === 0) {
      console.error('No fields were collected. Check sections and moduleData.tableData.');
    }
    return allFields;
  };

  const confirmDeleteSection = (sectionId: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete?',
      okText: 'Yes, Delete',
      okButtonProps: { danger: true },
      cancelText: 'Cancel, go back',
      onOk() {
        deleteSection(sectionId);
      },
    });
  };

  const deleteSection = (sectionId: string) => {
    const updatedSections = sections.filter((section) => section.id !== sectionId);
    setSections(updatedSections);
  };

  const updateCurrentFields = () => {
    const updatedFields = getAllFields();
    setCurrentFields(updatedFields);
  };

  useEffect(() => {
    updateCurrentFields();
  }, [sections, tableRefs]);

  useEffect(() => {
    if (hasChanges()) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [sections, functions, moduleName, version, status]);

  return (
    <div>
      <h1>Transaction Page Builder</h1>


      {hasUnsavedChanges && (
      <div className='unsaved-changes-banner'>
        <strong>Unsaved Changes:</strong> Don't forget to click "Save" or your changes will be lost
      </div>
      )}

      <Input
        value={pageTitle}
        onChange={(e) => {
            // console.log('Title: ', pageTitle)xw
          setPageTitle(e.target.value);
          setModuleName(e.target.value);
        }}
        placeholder="Editable Page Title"
        style={{ marginBottom: '10px', width: '50%' }}
      />

      <Select
        value={selectedSection}
        onChange={(value) => setSelectedSection(value)}
        style={{ marginBottom: '20px', width: '200px' }}
        >
          {sections
            .filter((section) => section.type === 'section')
            .map((section) => (
            <Option key={section.id} value={section.id}>
              {section.title}
            </Option>
          ))}
      </Select>

      <div >
        <Button
          type="primary"
          onClick={addSection}
          style={{ marginBottom: '10px', marginRight: '10px' }}
          icon={<PlusSquareOutlined/>}
        >
          Section
        </Button>
        <Button
          type="primary"
          onClick={addTable}
          style={{ marginBottom: '10px', marginRight: '10px' }}
          icon={<PlusSquareOutlined/>}
        >
          Table
        </Button>
        <Button
          type='primary'
          onClick={() => setIsStageModalVisible(true)}
          style={{ marginRight: '10px' }}
          icon={<ToolOutlined />}
        >
          Stages
        </Button>
        <Button
        type="primary"
        onClick={saveSchema}
        icon={<SaveOutlined />}
          style={{ marginBottom: '10px',
              backgroundColor: 'green',
               }}
        >
          Save
        </Button>
      </div>

      <div>
        <h3>Field Palette</h3>
        <FieldPalette
          onAddField={(fieldType: any) => {
            if (selectedSection) {
              onAddField(fieldType, selectedSection);
            } else {
              alert('Please select a section.');
            }
          }}
          availableFields={availableFields}
        />
      </div>

      <div>
      {sections.map((section) => {
        if (section.type === 'section') {
          return (
            <div key={section.id}>
                <PageOrganizer
                  sections={[section]}
                  setSections={setSections}
                  onFieldUpdate={onFieldUpdate}
                  onFieldDelete={onFieldDelete}
                  pageType='Transaction'
                />
              <Button
                  type='primary'
                  style={{background: 'red',
                    marginBottom: '30px',
                    marginTop: '-10px'
                  }}
                  icon={<DeleteOutlined/>}
                  onClick={() => confirmDeleteSection(section.id)
                  }
              >Section</Button>
            </div>
          );
        } else if (section.type === 'table') {
          return (
            <div key={section.id}>
              <h3>{section.title}</h3>
              <FieldTable
                ref={(ref) => (tableRefs.current[section.id] = ref)}
                availableFields={availableFieldsForTable}
                currentModuleId={module?._id || 'new-module'}
                currentModuleName={module?.moduleName || moduleName}
                initialColumns={section.columns || []}
                onColumnsChange={(newColumns) => onColumnsChange(section.id, newColumns)}
                currentFieldNames={getAllFields()} // Add new variable
              />
              <Button
                  type='primary'
                  style={{background: 'red',
                    marginBottom: '30px',
                    marginTop: '10px'
                  }}
                  icon={<DeleteOutlined/>}
                  onClick={() => confirmDeleteSection(section.id)
                  }
              >Table</Button>
            </div>
          );
        }
        return null;
      })}
    </div>

      {/* Modal for configuring stages */}
      <Modal
        title="Stage Builder"
        open={isStageModalVisible}
        onCancel={() => setIsStageModalVisible(false)}
        footer={null}
        className='stage-builder-modal'
        // width={1000}
      >
        <StageBuilder
            existingStages={stages}
            allFields={getAllFields()} // Pass all fields for field selection
            onSaveStages={handleStageSave}
        />
      </Modal>
    </div>
  );
};

export default TransactionPageBuilder;

import React, { useState, useEffect } from 'react';
import {Select, Button, Divider, Radio, Table, Form, Modal} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTenant } from '../../../contexts/TenantContext';
import dataAll from "../../../utils/dataAll";

const { Option } = Select;

interface AggregateRule {
  moduleId: string;
  aggregateField: {
    aggregateFieldId: string;
    aggregateFieldLabel: string;
  };
  matchField: {
    sourceFieldId: string;
    sourceFieldLabel: string;
  };
  stage: string;
  stageMatchType: string;
  operation: 'Increase' | 'Decrease';
  condition?: {
    conditionField: {
      conditionFieldId: string;
      conditionFieldLabel: string;
    };
    operator: string;
    value?: any;
  };
}

interface AggregateFieldProps {
  fieldOptions?: { aggregationRules?: AggregateRule[] };
  setFieldOptions?: (options: any) => void;
  onChange?: (fieldOptionKey: string, value: any) => void;
  sourceModule?: string;
}

const AggregateField: React.FC<AggregateFieldProps> = ({
                                                         fieldOptions = { aggregationRules: [] },
                                                         setFieldOptions,
                                                         onChange,
                                                       }) => {
  const [rules, setRules] = useState<AggregateRule[]>(fieldOptions.aggregationRules || []);
  const [formVisible, setFormVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  // Form state for rule creation/editing
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedAggregateField, setSelectedAggregateField] = useState('');
  const [selectedMatchField, setSelectedMatchField] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const [stageMatchType, setStageMatchType] = useState<'equals' | 'from-stage-onward'>('equals');
  const [operation, setOperation] = useState<'Increase' | 'Decrease'>('Increase');
  const [selectedConditionField, setSelectedConditionField] = useState('');
  const [selectedConditionOperator, setSelectedConditionOperator] = useState('');
  const [conditionValue, setConditionValue] = useState<any>('');

  // Options from API
  const [modules, setModules] = useState<any[]>([]);
  const [numberFields, setNumberFields] = useState<any[]>([]);
  const [matchFields, setMatchFields] = useState<any[]>([]);
  const [stages, setStages] = useState<any[]>([]);

  const { tenantId, companyId } = useTenant();

  useEffect(() => {
    if (fieldOptions.aggregationRules) {
      setRules(fieldOptions.aggregationRules);
    }
  }, [fieldOptions]);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const moduleData = await dataAll.dataExtractUtility.getAllModulesByType(
            tenantId, companyId, 'transaction'
        );
        setModules(moduleData);
      } catch (error) {
        console.error('Error fetching modules', error);
      }
    };
    fetchModules();
  }, [tenantId, companyId]);

  useEffect(() => {
    const fetchFieldsAndStages = async () => {
      if (!selectedModule) {
        setNumberFields([]);
        setMatchFields([])
        // setConditionFields([]);
        setStages([]);
        return;
      }
      try {
        const moduleFields = await dataAll.dataExtractUtility.getModuleDataFields(selectedModule);
        const moduleStages = await dataAll.dataExtractUtility.getModuleDataStages(selectedModule);
        const numberFields = moduleFields.filter(field =>
            ["NumberField", "CalculationField"].includes(field.type)
        );
        const matchFields = moduleFields.filter(field =>
            ["ReferenceLinkField"].includes(field.type)
        );
        setNumberFields(numberFields);
        setMatchFields(matchFields);
        // setConditionFields(moduleFields.moduleData)
        setStages(moduleStages);
      } catch (error) {
        console.error('Error fetching fields and stages', error);
      }
    };
    fetchFieldsAndStages();
  }, [selectedModule]);

  const resetForm = () => {
    setSelectedModule('');
    setSelectedAggregateField('');
    setSelectedMatchField('');
    setSelectedStage('');
    setStageMatchType('equals');
    setOperation('Increase');
    setSelectedConditionField('');
    setSelectedConditionOperator('');
    setConditionValue('');
    setEditingIndex(null);
  };

  const handleOpenForm = () => {
    resetForm();
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
    resetForm();
  };

  const handleEditRule = (record: AggregateRule, index: number) => {
    setEditingIndex(index);
    setSelectedModule(record.moduleId);
    setSelectedAggregateField(
        `${record.aggregateField.aggregateFieldId}|${record.aggregateField.aggregateFieldLabel}`
    );
    setSelectedMatchField(
        `${record.matchField.sourceFieldId}|${record.matchField.sourceFieldLabel}`
    );
    setSelectedStage(record.stage);
    setStageMatchType(record.stageMatchType as 'equals' | 'from-stage-onward');
    setOperation(record.operation);
    if (record.condition) {
      setSelectedConditionField(
          `${record.condition.conditionField.conditionFieldId}|${record.condition.conditionField.conditionFieldLabel}`
      );
      setSelectedConditionOperator(record.condition.operator);
      setConditionValue(record.condition.value);
    }
    setFormVisible(true);
  };

  const handleDeleteRule = (index: number) => {
    const updatedRules = rules.filter((_, i) => i !== index);
    setRules(updatedRules);
    setFieldOptions && setFieldOptions({ ...fieldOptions, aggregationRules: updatedRules });
    onChange && onChange('fieldOptions', updatedRules);
  };

  const handleSaveRule = () => {
    const [aggFieldId, aggFieldLabel] = selectedAggregateField.split('|');
    const [matchFieldId, matchFieldLabel] = selectedMatchField.split('|');

    const newRule: AggregateRule = {
      moduleId: selectedModule,
      aggregateField: { aggregateFieldId: aggFieldId, aggregateFieldLabel: aggFieldLabel },
      matchField: { sourceFieldId: matchFieldId, sourceFieldLabel: matchFieldLabel },
      stage: selectedStage,
      stageMatchType,
      operation,
      condition: selectedConditionField
          ? {
            conditionField: {
              conditionFieldId: selectedConditionField.split('|')[0],
              conditionFieldLabel: selectedConditionField.split('|')[1],
            },
            operator: selectedConditionOperator,
            value: conditionValue,
          }
          : undefined,
    };

    const updatedRules = editingIndex !== null
        ? rules.map((rule, idx) => (idx === editingIndex ? newRule : rule))
        : [...rules, newRule];

    setRules(updatedRules);
    setFieldOptions && setFieldOptions({ ...fieldOptions, aggregationRules: updatedRules });
    onChange && onChange('fieldOptions', updatedRules);
    setFormVisible(false);
    resetForm();
  };

  const aggregateRuleForm = (
      <Modal
          open={formVisible}
          title={editingIndex !== null ? "Edit Rule" : "Add New Rule"}
          onCancel={handleCloseForm}
          footer={[
            <Button key="cancel" onClick={handleCloseForm}>
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={handleSaveRule}>
              Save Rule
            </Button>,
          ]}
      >
        <Form layout="vertical">
          <Form.Item label="Module" required>
            <Select
                placeholder="Select Module"
                value={selectedModule}
                onChange={(val) => setSelectedModule(val)}
            >
              {modules.map((mod) => (
                  <Option key={mod._id} value={mod._id}>
                    {mod.moduleName}
                  </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Field to Aggregate" required>
            <Select
                placeholder="Select Field to Aggregate"
                value={selectedAggregateField}
                onChange={(val) => setSelectedAggregateField(val)}
                disabled={!selectedModule}
            >
              {numberFields.map((field) => (
                  <Option key={field.id} value={`${field.id}|${field.name}`}>
                    {field.name}: {field.id}
                  </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Match Field" required>
            <Select
                placeholder="Select Match Field"
                value={selectedMatchField}
                onChange={(val) => setSelectedMatchField(val)}
                disabled={!selectedModule}
            >
              {matchFields.map((field) => (
                  <Option key={field.id} value={`${field.id}|${field.name}`}>
                    {field.name}: {field.id}
                  </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Stage" required>
            <Select
                placeholder="Select Stage"
                value={selectedStage}
                onChange={(val) => setSelectedStage(val)}
                disabled={!selectedModule}
            >
              {stages.map((stage) => (
                  <Option key={stage.id} value={stage.name}>
                    {stage.name}
                  </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Stage Config" required>
            <Select
                placeholder="Select Stage Config"
                value={stageMatchType}
                onChange={(val) => setStageMatchType(val as 'equals' | 'from-stage-onward')}
            >
              <Option value="equals">Equals</Option>
              <Option value="from-stage-onward">From Stage Onward</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Increase/Decrease" required>
            <Radio.Group value={operation} onChange={(e) => setOperation(e.target.value)}>
              <Radio value="Increase">Increase</Radio>
              <Radio value="Decrease">Decrease</Radio>
            </Radio.Group>
          </Form.Item>

          <Divider />

          {/*<Form.Item label="Condition (Optional)">*/}
          {/*  <Select*/}
          {/*      placeholder="Select Condition Field"*/}
          {/*      value={selectedConditionField}*/}
          {/*      onChange={(val) => setSelectedConditionField(val)}*/}
          {/*      disabled={!selectedModule}*/}
          {/*  >*/}
          {/*    {conditionFields.map((field) => (*/}
          {/*        <Option key={field.id} value={`${field.id}|${field.name}`}>*/}
          {/*          {field.name}*/}
          {/*        </Option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}

          {/*  {selectedConditionField && (*/}
          {/*      <>*/}
          {/*        <Select*/}
          {/*            placeholder="Condition Operator"*/}
          {/*            value={selectedConditionOperator}*/}
          {/*            onChange={(val) => setSelectedConditionOperator(val)}*/}
          {/*            style={{ marginTop: 8 }}*/}
          {/*        >*/}
          {/*          <Option value="equals">Equals</Option>*/}
          {/*          <Option value="greater-than">Greater Than</Option>*/}
          {/*          <Option value="less-than">Less Than</Option>*/}
          {/*        </Select>*/}

          {/*        <Input*/}
          {/*            placeholder="Condition Value"*/}
          {/*            value={conditionValue}*/}
          {/*            onChange={(e) => setConditionValue(e.target.value)}*/}
          {/*            style={{ marginTop: 8 }}*/}
          {/*        />*/}
          {/*      </>*/}
          {/*  )}*/}
          {/*</Form.Item>*/}
        </Form>
      </Modal>
  );

  const columns = [
    { title: 'Module', dataIndex: 'moduleId', render: (value: string) => modules.find(m => m._id === value)?.moduleName || value },
    { title: 'Field to Aggregate', dataIndex: 'aggregateField', render: (field: any) => field.aggregateFieldLabel },
    { title: 'Match Field', dataIndex: 'matchField', render: (field: any) => field.sourceFieldLabel },
    { title: 'Stage', dataIndex: 'stage' },
    { title: 'Stage Config', dataIndex: 'stageMatchType' },
    { title: 'Increase/Decrease', dataIndex: 'operation' },
    // { title: 'Condition', dataIndex: 'condition', render: (cond: any) => cond ? `${cond.conditionField.conditionFieldLabel} ${cond.operator} ${cond.value || ''}` : 'None' },
    { title: 'Actions', dataIndex: 'actions', render: (_: any, record: AggregateRule, index: number) => (
          <>
            <Button type="link" icon={<EditOutlined />} onClick={() => handleEditRule(record, index)} />
            <Button type="link" icon={<DeleteOutlined />} danger onClick={() => handleDeleteRule(index)} />
          </>
      )},
  ];

  return (
      <div>
        <h3>Aggregate Field Builder</h3>
        <Table
            columns={columns}
            dataSource={rules}
            rowKey={(record, index) => index.toString()}
            pagination={false}
            locale={{ emptyText: 'No rules' }}
        />
        <Divider />
        <Button type="primary" onClick={handleOpenForm}>
          Add New Rule
        </Button>

        {/* Render Modal for Adding/Editing Rule */}
        {aggregateRuleForm}
      </div>
  );
};

export default AggregateField;
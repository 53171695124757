import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTenant } from '../contexts/TenantContext';
import { useUser } from '../contexts/UserContext';
import dataAll from "../utils/dataAll";
import dayjs from "dayjs";
import {Alert} from "antd";

const QBHome: React.FC = () => {
  // const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState<string | null>(null);
  const { tenantId, setTenantId, companyId, setCompanyId } = useTenant();  // Use tenant context
  const { user, loading: userLoading } = useUser(); // Access the logged-in user


  // Fetch user data if defaultTenantId is not present in UserContext
  const fetchUserData = async (email: string): Promise<number> => {
    try {
      const response = await axios.get(`${API_URL}/users/${email}`);
      const fetchedUser = response.data;
      if (!fetchedUser || !fetchedUser.defaultTenantId) {
        throw new Error('Default Tenant ID not found for the user.');
      }

      return fetchedUser.defaultTenantId;
    } catch (err: any) {
      console.error('Error fetching user data:', err);
      throw new Error(err.response?.data?.message || 'Failed to fetch user data.');
    }
  };


  // Fetch tenant and company data based on tenantId
  const fetchTenantAndCompany = async (tenantId: number) => {
    try {
      // Fetch tenant details
      const tenantResponse = await axios.get(`${API_URL}/tenants/${tenantId}`);

      const fetchedTenantId = tenantResponse.data.id;
      setTenantId(fetchedTenantId);
      localStorage.setItem('tenantId', fetchedTenantId)

      // Fetch companies associated with the tenant
      // const companies = await dataAll.dataExtractUtility.getCompanies(tenantId);
      const companiesResponse = await axios.get(`${API_URL}/company/tenantall/${fetchedTenantId}`);
      const companies = companiesResponse.data.data.companies;

      if (companies && companies.length > 0) {
        setCompanyId(companies[0].id); // Select the first company as default
        const newcompanyId = companies[0].id;
        localStorage.setItem('companyId', newcompanyId)
      } else {
        throw new Error('No companies found for the tenant.');
      }

      setLoading(false);
    } catch (err: any) {
      console.error('Error fetching tenant and company data:', err);
      // setError(err.message || 'Failed to fetch tenant and company data.');
      setLoading(false);
    }
  };

  // Initialize tenant and company data
  const initializeTenantAndCompany = async () => {
    try {
      let tenantId: number | undefined = user?.defaultTenantId;

      // If defaultTenantId is not present in UserContext, fetch it via API
      if (!tenantId) {
        if (!user?.email) {
          // TODO: IDK why exiting early still works, does this logic retry?
          setLoading(false);
          return;
        }
        tenantId = await fetchUserData(user?.email);
      }
      // Proceed to fetch tenant and company data
      await fetchTenantAndCompany(tenantId);
    } catch (err: any) {
      console.error('Initialization error:', err);
      // setError(err.message || 'Failed to initialize tenant and company data.');
      setLoading(false);
    }
  };

  const useDismissibleBanner = () => {
    const [isBannerVisible, setBannerVisible] = useState(true);

    useEffect(() => {
      const lastDismissed = localStorage.getItem('bannerDismissedDate');
      if (lastDismissed === dayjs().format('YYYY-MM-DD')) {
        setBannerVisible(false);
      }
    }, []);

    const dismissBanner = () => {
      localStorage.setItem('bannerDismissedDate', dayjs().format('YYYY-MM-DD'));
      setBannerVisible(false);
    };

    return { isBannerVisible, dismissBanner };
  };

  const ExpirationBanner = () => {
    const daysUntilExpiration = useDaysUntilExpiration();
    const { isBannerVisible, dismissBanner } = useDismissibleBanner();

    if (!isBannerVisible || daysUntilExpiration === null || daysUntilExpiration > 21) {
      return null;
    }

    return (
        <Alert
            message={`Your free trial expires in ${daysUntilExpiration} days.`}
            type="warning"
            closable
            onClose={dismissBanner}
            style={{ marginBottom: 16, backgroundColor: 'red', borderColor: "black" }}
        />
    );
  };

  const useDaysUntilExpiration = () => {
    const [daysUntilExpiration, setDaysUntilExpiration] = useState<number | null>(null);

    useEffect(() => {
      const fetchExpiration = async () => {
        try {
          const response = await dataAll.dataExtractUtility.getSubscription(tenantId)
          const planName = response.licenseName
          if (planName !== "Free Trial") {
            return
          }
          const expirationDate = new Date(response.expiresAt);
          const today = new Date();
          const differenceInTime = expirationDate.getTime() - today.getTime();
          const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

          setDaysUntilExpiration(differenceInDays);
        } catch (error) {
          console.error("Failed to fetch expiration date:", error);
        }
      };

      fetchExpiration();
    }, []);

    return daysUntilExpiration;
  };


  useEffect(() => {
    // Wait until UserContext loading is complete
    if (!userLoading) {
      initializeTenantAndCompany();
    }
  }, [userLoading, user]); // Depend on userLoading to ensure user data is ready

  if (loading || userLoading) {
    return <div>Loading tenant and company information...</div>;
  }

  const tutorialDismissed = localStorage.getItem('tutorialDismissed') || false

  return (
      <div style={{width: tutorialDismissed ? '80vw': '65vw', textAlign: 'left', marginLeft: tutorialDismissed ? '2vw' : 'auto'}}>
        <ExpirationBanner />
        <h1>
          Welcome to QuickBuild!
        </h1>
        <p>Watch this video to get started!</p>
        <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/675aoStj1UU?si=SiURKSxgJg99jOXd"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>

        <p>Please submit any bugs or feature requests <a href='https://forms.gle/2kYKBirrNxKHTNZs6' target="_blank"
                                                         rel="noopener noreferrer">here.</a></p>
        <br></br>
        <br></br>
        <p>Much love and appreciation,</p>
        <p>Nathan Cunningham, QuickBuild</p>
        <p>PS. Need support? Book time <a href='https://tidycal.com/ncunninghamservices/quickbuild-support'
                                          target="_blank" rel="noopener noreferrer">here</a></p>
      </div>
  );
};

export default QBHome;

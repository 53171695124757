import React, { useState, useEffect } from 'react';
import { Modal, Input } from 'antd';
import { useTenant } from '../../../contexts/TenantContext';

// Import field configuration components
import { DropdownConfig } from '../Fields/Dropdown';
import { AutoIncrementIdConfig } from '../Fields/AutoIncrementId';
import { NumberFieldConfig } from '../Fields/NumberField';
import { TextFieldConfig } from '../Fields/TextField';
import { ParagraphTextFieldConfig } from '../Fields/ParagraphTextField';
import { EmailFieldConfig } from '../Fields/EmailField';
import PhoneNumberConfig from '../Fields/PhoneNumber';
// import { OneToManyFieldConfig } from '../Fields/OneToManyField';
import { ReferenceLinkFieldConfig } from '../Fields/ReferenceLinkField';
import CalculationFieldConfig from './CalculationField';
import LineNumberConfig from './LineNumber';
import DateFieldConfig from './DateField';
import AggregateFieldConfig from './AggregateField';
import { useTour } from '../../../contexts/tour/tourContext';
import '../../../styles/tourStyles.css'
import '../../../styles/fieldBuilder.css'
import OneToManyBuilder from './OneToManyBuilderField';
import {TrueFalseFieldConfig} from "./TrueFalseField";
import {URLFieldConfig} from "./URLField";

interface FieldModalsProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (updatedField: any) => void;
  currentField: any;
  currentModuleId?: string;
  currentModuleName?: string;
  handleFieldOptionChange?: any;
  onSaveFieldOptions?: any;
  currentFieldNames?: { id: string; name: string }[];
}

// interface FieldOptions {
//   formula?: string; // Add other field options here as needed
//   [key: string]: any; // This allows any other properties as well
// }

const FieldModals: React.FC<FieldModalsProps> = ({
  visible,
  onCancel,
  onOk,
  currentField,
  currentModuleId,
  currentModuleName,
  currentFieldNames
}) => {
  // console.log('Available Fields in FieldModals:', currentFieldNames); // Debugging log

  const { tenantId, companyId } = useTenant();
  const [fieldName, setFieldName] = useState('');
  const [fieldOptions, ] = useState<any>({});
  const [tempFieldOptions, setTempFieldOptions] = useState<any>({}); // Temporary options for the modal
  const { setCurrentStep, currentStep, } = useTour(); // Access the tour context
  const [sectionId,] = useState<string>(''); // Assuming you have the section context


  // console.log('field modals called')
  type FieldEntry = { Key: string; Value: any };

  useEffect(() => {
    if (currentField) {
      // const currentFieldEntries = Object.values(currentField) as FieldEntry[];

      // Extract the `name` and `fieldOptions`
      // const fieldNameObj = currentFieldEntries.find((item) => item.Key === 'name');
      // const fieldOptionsObj = currentFieldEntries.find((item) => item.Key === 'fieldOptions');

      // Update state
      setFieldName(currentField.name || '');
      setTempFieldOptions(currentField.fieldOptions || {});
    }
  }, [currentField]);

  const handleOk = () => {
    const updatedField = { ...currentField, name: fieldName, fieldOptions: tempFieldOptions };
    onOk(updatedField); // Save changes
    
    // Advance the tour step
    setCurrentStep(currentStep + 1); // Advance the tour step

  };

  const handleCancel = () => {
    setFieldName(currentField?.name || ''); // Reset name
    setTempFieldOptions(currentField?.fieldOptions || {}); // Reset options
    onCancel(); // Close modal
  };


  const handleBuilderUpdate = (fieldId, updatedFieldOptions: any) => {
    setTempFieldOptions(updatedFieldOptions);
  };
  
  const renderFieldOptions = () => {
    const isFieldEntry = (item: unknown): item is FieldEntry => {
      return typeof item === "object" && item !== null && "Key" in item && "Value" in item;
    };

    const currentFieldType =
        // Attempt direct access first
        currentField?.type ||
        // Fallback to nested logic
        Object.values(currentField).find(
            (item): item is FieldEntry => isFieldEntry(item) && item.Key === "type"
        )?.Value;

    switch (currentFieldType) {
      case 'Dropdown':
        return <DropdownConfig fieldOptions={tempFieldOptions} setFieldOptions={setTempFieldOptions} />;
      case 'AutoIncrementId':
        return <AutoIncrementIdConfig fieldOptions={tempFieldOptions} setFieldOptions={setTempFieldOptions} />;
      case 'NumberField':
        return <NumberFieldConfig fieldOptions={tempFieldOptions} setFieldOptions={setTempFieldOptions} />;
      case 'TrueFalseField':
        return <TrueFalseFieldConfig fieldOptions={tempFieldOptions} setFieldOptions={setTempFieldOptions} />;
      case 'URLField':
        return <URLFieldConfig fieldOptions={tempFieldOptions} setFieldOptions={setTempFieldOptions} />;
      case 'TextField':
        return <TextFieldConfig fieldOptions={tempFieldOptions} setFieldOptions={setTempFieldOptions} />;
      case 'ParagraphTextField':
        return <ParagraphTextFieldConfig fieldOptions={tempFieldOptions} setFieldOptions={setTempFieldOptions} />;
      case 'AggregateField':
        return (<AggregateFieldConfig
            // id={currentModuleId}
            // name={currentModuleName}
            // value={null}
            fieldOptions={tempFieldOptions}
            setFieldOptions={setTempFieldOptions}
            onChange = {null}
            sourceModule={currentModuleId}
        />
      );
      case 'EmailField':
        return <EmailFieldConfig fieldOptions={tempFieldOptions} setFieldOptions={setTempFieldOptions} />;
      case 'PhoneNumber':
        return <PhoneNumberConfig fieldOptions={tempFieldOptions} setFieldOptions={setTempFieldOptions} />;
      case 'OneToManyField':
        return (
          <OneToManyBuilder
            fieldOptions={tempFieldOptions}
            sectionId={sectionId}
            fieldId={currentField.id}
            onBuilderUpdate={handleBuilderUpdate}
          />
        );
      case 'LineNumber': 
        return (
          <LineNumberConfig
            // value={fieldOptions?.lineNumberFormat || '1, 2, 3, ...'}
            fieldOptions={tempFieldOptions}
            onChange={(newFormat) =>
              setTempFieldOptions((prevOptions: any) => ({ ...prevOptions, lineNumberFormat: newFormat }))
            }
          />
        );
      case 'DateField': 
      return ( <DateFieldConfig 
          value={fieldOptions?.dateFormat || 'YYYY-MM-DD'}
          fieldOptions={tempFieldOptions}
          onChange={(newFormat) =>
            setTempFieldOptions((prevOptions: any) => ({ ...prevOptions, dateFormat: newFormat }))
          }
        />
      );
      case 'CalculationField': // Add this case
      return (
        <CalculationFieldConfig
          id={currentField?.id}
          formula={currentField.fieldOptions?.userFriendlyFormula || ''} // Ensure formula is handled
          currentFieldNames={currentFieldNames} // Pass fields to CalculationFieldConfig
          onFormulaChange={(userFriendlyFormula, backendFormula) => {
            setTempFieldOptions((prevOptions: any) => ({
              ...prevOptions,
              userFriendlyFormula,
              backendFormula, // Save backend formula separately
            }));
          }}
        />
      );
      case 'ReferenceLinkField':
        return (
          <ReferenceLinkFieldConfig
            fieldOptions={tempFieldOptions}
            setFieldOptions={setTempFieldOptions}
            tenantId={tenantId}
            companyId={companyId}
            currentModuleId={currentModuleId}
            currentModuleName={currentModuleName}
          />
        );
      default:
        return <p>No additional options for this field type.</p>;
    }
  };

  return (
    <Modal
      className='field-builder-modal'
      title={`Configure Field: ${currentField?.type}`}
      open={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      width={800}
    >
      <div style={{ marginBottom: '10px' }}>
        <label>Field Name:</label>
        <Input
          className='field-name-input'
          value={fieldName}
          onChange={(e) => setFieldName(e.target.value)}
          placeholder="Enter field name"
        />
      </div>

      {visible && renderFieldOptions()}
    </Modal>
  );
};

export default FieldModals;
// utils/fieldRenderUtils.ts
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import dayjs from 'dayjs';

export const renderFieldValue = (fieldType: string, value: any) => {
    switch (fieldType) {
        case 'ReferenceLinkField':
            if (Array.isArray(value)) {
                const labelEntry = value.find(entry => entry.Key === 'label');
                return labelEntry?.Value || '';
            } else if (value && typeof value === 'object') {
                return value.label || '';
            } else {
                return value || ''
            }
        case 'Dropdown':
            if (Array.isArray(value)) {
                // const labelEntry = value.find(entry => entry.Key === 'label');
                return value.find(entry => entry.Key === 'value').Value || ''
            } else {
                return value || ''
            }
        case 'TrueFalseField':
            if (value === true || value === false) {
                return value.toString()
            }
            else {
                return value || ''
            }
        case 'OneToManyField':
            return Array.isArray(value) ? value.length : (Array.isArray(value?.value) ? value.value.length : 0);

        case 'DateField':
            return value && dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : '';

        case 'PhoneNumber':
            if (Array.isArray(value)) {
                const phoneEntry = value.find(entry => entry.Key === 'phone');
                const phoneValue = phoneEntry?.Value || '';

                try {
                    const phoneNumber = parsePhoneNumberFromString(phoneValue, 'US');
                    return phoneNumber ? phoneNumber.formatNational() : phoneValue;
                } catch {
                    return phoneValue;  // Return as-is if parsing fails
                }
            } else if (typeof value === 'object' && value !== null) {
                // Handle case where phone number is an object with direct properties
                const phoneNumber = parsePhoneNumberFromString(value.phone, 'US')
                return phoneNumber ? phoneNumber.formatNational() : '';
            }
            return '';

        case 'AggregateField':

            return value

        default:
            if (typeof value === 'string' && value.startsWith('{')) {
                try {
                    return JSON.stringify(JSON.parse(value), null, 2); // Pretty-print
                } catch {
                    return value;  // Return as-is if parsing fails
                }
            }
            return value || '';
    }
};
// FieldPalette.tsx

import React from 'react';
import { Button } from 'antd';
import {Tooltip} from "antd";

const tooltips = {
  TextField: "A basic text input field for short or long-form text. Alphanumeric and special characters",
  AutoIncrementId: "Automatically generates a unique sequential number for each entry.",
  PhoneNumber: "A formatted input field for phone numbers.",
  OneToManyField: "Links multiple records together, allowing relationships between data sets.",
  EmailField: "A field specifically for email addresses with validation.",
  Dropdown: "A pre-defined selection of options for users to choose from.",
  NumberField: "A numeric input field that supports integers or decimals.",
  ReferenceLinkField: "A field to reference data from another module or record.",
  AggregateField: "A calculated field that aggregates data from related records.",
};


const FieldPalette = ({ onAddField, availableFields }) => {
  return (
    <div style={{ marginBottom: '20px' }} className='field-palette'>
      {availableFields.map((fieldType) => (
        <Tooltip key={fieldType} title={tooltips[fieldType] || "No description available."} placement="topLeft">
        <Button
          key={fieldType}
          onClick={() => onAddField(fieldType)}
          style={{ marginRight: '8px', marginBottom: '8px' }}
          className={`field-button ${fieldType.toLowerCase()}-button`} // Assign unique classes
        >
          {fieldType}
        </Button>
      </Tooltip>
      ))}
    </div>
  );
};

export default FieldPalette;